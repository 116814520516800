import React, { useState, useMemo, useEffect } from 'react';
import { Layout, Card, Avatar, Typography, Form, Button, Drawer, Image, Flex, Switch, Spin, Modal, Select, message, notification, Popconfirm} from 'antd';
import { CloseOutlined, StarOutlined, StopOutlined, PlusOutlined, RightOutlined, LeftOutlined, DeleteOutlined , StarFilled, StopFilled, CloseCircleFilled,PlusCircleFilled } from '@ant-design/icons';
import { get, post } from '../../utils/api';
import AvatarImage from '../../components/AvatarImage';
import { calculateAge } from '../../utils/utils';
import useScreenSize from '../../utils/useScreenSize';
import CheckCircle from '../../images/check-with-circles.svg';
import MessageIcon from '../../images/message-icon.svg';
import SelectMessage from '../../components/Communities/SelectMessage';

const { Sider } = Layout;
const { Title, Text, Paragraph } = Typography;

const SidebarFan = ({ selectedFan, setSelectedFan, selectedUser, setIsLoading, setIsReload, selectedCommunity }) => {
  const [form] = Form.useForm();
  const [userInfo, setUserInfo]=useState(null);
  const [blockreasons, setBlockreasons]=useState(null);
  const [reportreasons, setReportreasons]=useState(null);
  const [isFavorite, setIsFavorite]=useState(false);
  const [isBanUser,setIsBanUser]=useState(false);
  const [isReported,setIsReported]=useState(false);
  
  const [ishideNotifiction,setIsHideNotifiction]=useState(false);
  const [isLoader,setIsLoader]=useState(false);
  const [customCommunities,setCustomCommunities]=useState([]);
  const { isMobile, isTableToDesktop } = useScreenSize();

  const [isCommunityDrawerVisible, setIsCommunityDrawerVisible] = useState(false);

  const [isUnsubscribeModalVisible, setIsUnsubscribeModalVisible] = useState(false);
  const [isReportModalVisible, setIsReportModalVisible] = useState(false);
  const [isBlockModalVisible, setIsBlockModalVisible] = useState(false);

  const [selectedMessage, setSelectedMessage] = useState(null);
  const [chatHistory, setChatHistory] = useState([]);
  const [isHistoryLoading, setIsHistoryLoading] = useState(false); 
  const [isModalVisible, setIsModalVisible] = useState(false);

  useEffect(()=>{
      const loadUserInfo=async()=>{
        if(!userInfo){
          return false;
        }
        const { id } = userInfo;
        setIsLoader(true);
        setIsFavorite(false);
        try {
          const response = await get(`creator/favoriteAudience/${id}`);
          const loadComminties = await get(`creator/communities`);
          if(loadComminties){
            setCustomCommunities(loadComminties);
          }
    
          if (response && response.status) {
            const {favoriteUser,banUser,hideNotifiction}=response.data;
            setIsFavorite(favoriteUser);
            setIsBanUser(banUser);
            setIsHideNotifiction(hideNotifiction);
          } 
        } catch (error) {
          console.error('Error fetching user info:', error);
        } finally {
          setIsLoader(false);
        }
      }
      loadUserInfo();
  },[userInfo]);
  
  const handleClose = () => {
    setSelectedFan(false);
  };
  useEffect(() => {
    const init = async () => {
      const { user_phone } = selectedUser;
      setIsLoading(true);

      console.log("selectedUser",selectedFan);
  
      try {
        const response = await post(`creator/getUserInfo`, {
          phone: user_phone
        });
  
        if (response && response.status) {
          setUserInfo(response.data);
        } else {
          console.error('Information not available!');
        }
      } catch (error) {
        console.error('Error fetching user info:', error);
      } finally {
        setIsLoading(false);
      }
    };
  
    if (selectedUser) {
      init();
    }
  }, [selectedUser]);

  const addRemovefavorite=async()=>{
    setIsFavorite((prevIsFavorite) => !prevIsFavorite);
    const { id } = userInfo;
    setIsLoader(true);

    try {
    const response=await post(`creator/addRemovefavorite`, {
        favrateId: id
      });
      if(response.status){
        
        openNotification(response.message);
      }
    } catch (error) {
      console.error('Error fetching user info:', error);
    } finally {
      setIsLoader(false);
    }
  }

  const banUser=async()=>{
    setIsBanUser((prevIsFavorite) => !prevIsFavorite);
    const { id } = userInfo;
   
    if(!blockreasons && !isBanUser){
      return message.error("Please select reason for blocked");
    }
    setIsLoader(true);
    try {
    const response=await post(`creator/banUser`, {
        favrateId: id,
        blockreasons
      });
      if(response.status){
        openNotification(response.message);
        setIsBlockModalVisible(false);
      }
    } catch (error) {
      console.error('Error fetching user info:', error);
    } finally {
      setIsLoader(false);
    }
  }

  const notificationHandler=async()=>{
    setIsHideNotifiction((prevIsFavorite) => !prevIsFavorite);
    const { id } = userInfo;
    setIsLoader(true);
    if(!reportreasons && !ishideNotifiction){
      return message.error("Please select reason for blocked");
    }

    try {
    const response=await post(`creator/hideAlerts`, {
        favrateId: id,
        reportreasons
      });
      if(response.status){
        setIsReportModalVisible(false);
        openNotification(response.message);
      }
    } catch (error) {
      console.error('Error fetching user info:', error);
    } finally {
      setIsLoader(false);
    }
  }

  const removeUserFromCommunity=async(userId,communityId)=>{
   
      setIsLoader(true);
      try {
      const response=await post(`creator/rmUserCommunity`, {
        id: communityId,
        uId:userId
        });
        if(response.status){
         
          setSelectedFan(false);
          setIsReload(true);
          openNotification(response.message);
          
        }
      } catch (error) {
        console.error('Error fetching user info:', error);
      } finally {
        setIsLoader(false);
      }
  }

  const handleDeleteUser = () => {
      if (selectedCommunity) {
          removeUserFromCommunity(selectedUser.id, selectedCommunity.id);
      } else {
          message.error('No community selected.');
      }
  };

  const cancel=()=>{
    console.log("cancel");
   }

   const selectedFanStyles = useMemo(() => 
    isMobile ? { 
      backgroundColor: '#F2F4F7', 
      position: 'absolute', 
      width: '100%', 
      left: 0,
      height: 'calc(100vh - 70px)',
      overflow: 'auto', 
      scrollbarWidth: 'thin', 
      scrollbarColor: 'unset', 
      padding: '16px', 
      zIndex: 99,
    } : isTableToDesktop ? { 
      backgroundColor: '#F2F4F7', 
      position: 'absolute', 
      right: 0,
      height: '100vh',
      overflow: 'auto', 
      scrollbarWidth: 'thin', 
      scrollbarColor: 'unset', 
      padding: '16px', 
      borderLeft: '1px solid #e3e3e3',
      zIndex: 99,
    } : { 
      height: '100vh', 
      overflow: 'auto', 
      scrollbarWidth: 'thin', 
      scrollbarColor: 'unset', 
      background: '#F2F4F7', 
      padding: '16px', 
      borderLeft: '1px solid #e3e3e3',
      zIndex: 99,
    }
  , [isMobile, isTableToDesktop]);

  const openCommunityDrawer = () => {
    setIsCommunityDrawerVisible(true);
  };

  const closeCommunityDrawer = () => {
    setIsCommunityDrawerVisible(false);
  };


  const isUserInCommunity = (communityId) => {
    return userInfo?.Communities?.some(comm => comm.id === communityId);
  };

  const addUserToCommunity=async(userId, communityId)=>{
    setIsLoader(true);
    try {
    const response=await post(`creator/addUserCommunity`, {
      id: communityId,
      uId:userId
      });
     
        if(response.status){
          openNotification(response.message);
        }
    
    } catch (error) {
      console.error('Error fetching user info:', error);
    } finally {
      setIsLoader(false);
      setIsCommunityDrawerVisible(false);
    }
  }

  const openNotification = (message) => {
    notification.success({
      message: message,
      placement: 'topRight',
      duration: 3,
      icon: <Image src={CheckCircle} preview={false} /> 

    });
  };

  const showUnsubscribeModal = () => {
    setIsUnsubscribeModalVisible(true);
  }
  const handleUnsubscribeCancel = () => { 
    setIsUnsubscribeModalVisible(false);
  }
  const handleUnsubscribeConfirm = async() => {
    const { id } = userInfo;
    setIsLoader(true);
    try {
    const response=await post(`creator/unsubscribe`, {
        favrateId: id
      });
      if(response.status){
        setIsUnsubscribeModalVisible(false);
        openNotification("Fan was successfully unsubscribed");
        const currentUrl = new URL(window.location.href);
        currentUrl.searchParams.set('unsubscribe', 'true');
        window.location.href = currentUrl.toString();
      }
    } catch (error) {
      console.error('Error fetching user info:', error);
    } finally {
      setIsLoader(false);
    }
  };
  const showReportModal = () => { 
    setIsReportModalVisible(true);
  }
  const handleReportCancel = () => { 
    setIsReportModalVisible(false);
  }
  const handleReportConfirm = async () => {
   await notificationHandler();
   
  };
  const showBlockModal = () => { 
    setIsBlockModalVisible(true);
  }
  const handleBlockCancel = () => { 
    setIsBlockModalVisible(false);
  }
  const handleBlockConfirm = async() => {
    await banUser();
  };

  const unblockUser=async()=>{
     await banUser();
  }

  const handleSelectMessage = async() => {
    
   const item=selectedUser;
    setSelectedMessage(item);
    setIsModalVisible(true);
    
    try{
        const response = await post(`creator/chat`,{
          phn:item?.phone_number
        });
        if(response && response.status){
          setChatHistory(response?.data.reverse());
        }
    }catch(error){
      console.log("loading......");
    } finally {
      setIsHistoryLoading(false);
    }
  }

  const handleModalClose = () => {
    setIsModalVisible(false);
    setSelectedMessage(null);
    setIsHistoryLoading(false);
  };

  return (
    selectedFan && userInfo && (
      <Sider className="fan-sidebar-main" width={ isMobile ? '100%' : 300} style={selectedFanStyles} collapsed={!selectedFan}>
        <Spin spinning={isLoader}>
          {isCommunityDrawerVisible == false ?
          <Flex vertical gap="middle">
            <Button
              type="link"
              icon={<CloseOutlined style={{ color: '#101828' }}/>}
              onClick={handleClose}
              style={{ marginBottom: '20px', alignSelf: 'flex-end' }}
            >
            </Button>
            <Flex vertical className="fan-head" style={{ textAlign: 'center' }}>
              <Flex justify="center" style={{ marginTop: '-65px' }}>
                <AvatarImage size={75} title={`${userInfo?.first_name} ${userInfo?.last_name}`}/>
              </Flex>
              <Title level={4} style={{ textTransform: 'capitalize', fontWeight: 700, fontSize: '18px' }}>{userInfo?.first_name} {userInfo?.last_name}</Title>
              {/*<Text className="phone">{selectedUser?.user_phone}</Text>*/}
            </Flex>

            <Card className="fan-favorite-card" style={{ border: '0 none', backgroundColor: 'transparent' }}>
              <Flex gap={10} horizontal justify="space-around">
              
                {selectedCommunity && 
                  <Button
                    type="link"
                    icon={<Image src={MessageIcon} preview={false} />}
                    style={{ color: '#475467', padding: 0 }}
                    onClick={handleSelectMessage}
                  >
                  Message
                  </Button>
                }
                <Button
                  type="link"
                  icon={<PlusOutlined style={{ fontSize: 20 }} />}
                  style={{ color: '#475467', padding: 0 }}
                  onClick={openCommunityDrawer}
                >
                Add to
                </Button>
                <Button
                  type="link"
                  icon={isFavorite?<StarFilled style={{ fontSize: 20,color: '#FAC515' }} />:<StarOutlined style={{ fontSize: 20 }} />}
                  style={{ color: '#475467', padding: 0 }}
                  onClick={addRemovefavorite}
                >
                Favorites
                </Button>
                {/*<Popconfirm
                  placement="topLeft"
                    title={isBanUser?`UnBlock User`:`Block User`}
                    description={isBanUser?`Are you sure? you want to unblock this user`:`Are you sure? This user will not be able to message you again.`}
                    onConfirm={banUser}
                    onCancel={cancel}
                    okText="Yes"
                    cancelText="No"
                  >
                  <Button
                    type="link"
                    icon={isBanUser?<StopFilled style={{ fontSize: 20,color: '#ec0000' }} />:<StopOutlined style={{ fontSize: 20 }} />}
                    style={{ color: '#475467' }}
                  />
                </Popconfirm>
            
                <Popconfirm
                  placement="topLeft"
                    title={`Remove from community`}
                    description={`Are you sure you want to remove this user from the community?`}
                    onConfirm={handleDeleteUser}
                    onCancel={cancel}
                    okText="Yes"
                    cancelText="No"
                  >
                  <Button
                    type="link"
                    icon={<DeleteOutlined style={{ fontSize: 20 }} />}
                    style={{ color: '#D92D20' }}
                  >
                  </Button>
                </Popconfirm>*/}

              </Flex>
            </Card>

            {/*<Card className="fan-details">
              <Flex horizontal align="center" gap={10}>
              <Popconfirm
                  placement="topLeft"
                    title={isBanUser?`Show notifications`:`Hide notifications`}
                    description={isBanUser?`Are you sure? you want to show all notifications`:`Are you sure? you want to hide all notifications`}
                    onConfirm={notificationHandler}
                    onCancel={cancel}
                    okText="Yes"
                    cancelText="No"
                  >
                  <Switch checked={ishideNotifiction} />
                </Popconfirm>
                  <Paragraph style={{ marginBottom: 0 }}>Hide Alerts</Paragraph>
              </Flex>
            </Card>*/}

            <Card className="fan-details" style={{ border: '0 none' }}>
              <Title level={4}>Details</Title>
              <Flex horizontal style={{ borderBottom: '1px solid #EAECF0', padding: '0px 0 10px 0' }}>
                <strong style={{ width: '50%' }}>Age:</strong> <span style={{ width: '50%' }}>{calculateAge(userInfo.dob)}</span>
              </Flex>
              <Flex horizontal style={{ borderBottom: '1px solid #EAECF0', padding: '10px 0' }}>
                <strong style={{ width: '50%' }}>Gender:</strong> <span style={{ width: '50%' }}>{userInfo.gender}</span>
              </Flex>
              <Flex horizontal style={{ borderBottom: '1px solid #EAECF0', padding: '10px 0' }}>
                <strong style={{ width: '50%' }}>Location:</strong> <span style={{ width: '50%' }}>{userInfo.city}</span>
              </Flex>
              <Flex horizontal style={{ padding: '10px 0 0px 0' }}>
                <strong style={{ width: '50%' }}>Last Active</strong> <span style={{ width: '50%' }}>Apr 25, 2024</span>
              </Flex>
            </Card>

              <Card className="fan-engagement" style={{ border: '0 none' }}>
                <Title level={4}>Engagement</Title>
                <Flex horizontal style={{ borderBottom: '1px solid #EAECF0', padding: '0px 0 10px 0' }}>
                  <strong style={{ width: '50%' }}>Spent:</strong> <span style={{ width: '50%' }}>${selectedFan.spent || '0.00'}</span>
                </Flex>
                <Flex horizontal style={{ padding: '10px 0 0px 0' }}>
                  <strong style={{ width: '50%' }}>Interactions:</strong> <span style={{ width: '50%' }}>{selectedFan.interactions || 0}</span>
                </Flex>
              </Card>
     
           
              <Card className="fan-communities" style={{ border: '0 none' }}>
                <Title level={4}>Communities</Title>
                <Flex gap={4}>
                  {userInfo?.Communities && userInfo.Communities.length > 0 ? (
                    userInfo.Communities.map((community) => (
                      <Paragraph key={community.id} className="communities-name">
                        {community.name}
                        <Popconfirm
                          placement="topLeft"
                          title="Remove from community"
                          description="Are you sure you want to remove this user from the community?"
                          onConfirm={() => removeUserFromCommunity(userInfo?.id, community.id)}
                          onCancel={cancel}
                          okText="Yes"
                          cancelText="No"
                        >
                          <Button
                            type="link"
                            icon={<CloseOutlined style={{ fontSize: 14, fontWeight: 600, color: '#98A2B3' }} />}
                            style={{ color: '#475467', padding: 0, height: 'auto', width: 'auto', marginLeft: 4, verticalAlign: 'middle' }}
                          />
                        </Popconfirm>
                      </Paragraph>
                    ))
                  ) : (
                    <Paragraph>None</Paragraph>
                  )}

                </Flex>

                <Button type="link" onClick={openCommunityDrawer} style={{ color: '#6941C6', padding: 0 }}>
                  Add to Community <PlusOutlined />
                </Button>

              </Card>
         

            <Card className="fan-actions" style={{ border: '0 none' }}>
              <Flex vertical>
                <Button type="link" block style={{ color: '#B42318', flexDirection: 'row-reverse', justifyContent: 'space-between', padding: '4px 0px 10px 0px', height: 'auto', fontWeight: 600 }} icon={<RightOutlined />} onClick={showUnsubscribeModal}>Unsubscribe</Button>
                <Button type="link" block style={{ color: '#B42318', flexDirection: 'row-reverse', justifyContent: 'space-between', padding: '10px 0px 10px 0px', height: 'auto', fontWeight: 600 }} icon={<RightOutlined />} onClick={ishideNotifiction?()=>{openNotification('This user has already been reported.')}:showReportModal}>{ishideNotifiction?`Reported`:`Report`}</Button>
                <Button type="link" block style={{ color: '#B42318', flexDirection: 'row-reverse', justifyContent: 'space-between', padding: '10px 0px 4px 0px', height: 'auto', fontWeight: 600 }} icon={<RightOutlined />} onClick={isBanUser?unblockUser:showBlockModal}>{(isBanUser)?`Unlock`:`Block`}</Button>
              </Flex>
            </Card>

          </Flex>
        : 
          ""
        }

        {isCommunityDrawerVisible &&
          <Flex className="community-sidebar" vertical>
            <Flex style={{ padding: '12px 15px', borderBottom: '1px solid #D0D5DD' }}>
              <Button icon={ <LeftOutlined /> } type="text" style={{ padding: 0, color: '#475467', fontWeight: 600 }} onClick={closeCommunityDrawer}>Back</Button>
              <Button icon={ <CloseOutlined /> } type="text" style={{ padding: 0, marginLeft: 'auto' }} onClick={closeCommunityDrawer}></Button>
            </Flex>
            <Flex style={{ padding: '15px 15px' }}>
              <Title level={3} style={{ margin: 0, fontWeight: 700 }}>Communities</Title>
            </Flex>
            <Flex vertical gap={14} style={{ padding: '10px 15px' }}>
              {customCommunities.length > 0 ? (
                <>
                  {customCommunities.map((community) => (
                    <Flex gap={10} key={community.id} align="center">
                      <AvatarImage size={40} title={`${community?.name}`}/>
                      <span style={{ color: '#344054', fontSize: '14px', fontWeight: 700 }}>{community.name}</span>
                      {isUserInCommunity(community.id) ? (
                        <Button
                          type="link"
                          onClick={() => removeUserFromCommunity(userInfo?.id, community.id)}
                          style={{ color: '#B42318', fontWeight: 600, marginLeft: 'auto' }}
                        >
                          Remove
                        </Button>
                      ) : (
                        <Button
                          type="link"
                          onClick={() => addUserToCommunity(userInfo?.id, community.id)}
                          style={{ color: '#6941C6', fontWeight: 600, marginLeft: 'auto' }}
                        >
                          Add
                        </Button>
                      )}
                    </Flex>
                  ))}
                </>) : (
                  <Paragraph className="text-gray-500">No communities available.</Paragraph>
                )}
            </Flex>
          </Flex>
        }


        {selectedMessage &&
                <Modal
                  open={isModalVisible}
                  onCancel={handleModalClose}
                  footer={null}
                  width={800}
                  className="select-message-modal"
                >
                  <SelectMessage selectedMessage={selectedMessage} isHistoryLoading={isHistoryLoading} chatHistory={chatHistory} setChatHistory={setChatHistory} />
                </Modal>
              }

        {/* Unsubscribe Modal */}
        <Modal
          visible={isUnsubscribeModalVisible}
          onCancel={handleUnsubscribeCancel}
          className="user-common-dialog"
          footer={[
            <Button key="cancel" size="large" onClick={handleUnsubscribeCancel} style={{ fontWeight: 600 }}>Cancel</Button>,
            <Button key="unsubscribe" size="large" 
            type="primary"
            danger
            onClick={handleUnsubscribeConfirm} 
            style={{ fontWeight: 600 }}
            >Unsubscribe</Button>
          ]}
          okButtonProps={{ style: { backgroundColor: '#B42318', color: '#fff' } }}
          width={400}
        >
          <Title level={4} style={{ fontSize: '18px', marginTop: 5, marginBottom: 10, textAlign: 'center' }}>Unsubscribe Fan Name?</Title>
          <Paragraph style={{ textAlign: 'center', marginBottom: 25 }}>This Member will be opted out. They can opt in again by messaging your Blonde Number. They will be notified that they have been unsubscribed.</Paragraph>
        </Modal>

        {/* Report Modal */}
        <Modal
          visible={isReportModalVisible}
          onCancel={handleReportCancel}
          onOk={handleReportConfirm}
          className="user-common-dialog"
          footer={[
            <Button key="cancel" size="large" onClick={handleReportCancel} style={{ fontWeight: 600 }}>Cancel</Button>,
            <Button key="report" size="large" 
            type="primary"
            onClick={handleReportConfirm} 
            style={{ fontWeight: 600 }}
            >Report</Button>
          ]}
          width={400}
        >
          <Title level={4} style={{ fontSize: '18px', marginTop: 5, marginBottom: 10 }}>Report</Title>
          <Paragraph style={{ marginBottom: 10 }}>The contents of this conversation will be flagged to our Trust and Safety team. Please select a reason.</Paragraph>
          <Form.Item 
            label="Reason"
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24 }}
            className="custom-form-item"
          >
            <Select 
              onChange={(value) => {
                setReportreasons(value);  // Set the selected value
              }} 
            placeholder="Select one" style={{ width: '100%' }}>
              <Select.Option value="spam">Spam</Select.Option>
              <Select.Option value="abusive">Abusive Language</Select.Option>
              <Select.Option value="harassment">Harassment</Select.Option>
              <Select.Option value="hate_speech">Hate Speech</Select.Option>
              <Select.Option value="explicit_content">Explicit Content</Select.Option>
              <Select.Option value="violence">Violence</Select.Option>
              <Select.Option value="discrimination">Discrimination</Select.Option>
              <Select.Option value="fake_information">Fake Information</Select.Option>
              <Select.Option value="phishing">Phishing</Select.Option>
              <Select.Option value="malware">Malware/Links to malicious content</Select.Option>
              <Select.Option value="scam">Scam</Select.Option>
              <Select.Option value="inappropriate_name">Inappropriate Username</Select.Option>
              <Select.Option value="underage">Underage User</Select.Option>
              <Select.Option value="inappropriate_profile_picture">Inappropriate Profile Picture</Select.Option>
              <Select.Option value="self_harm">Self-Harm/Threatening Behavior</Select.Option>
              <Select.Option value="impersonation">Impersonation</Select.Option>
              <Select.Option value="illegal_activities">Illegal Activities</Select.Option>
            </Select>
          </Form.Item>
          <p>Only the Blonde team will see your response</p>
        </Modal>

        {/* Block Modal */}
        <Modal
          visible={isBlockModalVisible}
          onCancel={handleBlockCancel}
          onOk={handleBlockConfirm}
          className="user-common-dialog"
          footer={[
            <Button key="cancel" size="large" onClick={handleBlockCancel} style={{ fontWeight: 600 }}>Cancel</Button>,
            <Button key="block" size="large" 
            type="primary"
            onClick={handleBlockConfirm} 
            style={{ fontWeight: 600 }}
            >Block</Button>
          ]}
          width={400}
        >
          <Title level={4} style={{ fontSize: '18px', marginTop: 5, marginBottom: 10 }}>Block Fan Name?</Title>
          <Paragraph style={{ marginBottom: 10 }}>This Member will not be able to message you again. This action is irreversible. They will not be notified of being blocked.</Paragraph>
          <Form.Item 
            label="Reason"
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24 }}
            className="custom-form-item"
           
          >
         <Select onChange={(value) => {
              setBlockreasons(value);  // Set the selected value
            }} 
            placeholder="Select one" style={{ width: '100%' }}>
              <Select.Option value="spam">Spam</Select.Option>
              <Select.Option value="abusive">Abusive Language</Select.Option>
              <Select.Option value="harassment">Harassment</Select.Option>
              <Select.Option value="hate_speech">Hate Speech</Select.Option>
              <Select.Option value="explicit_content">Explicit Content</Select.Option>
              <Select.Option value="violence">Violence</Select.Option>
              <Select.Option value="discrimination">Discrimination</Select.Option>
              <Select.Option value="fake_information">Fake Information</Select.Option>
              <Select.Option value="phishing">Phishing</Select.Option>
              <Select.Option value="malware">Malware/Links to malicious content</Select.Option>
              <Select.Option value="scam">Scam</Select.Option>
              <Select.Option value="inappropriate_name">Inappropriate Username</Select.Option>
              <Select.Option value="underage">Underage User</Select.Option>
              <Select.Option value="inappropriate_profile_picture">Inappropriate Profile Picture</Select.Option>
              <Select.Option value="self_harm">Self-Harm/Threatening Behavior</Select.Option>
              <Select.Option value="impersonation">Impersonation</Select.Option>
              <Select.Option value="illegal_activities">Illegal Activities</Select.Option>
             
          </Select>
          </Form.Item>
          <p>Only the Blonde team will see your response</p>
        </Modal>

        </Spin>
      </Sider>
    )
  );
};

export default SidebarFan;
