import React, { useState, useRef, useEffect,useContext } from 'react';
import { Layout, Card, Typography, Breadcrumb, Button, Image, DatePicker, Table, Row, Col, Flex, Radio, Divider, List, Avatar, Space,Spin } from 'antd';
import { ArrowUpOutlined, RightOutlined, BarChartOutlined, ArrowDownOutlined } from '@ant-design/icons';
import { Line } from 'react-chartjs-2';
import moment from 'moment';
import Analytics from '../../Graph/Analytics';
import dataSet  from "../../Graph/data.js";
import DropdownSelector from "../../Graph/menu.js";
import { UserContext } from '../../../contexts/UserContext.js';
import { get } from '../../../utils/api.js';
import { formatPrice } from '../../../utils/utils.js';
import TimeAgo from '../../TimeAgo.js';
import AvatarImage from '../../AvatarImage.js';

import CalendarIcon from '../../../images/calendar.png';

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const { Title: AntTitle, Text } = Typography;


// const activity = [
//   {
//     name: 'Olivia Rhye',
//     time: '2 mins ago',
//     content: 'Purchased a content',
//     price: '$25.00',
//   },
//   {
//     name: 'Olivia Rhye',
//     time: '2 mins ago',
//     content: 'Purchased a content',
//     price: '$25.00',
//   },
//   {
//     name: 'Olivia Rhye',
//     time: '2 mins ago',
//     content: 'Purchased a content',
//     price: '$25.00',
//   },
//   {
//     name: 'Olivia Rhye',
//     time: '2 mins ago',
//     content: 'Purchased a content',
//     price: '$25.00',
//   },
//   {
//     name: 'Olivia Rhye',
//     time: '2 mins ago',
//     content: 'Purchased a content',
//     price: '$25.00',
//   },
//   {
//     name: 'Olivia Rhye',
//     time: '2 mins ago',
//     content: 'Purchased a content',
//     price: '$25.00',
//   },
//   {
//     name: 'Olivia Rhye',
//     time: '2 mins ago',
//     content: 'Purchased a content',
//     price: '$25.00',
//   },
//   {
//     name: 'Olivia Rhye',
//     time: '2 mins ago',
//     content: 'Purchased a content',
//     price: '$25.00',
//   }
// ];


const ThisWeeksRevenueDetails = () => {
  const [selectedRange, setSelectedRange] = useState('12 months');
  const [graphData, setGraphData] = useState([]);
  const chartRef = useRef(null);
  const {user}  = useContext(UserContext);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalRevanue, setTotalRevanue] = useState(0);
  const [activity, setActivity] = useState([]);

  const [growth, setGrowth] = useState(0);
  const [growthString, setGrowthString] = useState(null);
  const [weeks, setweeks] = useState({});

  const fetchChartData=async(keyName="Last_12_months")=>{
    setLoading(true);
    try{
      const response = await get('creator/revenueChart', {dataKey:keyName});
      if(response && response.status){
        setData(response?.data);
        setweeks(response?.weeks);
      }
    }catch (error) {
      console.error('Error fetching users:', error);
    }finally {
      setLoading(false);
    }
  }

  const fetchRevanueList=async(params = {})=>{
    setLoading(true);
    try{
      const response = await get('creator/revenueList',{
        _page: params.pagination?.current || 1,
        _limit: params.pagination?.pageSize || 10,
        q: params?.searchTerm ||'',
        sortBy:params?.sortBy ||'',
        });
      if(response && response.status){
        setTotalRevanue(response?.thisrevenue?.totalAmount);
        setActivity(response?.data);
      }
    }catch (error) {
      console.error('Error fetching users:', error);
    }finally {
      setLoading(false);
    }
  }

  useEffect(()=>{
    fetchRevanueList();
    fetchChartData();
	 },[]);

  const fetchCustomData =async (key) => {
    await fetchChartData(key);
  };

  const handleRangeChange = (range) => {
    setSelectedRange(range);
  };

  const handleDateChange = (date) => {
    // Update graph data based on the selected date
  };

  useEffect(() => {
    return () => {
      if (chartRef.current && chartRef.current.chartInstance) {
        chartRef.current.chartInstance.destroy();
      }
    };
  }, []);


  const chartOptions = {
    responsive: true,
    scales: {
      x: {
        grid: {
          display: false,
        },
      },
      y: {
        grid: {
          display: true,
        },
        ticks: {
          display: true,
        },
      },
    },
    plugins: {
      legend: {
        display: false,
      },
      title: {
        display: false,
      },
    },
  };


  const compareWeeks=(data)=> {
    const { currentWeekRevenue, previousWeekRevenue } = data;
    let ctl=0;
    let changeStatus;
    // Calculate the percentage change
    const percentageChange = ((currentWeekRevenue - previousWeekRevenue) / previousWeekRevenue) * 100;
    
    if(percentageChange===Infinity){
      ctl=100;
    }else{
       ctl = isNaN(percentageChange) ? 0 : percentageChange;
      if (percentageChange >=0) {
          changeStatus = 'increased';
      } else if (percentageChange < 0) {
          changeStatus = 'decreased';
      } 
    }
   return {ctl,changeStatus};
}

  useEffect(()=>{
    if(weeks){
      const p=compareWeeks(weeks);
      setGrowth(p?.ctl);
      setGrowthString(p?.changeStatus);
    }
 },[weeks]);


  return (
    <Layout style={{ minHeight: '100vh', padding: 30 }}>
      <Spin spinning={loading}>
       {user?.role==="Team"?
      <Breadcrumb className="breadcrumb-insights" separator={<RightOutlined />} style={{ marginBottom: 25 }}>
        <Breadcrumb.Item href="/team/insights">
          <BarChartOutlined />
        </Breadcrumb.Item>
        <Breadcrumb.Item href="/team/insights">
          <span>Audience Insights</span>
        </Breadcrumb.Item>
        <Breadcrumb.Item>
          <span>Revenue</span>
        </Breadcrumb.Item>
      </Breadcrumb>
      : <Breadcrumb className="breadcrumb-insights" separator={<RightOutlined />} style={{ marginBottom: 25 }}>
      <Breadcrumb.Item href="/insights">
        <BarChartOutlined />
      </Breadcrumb.Item>
      <Breadcrumb.Item href="/insights">
        <span>Audience Insights</span>
      </Breadcrumb.Item>
      <Breadcrumb.Item>
        <span>Revenue</span>
      </Breadcrumb.Item>
    </Breadcrumb>}
      
      <AntTitle level={2} style={{ marginBottom: 5, marginTop: 0 }}>Revenue activity</AntTitle>
      <Text>View and see your recent revenue activity</Text>

      <Row className="button-date-group" gutter={16} style={{ margin: '20px 0 30px 0' }}>
        <Col>
          <DropdownSelector fetchCustomData={fetchCustomData} />
        </Col>
        <Col style={{ marginLeft: 'auto' }}>
         {/* <DatePicker 
            placeholder="Select date" 
            onChange={handleDateChange}
            suffixIcon={<span style={{ position: 'absolute', left: '10px', zIndex: 10, top: 5 }}><Image src={CalendarIcon} preview={false} /></span>}
            style={{ 
              paddingLeft: '40px' // Make space for the icon 
            }}
          />*/}
        </Col>
      </Row>

      <Flex horizontal="true" flex={1}>
        <Flex vertical style={{ minWidth: 250 }}>
          <Text style={{ marginTop: 0, marginBottom: 15 }}>Revenue</Text>
          <AntTitle level={2} style={{ marginTop: 0, marginBottom: 10, fontSize: '36px' }}>{formatPrice(totalRevanue)}</AntTitle>
          <Text>
          {growthString=="increased"?<span style={{ color: '#079455' }}><ArrowUpOutlined />{Math.abs(growth).toFixed(2)}%</span> :<span style={{ color: 'red' }}><ArrowDownOutlined/>{Math.abs(growth).toFixed(2)}%</span> }
           vs last week</Text>
        </Flex>
        <Flex vertical flex={1}>
          {/*<Line ref={chartRef} data={graphData} options={chartOptions} />*/}
          <div className="analytics-graph">
            <Analytics data={data} isFormated={true} />
          </div>
        </Flex>
      </Flex>

      <Divider />

      <AntTitle level={4} style={{ marginBottom: 20, marginTop: 10, fontSize: '18px' }}>Recent activity</AntTitle>
      <List
        itemLayout="horizontal"
        dataSource={activity}
        className="activity-list"
        renderItem={(item) =>{
          const {revenueUser}=item;
          const imageId = revenueUser && revenueUser?.uploadedIds && revenueUser?.uploadedIds.length > 0 ? revenueUser?.uploadedIds[0] : null;
          return (
          <List.Item>
            <List.Item.Meta
              avatar={<AvatarImage size={48} imageId={imageId} title={`${revenueUser?.first_name} ${revenueUser.last_name}`}   />}
              title={
                <Space>
                  <Text strong>{revenueUser?.first_name} {revenueUser?.last_name}</Text>
                  <Text type="secondary"><TimeAgo timestamp={item.updatedAt} /></Text>
                </Space>
              }
              description={
                <div>
                  <Text>{item?.message}</Text>
                  {item.amount && <Text strong>, {formatPrice(item?.amount|0)}</Text>}
                </div>
              }
            />
          </List.Item>)
        }}
      />

</Spin>
    </Layout>
  );
};

export default ThisWeeksRevenueDetails;
