import React,{useEffect,useState} from 'react';
import { Link,useNavigate,useParams,Navigate } from 'react-router-dom';
import { Layout, Col, Row, Flex, Image, Drawer, Divider, Radio, Form, Typography, Button, Spin } from 'antd';
import { EllipsisOutlined } from '@ant-design/icons';

import PlaceholderImg from '../../../images/placeholder-img2.jpg';
import Payment from '../../../components/Audience/Payment';
import './FanSignup.css';
import { get } from '../../../utils/api';
import { openNotification } from '../../../utils/utils';

const { Content } = Layout;
const { Text, Title } = Typography;

const FanSignup = () => {
    const [data,setData]=useState(null);
    const [creatorData,setCreatorData]=useState(null);
    const [unLockHandler,SetUnLockHandler]=useState(null);
    const [token,setToken]=useState(null);
    const { smsId,phone } = useParams();
    const Navigate = useNavigate();
    const [isDrawerVisible, setDrawerVisible] = useState(false);

    const [reportOption, setReportOption] = useState(null);

    const toggleDrawer = () => {
        setDrawerVisible(!isDrawerVisible);
    };

    useEffect(()=>{
        const init=async()=>{
            const response = await get(`user/unlockRequest/${smsId}/${phone}`);
            if (response && response.status) {
                console.log("response?.token",response);
                setToken(response?.data?.token);
                setData(response?.data.postData);
                if(response && response?.data?.creatorData){
                    setCreatorData(response?.data?.creatorData);
                }
            }
        }
        init();
    
    },[smsId]);

    useEffect(()=>{
        if(data?.message && token){
            localStorage.setItem('token',token);
            Navigate(`/audience/fan-signup-unlock/${smsId}`);  
        }
    },[data,token]);

    const reportOptions = [
        { key: '1', label: 'I just don\'t like it' },
        { key: '2', label: 'It\'s spam' },
        { key: '3', label: 'Nudity or sexual activity' },
        { key: '4', label: 'Hate speech or symbols' },
        { key: '5', label: 'False information' },
        { key: '6', label: 'Bullying or harassment' },
        { key: '7', label: 'Scam or fraud' },
        { key: '8', label: 'Violence or dangerous organizations' },
        { key: '9', label: 'Intellectual property violation' },
        { key: '10', label: 'Sale of illegal or regulated goods' },
        { key: '11', label: 'Suicide or self-injury' },
        { key: '12', label: 'Eating disorders' },
        { key: '13', label: 'Something else' },
    ];
  
    const onFinish = () => {
        console.log('Reported:', reportOption);
        setDrawerVisible(!isDrawerVisible);
        openNotification("Report submit!");
        setReportOption(null);
    };

    return (
        <Layout style={{ minHeight: '100vh', backgroundColor: '#000000', justifyContent: 'center' }}>
            {data ? 
            <Content style={{ padding: '16px 20px', flex: '0' }}>
                {!unLockHandler?<Flex vertical className="unclock-content" style={{ maxWidth: '416px', margin: '0 auto', borderRadius: 10 }}>
                    <Button type="text" style={{ backgroundColor: 'rgba(0,0,0,0.1)', position: 'absolute', right: 5, top: 5, zIndex: 1, padding: '2px 6px' }} onClick={toggleDrawer}><EllipsisOutlined style={{ fontSize: 30, color: 'rgba(255,255,255,0.6)' }} /></Button>
                    <Image
                        src={PlaceholderImg}
                        style={{ borderRadius: 8, maxWidth: '100%' }}
                        preview={false}
                    />
                    <Flex vertical className="button-group">
                        <Button onClick={() => SetUnLockHandler(true)} size="large" style={{ width: '100%', fontWeight: 600 }}>Unlock ${data.price}</Button>
                    </Flex>
                </Flex>:
                <Flex vertical style={{ maxWidth: '416px', margin: '0 auto', padding: '25px 20px', backgroundColor: '#FFFFFF', borderRadius: 10 }}>
                    <Payment smsId={smsId} phone={phone} creatorData={creatorData} data={data} SetUnLockHandler={SetUnLockHandler} />
                </Flex>}
            </Content>: 
             <Spin spinning={true}>
                <Content style={{ height:"100Vh", flex: '0' }}>
                </Content>
            </Spin>}

            <Drawer
                placement="bottom"
                closable={true}
                onClose={toggleDrawer}
                open={isDrawerVisible}
                height="90%"
                bodyStyle={{ padding: '0px' }}
                className="report-drawer"
            >   
                <div style={{ backgroundColor: 'rgba(0,0,0,0.6)', height: '5px', width: '32px', top: 6, margin: '0 auto', borderRadius: 10, position: 'absolute', left: 0, right: 0 }}></div>
                <Title level={3} style={{ marginTop: 25, textAlign: 'center' }}>Report</Title>
                <Divider />
                <Flex vertical style={{ padding: '0 20px 20px 20px' }}>
                    <Title level={5} style={{ marginTop: 0 }}>Why are you reporting this post?</Title>
                    <Text>Your report is anonymous, except if you're reporting an intellectual property infringement. If someone is in immediate danger, call the local emergency services- don't wait.</Text>
                    
                    <Form onFinish={onFinish} className="report-list">
                      {reportOptions.map((option) => (
                        <Form.Item key={option.key} name={option.key} className="list" style={{ margin: 0 }}>
                          <Radio
                            checked={reportOption === option.key}
                            onChange={() => setReportOption(option.key)}
                          >
                            {option.label}
                          </Radio>
                        </Form.Item>
                      ))}
                      <Form.Item style={{ marginTop: 10 }}>
                        <Button type="primary" size="large" htmlType="submit" style={{ fontWeight: 600 }}>
                          Submit Report
                        </Button>
                      </Form.Item>
                    </Form>

                </Flex>
            </Drawer>
        </Layout>
    );
}

export default FanSignup;