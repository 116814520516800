import React, { useState } from 'react';
import { Form, Input, Button, Steps, Typography, DatePicker, Checkbox, Image, Flex, Select, Spin, Avatar, message } from 'antd';
import { useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';
import { ArrowLeftOutlined, CheckCircleOutlined } from '@ant-design/icons';
import './StepSignupForm.css';
import { post,get } from '../../../utils/api';
import { openNotification } from '../../../utils/utils';
import UserImg from '../../../images/avatar-image.png';
import CheckSMIcon from '../../../images/check-sm.svg';
import CrossSMIcon from '../../../images/x-circle.svg';
import CheckSMSuccess from '../../../images/check-sm-green.svg';
import AvatarImage from '../../AvatarImage';
import AddressAutocomplete from '../../AddressAutoComplete';

const { Step } = Steps;
const { Title, Text } = Typography;
const { Option } = Select;


const days = Array.from({ length: 31 }, (_, i) => i + 1);
const months = [
  'January', 'February', 'March', 'April', 'May', 'June',
  'July', 'August', 'September', 'October', 'November', 'December'
];
const currentYear = new Date().getFullYear();
const startYear = currentYear - 50;
const years = Array.from({ length: 51 }, (_, i) => startYear + i);

const getDaysInMonth = (month, year) => {
  return dayjs(`${year}-${month}-01`).daysInMonth();
};


const StepSignupForm = ({data,creatorId,phone}) => {
  const [current, setCurrent] = useState(0);
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [checkedList, setCheckedList] = useState([]);
  const [hearAboutUs, sethearAboutUs] = useState(null);
  const [isLessthenEight, setIsLessthenEight] = useState(false);
  const [isOneSpecial, setIsOneSpecial] = useState(false);
  const [isValidPassword, setIsValidPassword] = useState(false);
  const [isConfirmValidPassword, setIsConfirmValidPassword] = useState(false);
  const [formData, setFormData] = useState({});
  const tenYearsAgo = dayjs().subtract(18, 'year');
  const navigate = useNavigate();

  const [day, setDay] = useState('');
  const [month, setMonth] = useState('');
  const [year, setYear] = useState('');


  const onChange = (list) => {
    setCheckedList(list);
  };
  const handleChange=(value)=>{
    sethearAboutUs(value);
  }
  const validateMessages = {
    required: '${label} is required!',
    types: {
      email: '${label} is not a valid email!',
      number: '${label} is not a valid number!',
    },
  };

  const checkUsernameAvailability = async (_, value) => {
    try {
      const response = await get(`auth/check-username/${value}`);
      if (response.available) {
        return Promise.resolve(); // Username is available
      } else {
        return Promise.reject('Username is already taken.');
      }
    } catch (error) {
      return Promise.reject('Failed to check username availability.');
    }
  };

  const checkEmailAvailability = async (_, value) => {
    try {
      const response = await get(`auth/checkemail/${value}`);
      if (response.available) {
        return Promise.resolve(); // Username is available
      } else {
        return Promise.reject('Username is already taken.');
      }
    } catch (error) {
      return Promise.reject('Failed to check username availability.');
    }
  };

  const validateDate = (_, value) => {
    if (!value || !value.day || !value.month || !value.year) {
      return Promise.reject(new Error('Please enter a valid date!'));
    }
    const { day, month, year } = value;
    const daysInMonth = getDaysInMonth(month, year);
    
    if (day < 1 || day > daysInMonth) {
      return Promise.reject(new Error(`Invalid day for the selected month! ${daysInMonth} days available.`));
    }
    const selectedDate = new Date(year, month - 1, day);
    const currentDate = new Date();

    if (selectedDate > currentDate) {
      return Promise.reject(new Error('Date of birth cannot be in the future!'));
    }
    return Promise.resolve();
  };


  const steps = [
    {
      title: 'Step 1',
      content: (<>
        <Form.Item
          name="firstname"
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
          rules={[{ required: true, message: 'Please input your First name!' }]}
          className="custom-form-item"
        >
          <Input placeholder="First name" style={{ padding: '7px 10px' }} />
        </Form.Item>
        <Form.Item
          name="lastname"
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
          rules={[{ required: true, message: 'Please input your Last name!' }]}
          className="custom-form-item"
        >
          <Input placeholder="Last name" style={{ padding: '7px 10px' }} />
        </Form.Item>
        <Form.Item
          name="email"
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
          rules={[
            { required: true, type: 'email' },
            { validator: checkEmailAvailability, message: 'Email is already registered.' }
          ]}
          className="custom-form-item"
        >
          <Input placeholder="Enter email address" style={{ padding: '7px 10px' }} />
        </Form.Item>
        <Form.Item
          name="isUserAccecpt"
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
          className="custom-form-item"
          valuePropName="checked"
          rules={[
            {
              validator: (_, value) =>
                value ? Promise.resolve() : Promise.reject(new Error('This field is required')),
            },
          ]}
        >
           <Checkbox>
           By checking this box, you are giving explicit consent to receive promotional messages, offers, and updates from the influencer via SMS.
            </Checkbox>
        </Form.Item>
      </>
      ),
    },
    {
      title: 'Step 2',
      content: (<>
        <Form.Item
          name="gender"
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
          rules={[{ required: true, message: 'Please select your gender!' }]}
          className="custom-form-item"
        >
        <Select
          showSearch
          style={{
            width: '100%',
          }}
          placeholder="Gender"
          optionFilterProp="children"
          filterOption={(input, option) => (option?.label ?? '').includes(input)}
          filterSort={(optionA, optionB) =>
            (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
          }
          className="custom-form-select"
          onChange={handleChange}
          options={[
            {
              value: 'Male',
              label: 'Male',
            },
            {
              value: 'Female',
              label: 'Female',
            },
            {
              value: 'Other',
              label: 'Other',
            }
          ]}
        />
        </Form.Item>


        {/*<Form.Item
          name="birthday"
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
          rules={[{ required: true, message: 'Please select your birthday!' }]}
          className="custom-form-item"
        >
          <DatePicker
            style={{ width: '100%', padding: '7px 10px' }}
            format="DD-MM-YYYY"
            placeholder="Birthday"
            maxDate={tenYearsAgo}
          />
        </Form.Item>*/}

        <Form.Item
          name="birthday"
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
          className="cst-datepicker"
          rules={[
            { required: true, message: 'Please enter your birthday!' },
            { validator: validateDate },
          ]}
        >
          <Flex gap={10}>
            <Form.Item name={['birthday', 'day']} style={{ flex: '1 1 25%', marginBottom: 0 }}>
              <Select placeholder="Day">
                {days.map(day => (
                  <Select.Option key={day} value={day}>{day}</Select.Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item name={['birthday', 'month']} style={{ flex: '1 1 45%', marginBottom: 0 }}>
              <Select placeholder="Month">
                {months.map((month, index) => (
                  <Select.Option key={index + 1} value={index + 1}>{month}</Select.Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item name={['birthday', 'year']} style={{ flex: '1 1 30%', marginBottom: 0 }}>
              <Select placeholder="Year">
                {years.map(year => (
                  <Select.Option key={year} value={year}>{year}</Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Flex>
        </Form.Item>

        <Form.Item
          name="city"
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
          rules={[{ required: true, message: 'Please input your city!' }]}
          className="custom-form-item address-complete"
        >
          <AddressAutocomplete/>
        </Form.Item>
      </>),
    },
    {
      title: 'Step 3',
      content: (<>
          <Form.Item
            name="username"
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24 }}
            style={{ marginBottom: 24 }}
            rules={[
            { required: true, message: 'Please input your desired username!' },
            { pattern: /^[a-zA-Z0-9_]+$/, message: 'Username can only contain letters, numbers, and underscores.' },
            { validator: checkUsernameAvailability, message: 'Username is already taken.' }
            ]}
            className="custom-form-item"
          >
            <Input placeholder="Username" />
          </Form.Item>
          <Form.Item
              name="password"
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
              className="custom-form-password"
              rules={[
                {
                  required: true,
                  message: 'Please input your password!',
                }
              ]}
              // hasFeedback
            >
              <Input.Password placeholder="Create a password" />
          </Form.Item>
          <Form.Item
            name="confirm"
            dependencies={['password']}
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24 }}
            className="custom-form-password"
            // hasFeedback
            rules={[
              {
                required: true,
                message: 'Please confirm your password!',
              },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue('password') === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(
                    new Error('The two passwords that you entered do not match!')
                  );
                },
              }),
            ]}
          >
            <Input.Password placeholder="Confirm password" />
        </Form.Item>
        <div className="password-criteria">
            {isLessthenEight ?
              <div className="criteria-item">
                <Image
                  width={25}
                  src={CrossSMIcon}
                  preview={false}
                  style={{ paddingRight: 7, verticalAlign: 'text-bottom' }}
                />
                <span style={{ color: '#d92d20' }}>Must be at least 8 characters</span>
              </div>
              : isValidPassword ?
                <div className="criteria-item">
                  <Image
                    width={25}
                    src={CheckSMSuccess}
                    preview={false}
                    style={{ paddingRight: 7, verticalAlign: 'text-bottom' }}
                  />
                  <span>Must be at least 8 characters</span>
                </div>
              :
              <div className="criteria-item">
                <Image
                  width={25}
                  src={CheckSMIcon}
                  preview={false}
                  style={{ paddingRight: 7, verticalAlign: 'text-bottom' }}
                />
                <span>Must be at least 8 characters</span>
              </div>
            }
            {isOneSpecial ?
              <div className="criteria-item">
                <Image
                  width={25}
                  src={CrossSMIcon}
                  preview={false}
                  style={{ paddingRight: 7, verticalAlign: 'text-bottom' }}
                />
                <span style={{ color: '#d92d20' }}>Must contain one special character</span>
              </div> 
              : isConfirmValidPassword ?
              <div className="criteria-item">
                <Image
                  width={25}
                  src={CheckSMSuccess}
                  preview={false}
                  style={{ paddingRight: 7, verticalAlign: 'text-bottom' }}
                />
                <span>Must contain one special character</span>
              </div> 
              : 
              <div className="criteria-item">
                <Image
                  width={25}
                  src={CheckSMIcon}
                  preview={false}
                  style={{ paddingRight: 7, verticalAlign: 'text-bottom' }}
                />
                <span>Must contain one special character</span>
              </div> 
            }
        </div>
        <Form.Item
          name="terms"
          valuePropName="checked"
          wrapperCol={{ span: 24 }}
          className="custom-form-item"
          // rules={[{ required: true, message: 'Please agree to the Terms of Use!' }]}
          rules={[
            {
              validator: (_, value) =>
                value ? Promise.resolve() : Promise.reject(new Error('Please agree to the Terms of Use!')),
            },
          ]}
        >
          <Checkbox>
            I agree to the <a href="/">Terms of Use</a>
          </Checkbox>
        </Form.Item>
      </>),
    },
  ];

  const next = () => {
    form.validateFields().then((currentStepData) => {
      
      setFormData({ ...formData, ...currentStepData });
      setCurrent(current + 1);
     
    }).catch((info) => {
      console.log('Validation failed:', info);
    });
  };

  const prev = () => {
    setCurrent(current - 1);
  };

  const handleSubmit = async (values) => {
    if(isLessthenEight || isOneSpecial){
      return false;
    }
    setLoading(true);
    try {
      const finalStepData = await form.validateFields();

      const formatDate = ({ day, month, year }) => {
        // Ensure day and month are always two digits
        const formattedDay = day.toString().padStart(2, '0');
        const formattedMonth = month.toString().padStart(2, '0');
        
        // Return formatted date string
        return `${formattedDay}-${formattedMonth}-${year}`;
      };

      const birthday = formData?.birthday;
      const formattedDate = formatDate(birthday);

      const completeFormData = { ...formData, ...finalStepData, birthday:formattedDate, creator_id:creatorId,phone:phone };
      const response = await post('user/register', completeFormData);
      if (response && response.status === 200) {
        openNotification('Form submitted successfully!');
        setCurrent(0);
        form.resetFields();
        navigate(`/audience/payment/${phone}`);
      }else{
        message.error(response.message);
      }
    } catch (error) {
      if (error.response && error.response.data) {
        message.error(error.response.data.message || 'Error submitting form');
      } else {
        message.error(error.message || 'Error submitting form');
      }
    } finally {
      setLoading(false);
    }
  };
  const imageId = data && data.uploadedIds && data.uploadedIds.length > 0 ? data.uploadedIds[0] : null;
  return (
    <Spin size="small" spinning={loading}>
      <Form form={form} onFinish={handleSubmit}
      
      onFieldsChange={(_, allFields) => {
        const passwordField = allFields.find(field => field.name[0] === 'password');
        const confirmPasswordField = allFields.find(field => field.name[0] === 'confirm');
        
        const hasSpecialCharacter = /[!@#$%^&*(),.?":{}|<>]/;
        const passwordValue = passwordField?.value;
        const confirmPasswordValue = confirmPasswordField?.value;

        if ((passwordValue && passwordValue.length < 8) || (confirmPasswordValue && confirmPasswordValue.length < 8)) {
          setIsLessthenEight(true);
        } else {
          setIsLessthenEight(false);
        }

        const isPasswordValid = passwordValue && hasSpecialCharacter.test(passwordValue);
        if (passwordValue && passwordValue.length > 0 && !isPasswordValid) {
          setIsOneSpecial(true);
        } else {
          setIsOneSpecial(false);
        }

        if (passwordValue && passwordValue.length >= 8) {
          setIsValidPassword(true);
        } else {
          setIsValidPassword(false);
        }

        if (passwordValue && isPasswordValid) {
          setIsConfirmValidPassword(true);
        } else {
          setIsConfirmValidPassword(false);
        }

      }}
      validateMessages={validateMessages}>
        <Flex vertical align="center" style={{ marginBottom: '25px' }}>
          <AvatarImage imageId={imageId} title={`${data?.first_name} ${data?.last_name}`} />
          <Title level={4} style={{ textAlign: 'center', marginTop: '20px', marginBottom: '10px', fontSize: '18px' }}>{data?.first_name} {data?.last_name}</Title>
          <Text>Sign up is easy as 1, 2, 3!</Text>
        </Flex>
        <Steps className="steps-nav audience-nav" current={current} style={{ marginBottom: '30px' }}>
          {steps.map((item, index) => (
            <Step key={index} />
          ))}
        </Steps>
        <div className="steps-content audience-steps">
          {steps[current].content}
        </div>
        <div className="steps-action" style={{ marginTop: '24px' }}>
          <Flex
              vertical
              gap="middle"
              style={{
                width: '100%',
              }}
            >
            {current < steps.length - 1 && (
                <Button type="primary" onClick={() => next()} size="large" style={{ fontWeight: 600 }}>
                  Next
                </Button>
            )}
            {current === steps.length - 1 && (
                <Button type="primary" htmlType="submit" size="large" style={{ fontWeight: 600 }}>
                  Get started
                </Button>
            )}
            {current > 0 && (
                <Button type="text" icon={<ArrowLeftOutlined />} onClick={() => prev()} size="large" style={{ fontWeight: 600, fontSize: '14px' }}>
                  Back
                </Button>
            )}
          </Flex>
        </div>
      </Form>
    </Spin>
  );
};

export default StepSignupForm;
