import React, { useState, useEffect } from 'react';
import { Avatar } from "antd";
import { UserOutlined } from '@ant-design/icons';
import { imageUrl, getInitials, getImageUrl } from '../utils/utils';

const AvatarImage = ({ 
    imageId, 
    title, 
    size = 48, 
    font = 18, 
    Url = null, 
    customIcon = null, 
    bgColor = '#98A2B3' 
}) => {
    const [imagesrc, setImagesrc] = useState(null);

    useEffect(() => {
        const init = async () => {
            try {
                if (Url) {
                    const rsp = await getImageUrl(Url);
                    setImagesrc(rsp);
                } else if (imageId) {
                    const rsp = await imageUrl(imageId);
                    if (rsp) {
                        setImagesrc(rsp);
                    }
                }
            } catch (error) {
                console.error('Error fetching image:', error);
            }
        };

        init();
    }, [imageId, Url]);

    return (
        <>
            {imagesrc ? (
                <Avatar 
                    key={imageId} 
                    size={size} 
                    src={<img src={imagesrc} alt={title || 'avatar'} />} 
                />
            ) : (
                <Avatar 
                    size={size} 
                    style={{ 
                        backgroundColor: bgColor, 
                        fontWeight: 600, 
                        fontSize: font 
                    }}
                >
                    {customIcon ? (
                        <img src={customIcon} alt="custom icon" style={{ width: '100%', height: '100%' }} />
                    ) : (
                        title ? getInitials(title) : <UserOutlined style={{ fontSize: '25px' }} />
                    )}
                </Avatar>
            )}
        </>
    );
};

export default AvatarImage;
