import React, { useState,useContext,useEffect } from 'react';
import { Button, Flex, Input, Modal, Image, Dropdown, Typography, Divider, Spin,message as antdMessage } from 'antd';
import {
  InfoCircleOutlined,
  SmileOutlined, 
  EditOutlined,
  CheckCircleOutlined
} from '@ant-design/icons';
import { put } from '../../utils/api';
import { convertStringsToBooleans } from '../../utils/utils';
import { UserContext } from '../../contexts/UserContext';
import EmojiPicker from 'emoji-picker-react';
import PhoneImg from '../../images/phone-img.png';
import HandImg from '../../images/waving-hand.png';
import AnnotationHeartIcon from '../../images/annotation-heart.svg';

const { Title, Text, Paragraph } = Typography;
const { TextArea } = Input;

const Automation = ({settinginfo}) => {
    const [loading, setLoading]=useState(false);
    const [isWelcomeModalVisible, setIsWelecomeModalVisible] = useState(false);
    const [isSignupModalVisible, setIsSignupModalVisible] = useState(false);
    const [isPrivateModalVisible, setIsPrivateModalVisible] = useState(false);
    const [message, setMessage] = useState('');
    const [savedMessage, setSavedMessage] = useState('');
    const [showEmojiPicker, setShowEmojiPicker] = useState(false);
    const {user}  = useContext(UserContext);
    const [messagesignup, setSignupMessage] = useState('Send a message...');
    const [savedSignupMessage, setSignupSavedMessage] = useState('');
    const [showSignupEmojiPicker, setSignupShowEmojiPicker] = useState(false);
    const [formattedMessage, setFormattedMessage] = useState("");

    const maxLength = 220;

    const [messageprivate, setPrivateMessage] = useState('');
    const [savedPrivateMessage, setPrivateSavedMessage] = useState('');
    const [showPrivateEmojiPicker, setPrivateShowEmojiPicker] = useState(false);
    

    const showWelcomeModal = () => {
      setIsWelecomeModalVisible(true);
    };

    useEffect(() => {
      setFormattedMessage(
        `${messagesignup} Msg frequency will vary. Msg&Data rates may apply. Reply HELP for help, STOP to cancel.`
      );
    }, [messagesignup]);

    const handleChange = (e) => {
      if (e.target.value.length <= maxLength) {
        setSignupMessage(e.target.value);
      }
    };

    const handleSave =async () => {
      setSavedMessage(message);
      setIsWelecomeModalVisible(false);
      try {
          const data={welcome_message:message};
        const response = await put(`creator/save-settings/${user?.id}`,data);
        if (response && response.status === 200) {
          antdMessage.success(response.message);
        } else {
          antdMessage.error(response.message || 'Login failed');
        }

      }catch (error) {
        if (error.response && error.response.data) {
          antdMessage.error(error.response.data.message);
        } else {
          antdMessage.error(error.message);
        }
      }finally {
        setLoading(false);
      }
    };
    const handleEmojiClick = (emojiObject) => {
      setMessage((prevMessage) => prevMessage + emojiObject.emoji);
      setShowEmojiPicker(false);
    };

    const showSignupModal = async() => {
      setIsSignupModalVisible(true);
    };
    const handleSignupSave =async () => {
      setSignupSavedMessage(formattedMessage);
      setIsSignupModalVisible(false);
      try {
        const data={signup_message:formattedMessage};
      const response = await put(`creator/save-settings/${user?.id}`,data);
        if (response && response.status === 200) {
          antdMessage.success(response.message);
        } else {
          antdMessage.error(response.message || 'Login failed');
        }

      }catch (error) {
        if (error.response && error.response.data) {
          antdMessage.error(error.response.data.message);
        } else {
          antdMessage.error(error.message);
        }
      }finally {
        setLoading(false);
      }
    };
    const handleSignupEmojiClick = (emojiObject) => {
      setSignupMessage((prevMessage) => prevMessage + emojiObject.emoji);
      setSignupShowEmojiPicker(false);
    };

    const showPrivateModal = () => {
      setIsPrivateModalVisible(true);
    };
    const handlePrivateSave =async () => {
      setPrivateSavedMessage(messageprivate);
      setIsPrivateModalVisible(false);

      try {
        const data={messageprivate:messageprivate};
      const response = await put(`creator/save-settings/${user?.id}`,data);
        if (response && response.status === 200) {
          antdMessage.success(response.message);
        } else {
          antdMessage.error(response.message || 'Login failed');
        }

      }catch (error) {
        if (error.response && error.response.data) {
          antdMessage.error(error.response.data.message);
        } else {
          antdMessage.error(error.message);
        }
      }finally {
        setLoading(false);
      }
    };
    const handlePrivateEmojiClick = (emojiObject) => {
      setPrivateMessage((prevMessage) => prevMessage + emojiObject.emoji);
      setPrivateShowEmojiPicker(false);
    };

    const updatedSettings = convertStringsToBooleans(settinginfo);
    useEffect(()=>{
      if(updatedSettings){
        setSavedMessage(updatedSettings?.welcome_message);
        setMessage(updatedSettings?.welcome_message);
        setSignupSavedMessage(updatedSettings?.signup_message);
        setPrivateMessage(updatedSettings?.messageprivate);
        setPrivateSavedMessage(updatedSettings?.messageprivate);
      }
    },[updatedSettings]);

    const welcomeEmojiDropdown = (
      <div>
        <EmojiPicker onEmojiClick={handleEmojiClick} pickerStyle={{ width: '100%' }} />
      </div>
    );
    const signupEmojiDropdown = (
      <div>
        <EmojiPicker onEmojiClick={handleSignupEmojiClick} pickerStyle={{ width: '100%' }} />
      </div>
    );
    const privateEmojiDropdown = (
      <div>
        <EmojiPicker onEmojiClick={handlePrivateEmojiClick} pickerStyle={{ width: '100%' }} />
      </div>
    );

    return(
      <div>
        <Spin spinning={loading}>
          <Flex className="head-inner" horizotanl justify="space-between">
            <Flex vertical>
                <Title level={4} style={{ marginBottom: '8px', fontSize: '18px' }}>Automation</Title>
                <Text>Write out automation to your fans to make your work easier</Text> 
            </Flex>
          </Flex>

          <Divider />
            
          <Title level={4} style={{ marginBottom: '8px', marginBottom: 30 }}>Welcome</Title>

          <Flex className="automation-steps" gap={0} vertical align="flex-start" style={{ marginBottom: 40 }}>
            
            <Flex className="steps" gap={20} align="flex-start" style={{ marginBottom: 13 }}>
              <Flex justify="center" style={{ borderRadius: 10, border: '1px solid #EAECF0', padding: 10, width: 48, height: 48, minWidth: 48 }}>
                <Image src={PhoneImg} preview={false} />
              </Flex>
              <Flex vertical align="flex-start">
                <Title level={4} style={{ marginTop: 0, marginBottom: 3 }}>Welcome Message</Title>
                {savedMessage ? (<>
                  <Text style={{ maxWidth: 600 }}>{savedMessage}</Text>
                </>) : (
                  <Button type="primary" onClick={showWelcomeModal} size="large" style={{ fontWeight: 600, marginTop: 10 }}>
                    Create Message
                  </Button>
                )}
              </Flex>
              {savedMessage &&
                <Flex style={{ paddingLeft: 0 }}>
                  <Button size="medium" onClick={showWelcomeModal} style={{ fontWeight: 600 }}>
                    Edit
                  </Button>
                </Flex>
              }
            </Flex>

            <Flex className="steps" gap={20} align="flex-start" style={{ marginBottom: 10 }}>
              <Flex justify="center" style={{ borderRadius: 10, border: '1px solid #EAECF0', padding: 10, width: 48, height: 48 }}>
                {savedMessage ? (
                  <CheckCircleOutlined style={{ fontSize: 20, color: '#079455' }} />
                ) : (
                  <InfoCircleOutlined style={{ fontSize: 20 }} />
                )}
              </Flex>
              <Flex vertical align="flex-start" style={{ marginTop: 8 }}>
              {savedMessage ? (
                <Button style={{ borderRadius: 50, color: '#067647', background: '#ECFDF3', borderColor: '#ABEFC6', fontWeight: 500 }}>Fan completes registration</Button>
              ) : ( 
                <Button disabled style={{ borderRadius: 50, color: '#344054', fontWeight: 500 }}>Fan completes registration</Button>
              )}
              </Flex>
            </Flex>

            <Flex className="steps" gap={20} align="flex-start">
              <Flex justify="center" style={{ borderRadius: 10, border: '1px solid #EAECF0', padding: 10, width: 48, height: 48, minWidth: '48px' }}>
                <Image src={HandImg} preview={false} />
              </Flex>
              <Flex vertical align="flex-start">
                <Title level={4} style={{ marginTop: 0, marginBottom: 3 }}>Signup Confirmation Message</Title>
                {savedSignupMessage ? (<>
                  <Text style={{ maxWidth: 600 }}>{savedSignupMessage}</Text>
                </>) : (
                  <Button type="primary" disabled={!savedMessage} onClick={showSignupModal} size="large" style={{ fontWeight: 600, marginTop: 10 }}>
                    Create Message
                  </Button>
                )}
              </Flex>
              {savedSignupMessage &&
                <Flex style={{ paddingLeft: 0 }}>
                  <Button size="medium" onClick={showSignupModal} style={{ fontWeight: 600 }}>
                    Edit
                  </Button>
                </Flex>
              }
            </Flex>

          </Flex>

          <Divider />
          <Title level={4} style={{ marginBottom: '8px', marginBottom: 30 }}>Private Request</Title>

          <Flex className="automation-steps" gap={0} vertical align="flex-start">
            <Flex className="steps" gap={20} align="flex-start" style={{ marginBottom: 13 }}>
              <Flex justify="center" style={{ borderRadius: 10, border: '1px solid #EAECF0', padding: 10, width: 48, height: 48 }}>
                {savedPrivateMessage ? (
                  <CheckCircleOutlined style={{ fontSize: 20, color: '#079455' }} />
                ) : (
                  <InfoCircleOutlined style={{ fontSize: 20 }} />
                )}
              </Flex>
              <Flex vertical align="flex-start" style={{ marginTop: 7 }}>
                {savedPrivateMessage ? (
                  <Button style={{ borderRadius: 50, color: '#067647', background: '#ECFDF3', borderColor: '#ABEFC6', fontWeight: 500 }}>Fan submits private request</Button>
                ) : ( 
                  <Button disabled style={{ borderRadius: 50, color: '#344054', fontWeight: 500 }}>Fan submits private request</Button>
                )}
              </Flex>
            </Flex>
            <Flex className="steps" gap={20} align="flex-start">
              <Flex justify="center" align="center" style={{ borderRadius: 10, border: '1px solid #EAECF0', padding: 10, width: 48, height: 48, minWidth: '48px' }}>
                <Image src={AnnotationHeartIcon} preview={false} />                
              </Flex>
              <Flex vertical align="flex-start">
                <Title level={4} style={{ marginTop: 0, marginBottom: 15 }}>Private Request Message</Title>
                {savedPrivateMessage ? (<>
                  <Text style={{ maxWidth: 600 }}>{savedPrivateMessage}</Text>
                </>) : (
                  <Button type="primary" onClick={showPrivateModal} size="large" style={{ fontWeight: 600 }}>
                    Create Message
                  </Button>
                )}
              </Flex>
              {savedPrivateMessage &&
                <Flex style={{ paddingLeft: 0 }}>
                  <Button size="medium" onClick={showPrivateModal} style={{ fontWeight: 600 }}>
                    Edit
                  </Button>
                </Flex>
              }
            </Flex>
          </Flex>

          {/* Show Welcome Modal */}
          <Modal
            visible={isWelcomeModalVisible}
            onCancel={() => setIsWelecomeModalVisible(false)}
            footer={[
              <Button size="large" onClick={() => setIsWelecomeModalVisible(false)} style={{ fontWeight: 600 }}>Cancel</Button>,
              <Button size="large" type="primary" onClick={handleSave} style={{ fontWeight: 600 }}>Save</Button>
            ]}
            width={640}
            className="automation-popup"
          >
            <Flex justify="center" style={{ borderRadius: 10, border: '1px solid #EAECF0', padding: 10, width: 48, height: 48, boxSizing: 'border-box', marginBottom: 15 }}>
              <Image src={PhoneImg} preview={false} />
            </Flex>
            <Title level={4} style={{ marginTop: 0, marginBottom: 15 }}>Welcome Message</Title>
            <Text style={{ marginBottom: 20, display: 'block' }}>Personalize the first message your Members receive when they text your Blonde Number. Encourage them to click the link & save their contact info.</Text>
            <Flex className="setting-message-area" style={{ position: 'relative' }}>
              <TextArea
                rows={12}
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                placeholder="Send a message..."
                style={{ marginBottom: 0 }}
              />
              <Dropdown
                open={showEmojiPicker}
                onOpenChange={(visible) => {
                    setShowEmojiPicker(visible);
                }}
                overlay={welcomeEmojiDropdown}
                trigger={['click']}
                placement="topLeft"
                destroyPopupOnHide={false}
              >
                <span style={{ cursor: 'pointer' }}>
                  <SmileOutlined style={{ fontSize: 18, color: '#475467' }} />
                </span>
              </Dropdown>

              {/*<Button
                icon={<SmileOutlined />}
                onClick={() => setShowEmojiPicker(!showEmojiPicker)}
                style={{ border: '0 none', padding: 0, width: 'auto', height: 'auto' }}
              >
              </Button>
              {showEmojiPicker && (
                <div className="emoji-picker-wrapper">
                  <EmojiPicker onEmojiClick={handleEmojiClick} pickerStyle={{ width: '100%' }} />
                </div>
              )}*/}
            </Flex>
          </Modal>

          {/* Show Signup Modal */}
          <Modal
            visible={isSignupModalVisible}
            onCancel={() => setIsSignupModalVisible(false)}
            footer={[
              <Button size="large" onClick={() => setIsSignupModalVisible(false)} style={{ fontWeight: 600 }}>Cancel</Button>,
              <Button size="large" type="primary" onClick={handleSignupSave} style={{ fontWeight: 600 }}>Save</Button>
            ]}
            width={640}
            className="automation-popup"
          >
            <Flex justify="center" style={{ borderRadius: 10, border: '1px solid #EAECF0', padding: 10, width: 48, height: 48, boxSizing: 'border-box', marginBottom: 15 }}>
              <Image src={HandImg} preview={false} />
            </Flex>
            <Title level={4} style={{ marginTop: 0, marginBottom: 5 }}>Signup Confirmation</Title>
            <Text style={{ marginBottom: 20, display: 'block' }}>Create a personalized response sent to your Members after they sign up to your Blonde Number.</Text>
            <Title level={5} style={{ marginTop: 0, marginBottom: 5, fontSize: 14 }}>This message should contain the following</Title>
            <ol style={{ margin: 0, padding: 0, listStylePosition: 'inside' }}>
              <li>Tell them they've been added to your phone and to save your contact info.</li>
            </ol>
            <Flex className="automation-message-area" style={{ position: 'relative', marginTop: 20 }}>
              <TextArea
                rows={5}
                value={messagesignup}
                onChange={handleChange}

                style={{ marginBottom: 0 }}
              />
              <span className="textarea-value">{messagesignup} <span style={{ color: 'rgb(108, 108, 108)' }}>Msg frequency will vary. Msg&Data rates may apply. Reply HELP for help, STOP to cancel.</span></span>
              
              <Dropdown
                open={showSignupEmojiPicker}
                onOpenChange={(visible) => {
                    setSignupShowEmojiPicker(visible);
                }}
                overlay={signupEmojiDropdown}
                trigger={['click']}
                placement="topLeft"
                destroyPopupOnHide={false}
              >
                <span style={{ cursor: 'pointer' }}>
                  <SmileOutlined style={{ fontSize: 18, color: '#475467' }} />
                </span>
              </Dropdown>

              {/*<Button
                icon={<SmileOutlined />}
                onClick={() => setSignupShowEmojiPicker(!showSignupEmojiPicker)}
                style={{ border: '0 none', padding: 0, width: 'auto', height: 'auto' }}
              >
              </Button>
              {showSignupEmojiPicker && (
                <div className="emoji-picker-wrapper">
                  <EmojiPicker onEmojiClick={handleSignupEmojiClick} pickerStyle={{ width: '100%' }} />
                </div>
              )}*/}
            </Flex>
          </Modal>

          {/* Show Private Request Modal */}
          <Modal
            visible={isPrivateModalVisible}
            onCancel={() => setIsPrivateModalVisible(false)}
            footer={[
              <Button size="large" onClick={() => setIsPrivateModalVisible(false)} style={{ fontWeight: 600 }}>Cancel</Button>,
              <Button size="large" type="primary" onClick={handlePrivateSave} style={{ fontWeight: 600 }}>Save</Button>
            ]}
            width={640}
            className="automation-popup"
          >
            <Flex justify="center" style={{ borderRadius: 10, border: '1px solid #EAECF0', padding: 10, width: 48, height: 48, boxSizing: 'border-box', marginBottom: 15, alignItems: 'center' }}>
              <Image src={AnnotationHeartIcon} preview={false} />
            </Flex>
            <Title level={4} style={{ marginTop: 0, marginBottom: 5 }}>Private Request Message</Title>
            <Text style={{ marginBottom: 20, display: 'block' }}>Personalize the first message your Members receive when they request a private request from your blonde account.</Text>
            <Flex className="setting-message-area" style={{ position: 'relative' }}>
              <TextArea
                rows={12}
                value={messageprivate}
                onChange={(e) => setPrivateMessage(e.target.value)}
                placeholder="Send a message..."
                style={{ marginBottom: 0 }}
              />

              <Dropdown
                open={showPrivateEmojiPicker}
                onOpenChange={(visible) => {
                    setPrivateShowEmojiPicker(visible);
                }}
                overlay={privateEmojiDropdown}
                trigger={['click']}
                placement="topLeft"
                destroyPopupOnHide={false}
              >
                <span style={{ cursor: 'pointer' }}>
                  <SmileOutlined style={{ fontSize: 18, color: '#475467' }} />
                </span>
              </Dropdown>

              {/*<Button
                icon={<SmileOutlined />}
                onClick={() => setPrivateShowEmojiPicker(!showPrivateEmojiPicker)}
                style={{ border: '0 none', padding: 0, width: 'auto', height: 'auto' }}
              >
              </Button>
              {showPrivateEmojiPicker && (
                <div className="emoji-picker-wrapper">
                  <EmojiPicker onEmojiClick={handlePrivateEmojiClick} pickerStyle={{ width: '100%' }} />
                </div>
              )}*/}
            </Flex>
          </Modal>

        </Spin>
      </div>
    );
};

export default Automation;