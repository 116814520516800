import React from 'react';
import { Layout, Col, Row, Typography } from 'antd';

const { Title, Paragraph } = Typography;
const { Content } = Layout;

const TermsService = () => {

  return (
    <Content style={{ padding: '0 30px' }}>
      <Row style={{ justifyContent: 'center' }}>
      <Col 
        xs={{
          span: 24,
        }}
        lg={{
          span: 16,
        }}
      >
      <Title level={2} style={{ marginBottom: 20, marginTop: 0 }}>Terms of Service</Title>
      <Paragraph>
        By accessing or using our Service, you agree to be bound by these Terms. If you disagree with any part of the terms, then you may not access the Service.
      </Paragraph>
      <Title level={4}>1. Accounts</Title>
      <Paragraph>When you create an account with us, you must provide information that is accurate, complete, and current at all times. Failure to do so constitutes a breach of the Terms, which may result in immediate termination of your account on our Service.
      </Paragraph>
      <Paragraph>You are responsible for safeguarding the password you use to access the Service and for any activities or actions under your password.</Paragraph>

      <Title level={4}>2. Privacy</Title>
      <Paragraph>Our [Privacy Policy](link to Privacy Policy) describes how we collect, use, and share information about you when you use our Service. By using our Service, you agree to our Privacy Policy.</Paragraph>

      <Title level={4}>3. Intellectual Property</Title>
      <Paragraph>The Service and its original content, features, and functionality are and will remain the exclusive property of [Your Company Name] and its licensors. Our trademarks and trade dress may not be used in connection with any product or service without prior written consent.</Paragraph>

      <Title level={4}>4. User Conduct</Title>
      <Paragraph>You agree not to misuse our Service. This includes, but is not limited to, using the Service for any unlawful purpose, engaging in activity that exploits or harms minors, or interfering with the Service’s operations.</Paragraph>

      <Title level={4}>5. Termination</Title>
      <Paragraph>We may terminate or suspend your access to our Service immediately, without prior notice or liability, if you breach the Terms. Upon termination, your right to use the Service will cease immediately.</Paragraph>

      <Title level={4}>6. Limitation of Liability</Title>
      <Paragraph>In no event shall [Your Company Name], nor its directors, employees, partners, agents, suppliers, or affiliates, be liable for any indirect, incidental, special, consequential, or punitive damages, including without limitation, loss of profits, data, use, goodwill, or other intangible losses.</Paragraph>

      <Title level={4}>7. Governing Law</Title>
      <Paragraph>These Terms shall be governed and construed in accordance with the laws of [State/Country], without regard to its conflict of law provisions.</Paragraph>

      <Title level={4}>8. Changes to Terms</Title>
      <Paragraph>We reserve the right to update or modify these Terms at any time. We will notify you of any changes by posting the new Terms on this page. Your continued use of the Service after any modifications indicates your acceptance of the revised Terms.</Paragraph>

      <Title level={4}>9. Contact Us</Title>
      <Paragraph>If you have any questions about these Terms, please contact us at [Contact Information].</Paragraph>
      </Col>
      </Row>
    </Content>
  );
};

export default TermsService;
