import React, { useState } from 'react';
import { Form, Input, Button, Steps, Typography, DatePicker, Checkbox, Flex, Select, Spin, message } from 'antd';
import { useNavigate, Link, useLocation } from 'react-router-dom';
// import DatePicker from 'react-date-picker';
// import InputMask from 'react-input-mask';
import { useMask } from "@react-input/mask";
import dayjs from 'dayjs';
import { ArrowLeftOutlined, CloseOutlined } from '@ant-design/icons';
import './StepForm.css';
import CodeInput from '../CodeInput';
import { post,get } from '../../utils/api';
import useScreenSize from '../../utils/useScreenSize';
import { openNotification } from '../../utils/utils';
const { Step } = Steps;
const { Title, Paragraph } = Typography;
const CheckboxGroup = Checkbox.Group;
const plainOptions = ['Actor', 'Activist', 'Agency', 'Automotive', 'Artist', 'Beauty', 'Business', 'Blogger', 'Celebrity', 'Consulting', 'Education', 'Entertainment', 'Fashion', 'Film', 'Finance', 'Fitness', 'Food', 'Gaming', 'Government', 'Health & Wellness', 'Hospitality', 'Lifestyle', 'Management', 'Manufacturing', 'Media', 'Model', 'Motivation', 'Musician', 'Photographer', 'Pets', 'Politics', 'Real estate', 'Social Work', 'Sports', 'Travel', 'Tech', 'Vlogger', 'Videographer'];

const { Option } = Select;


const days = Array.from({ length: 31 }, (_, i) => i + 1);
const months = [
  'January', 'February', 'March', 'April', 'May', 'June',
  'July', 'August', 'September', 'October', 'November', 'December'
];
const currentYear = new Date().getFullYear();
const startYear = currentYear - 50;
const years = Array.from({ length: 51 }, (_, i) => startYear + i);

const getDaysInMonth = (month, year) => {
  return dayjs(`${year}-${month}-01`).daysInMonth();
};


const StepForm = () => {
  const { isMobile } = useScreenSize();
  const location = useLocation();
  const [code, setCode] = useState('');
  const [current, setCurrent] = useState(0);
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [checkedList, setCheckedList] = useState([]);
  const [hearAboutUs, sethearAboutUs] = useState(null);
  const [formData, setFormData] = useState({});
  const tenYearsAgo = dayjs().subtract(18, 'year');
  const navigate = useNavigate();
  const [date, setDate] = useState(null);
  const maxDate = dayjs();
  const [day, setDay] = useState('');
  const [month, setMonth] = useState('');
  const [year, setYear] = useState('');
  const [formErrors, setFormErrors] = useState([]);


  const onChange = (list) => {
    setCheckedList(list);
  };
  const handleChange=(value)=>{
    sethearAboutUs(value);
  }
  const extractNumbers = (input) => {
    const numbersOnly = input.replace(/\D/g, ''); // Remove non-numeric characters
     return numbersOnly.slice(0, 10);
  };

  const validateMessages = {
    required: '${label} is required!',
    types: {
      email: '${label} is not a valid email!',
      number: '${label} is not a valid number!',
    },
  };

  // const validatePhoneNumber = (_, value) => {
  //   const onlyNumbers = extractNumbers(value);
  
  //   const phoneRegex = /^\d{10}$/; // Example: 1234567890 (10 digits)
  //   if (!onlyNumbers || phoneRegex.test(onlyNumbers)) {
  //     return Promise.resolve();
  //   }
  //   return Promise.reject(new Error('Please enter a valid 10-digit phone number!'));
  // };

  const validatePhoneNumber = (_, value) => {
    if (!value) {
      return Promise.reject(new Error(''));
    }
    const strippedValue = value.replace(/\D/g, '');
    console.log(strippedValue, 'value')    
    if (strippedValue.length === 10) {
      return Promise.resolve();
    }
    return Promise.reject(new Error('Please enter a valid 10-digit phone number!'));
  };

  const inputRef = useMask({
    mask: "(___) ___-____",
    replacement: { _: /\d/ },
  });

  const inputCalendarRef = useMask({
    mask: "dd-mm-yyyy",
    replacement: { _: /\d/ },
  });

  const checkUsernameAvailability = async (_, value) => {
    try {
      const response = await get(`auth/check-username/${value}`);
      if (response.available) {
        return Promise.resolve(); // Username is available
      } else {
        return Promise.reject('Username is already taken.');
      }
    } catch (error) {
      return Promise.reject('Failed to check username availability.');
    }
  };


  const checkEmailAvailability = async (_, value) => {
    try {
      const response = await get(`auth/checkemail/${value}`);
      if (response.available) {
        return Promise.resolve(); // Username is available
      } else {
        return Promise.reject('Username is already taken.');
      }
    } catch (error) {
      return Promise.reject('Failed to check username availability.');
    }
  };

  const handleDateChange = (date) => {
    if (date && dayjs(date).isBefore(maxDate, 'day')) {
      setDate(date);
      const formattedDate = dayjs(date).format('DD-MM-YYYY');
      form.setFieldsValue({ birthday: formattedDate });
    } else {
      message.error('The selected date cannot be in the future.');
    }
  };

  const handleDateInputChange = (e) => {
    let value = e.target.value.replace(/\D/g, '');

    if (value.length >= 2 && value.length <= 4) {
      value = value.replace(/(\d{2})(\d{1,2})/, '$1-$2');
    } else if (value.length > 4) {
      value = value.replace(/(\d{2})(\d{2})(\d{1,4})/, '$1-$2-$3');
    }

    const parsedDate = dayjs(value, 'DD-MM-YYYY', true).toDate();
    if (dayjs(parsedDate).isBefore(maxDate, 'day')) {
      setDate(parsedDate);
      form.setFieldsValue({ birthday: value });
    }
  }; 

  const clearDate = () => {
    setDate(null);
    form.setFieldsValue({ birthday: '' });
  };

  // const validateDate = (_, value) => {
  //   if (dayjs(value, 'DD-MM-YYYY', true).isValid() && dayjs(value, 'DD-MM-YYYY').isBefore(maxDate, 'day')) {
  //     return Promise.resolve();
  //   }
  //   return Promise.reject(new Error('Please enter a valid date in DD-MM-YYYY format and not in the future!'));
  // };


  // const validateDate = (_, value) => {
  //   if (!value || !value.day || !value.month || !value.year) {
  //     return Promise.reject(new Error('Please enter a valid date in DD-MM-YYYY format and not in the future!'));
  //   }
  //   const selectedDate = new Date(value.year, value.month - 1, value.day);
  //   const formattedDate = dayjs(selectedDate).format('DD-MM-YYYY');
  //   if (formattedDate > new Date()) {
  //     return Promise.reject(new Error('Date of birth cannot be in the future!'));
  //   }
  //   return Promise.resolve();
  // };

  const validateDate = (_, value) => {
    if (!value || !value.day || !value.month || !value.year) {
      return Promise.reject(new Error('Please enter a valid date!'));
    }
    const { day, month, year } = value;
    const daysInMonth = getDaysInMonth(month, year);
    
    if (day < 1 || day > daysInMonth) {
      return Promise.reject(new Error(`Invalid day for the selected month! ${daysInMonth} days available.`));
    }
    const selectedDate = new Date(year, month - 1, day);
    const currentDate = new Date();

    if (selectedDate > currentDate) {
      return Promise.reject(new Error('Date of birth cannot be in the future!'));
    }
    return Promise.resolve();
  };

  const steps = [
    {
      title: 'Step 1',
      content: (<>
        <Form.Item
          name="firstname"
          label="First name"
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
          rules={[{ required: true, message: 'Please input your first name!' }]}
          className="custom-form-item"
        >
          <Input placeholder="Enter your first name" style={{ padding: '7px 10px' }} />
        </Form.Item>
        <Form.Item
          name="lastname"
          label="Last name"
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
          rules={[{ required: true, message: 'Please input your last name!' }]}
          className="custom-form-item"
        >
          <Input placeholder="Enter your last name" style={{ padding: '7px 10px' }} />
        </Form.Item>
        <Form.Item
          name="email"
          label="Email*"
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
          rules={[
            { required: true, type: 'email' },
            { validator: checkEmailAvailability, message: 'Email is already registered.' }]}
          className="custom-form-item"
        >
          <Input placeholder="Enter your email" style={{ padding: '7px 10px' }} />
        </Form.Item>
        <Form.Item
          name="phone"
          label="Phone*"
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
          rules={[{ required: true, message: 'Please input your phone number!' },{ validator: validatePhoneNumber }]}
          className="custom-form-item"
        >
          {/*<InputMask mask="(999) 999-9999" maskChar={null}>
            {(inputProps) => <Input {...inputProps} style={{ padding: '7px 10px' }} placeholder="(000) 000-0000" />}
          </InputMask>*/}

          <input className="ant-input phone-input" ref={inputRef} placeholder="(000) 000-0000" />

        </Form.Item>
        {/*<Form.Item
          name="birthday"
          label="Confirm your age"
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
          className="cst-datepicker"
          rules={[
            { required: true, message: 'Please enter your birthday!' },
            { validator: validateDate },
          ]}
        >
          <Input
            maxLength={10}
            onChange={handleDateInputChange}
            style={{ width: '100%', padding: '7px 10px' }}
          />
          <DatePicker
            onChange={handleDateChange}
            value={date}
            format="dd-MM-yyyy"
            calendarIcon={<span role="img" aria-label="calendar">📅</span>}
            clearIcon={<CloseOutlined onClick={clearDate} />}
            maxDate={maxDate.toDate()}
          />
        </Form.Item>*/}

        <Form.Item
          name="birthday"
          label="Confirm your age"
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
          className="custom-form-item cst-datepicker"
          rules={[
            { required: true, message: 'Please enter your birthday!' },
            { validator: validateDate },
          ]}
        >
          <Flex gap={10}>
            <Form.Item name={['birthday', 'day']} style={{ flex: '1 1 25%', marginBottom: 0 }}>
              <Select placeholder="Day">
                {days.map(day => (
                  <Select.Option key={day} value={day}>{day}</Select.Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item name={['birthday', 'month']} style={{ flex: '1 1 45%', marginBottom: 0 }}>
              <Select placeholder="Month">
                {months.map((month, index) => (
                  <Select.Option key={index + 1} value={index + 1}>{month}</Select.Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item name={['birthday', 'year']} style={{ flex: '1 1 30%', marginBottom: 0 }}>
              <Select placeholder="Year">
                {years.map(year => (
                  <Select.Option key={year} value={year}>{year}</Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Flex>
        </Form.Item>

        <Form.Item
            name="terms"
            valuePropName="checked"
            wrapperCol={{ span: 24 }}
            className="custom-form-item checkbox-with-text"
            // rules={[{ required: true, message: 'Please agree to the Terms of Use!' }]}
            rules={[
              {
                validator: (_, value) =>
                  value ? Promise.resolve() : Promise.reject(new Error('Please agree to the Terms of Use!')),
              },
            ]}
          >
            <Checkbox>
              I agree to the <Link to="/creator-terms-use" target='_blank' style={{ textDecoration: 'underline' }}>Terms of Use</Link> the <Link target='_blank' to="/privacy-policy" style={{ textDecoration: 'underline' }}>Privacy Policy</Link>
            </Checkbox>
          </Form.Item>

          <Form.Item
            name="smsUpdates"
            valuePropName="checked"
            className="sms-updates custom-form-item"
          >
            <Checkbox>
              I agree to receive SMS messages and updates. (Reply "STOP" to end SMS messages and updates. Reply "HELP" for help.)
            </Checkbox>
          </Form.Item>
      </>),
    },
    {
      title: 'Step 2',
      content: (<>
        <Form.Item
          name="desiredusername"
          label="Desired Username*"
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
          rules={[
          { required: true, message: 'Please input your desired username!' },
          { pattern: /^[a-zA-Z0-9_]+$/, message: 'Username can only contain letters, numbers, and underscores.' },
          { validator: checkUsernameAvailability, message: 'Username is already taken.' }
          ]}
          className="custom-form-item"
        >
          <Input addonBefore={<span>@</span>} placeholder="username" style={{ padding: '7px 0px' }} />
        </Form.Item>
        <Title level={4} style={{ fontWeight: 'normal', fontSize: '18px' }}>Social Platforms</Title>
        <Form.Item
          name="instagram"
          label="Instagram"
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
          rules={[{ required: false, message: 'Please input your instagram username!' }]}
          className="custom-form-item"
        >
          <Input addonBefore={<span>@</span>} placeholder="instagram" style={{ padding: '7px 0px' }} />
        </Form.Item>
        <Form.Item
          name="facebook"
          label="Facebook"
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
          rules={[{ required: false, message: 'Please input your facebook username!' }]}
          className="custom-form-item"
        >
          <Input addonBefore={<span>@</span>} placeholder="facebook" style={{ padding: '7px 0px' }} />
        </Form.Item>
        <Form.Item
          name="snapchat"
          label="Snapchat"
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
          rules={[{ required: false, message: 'Please input your snapchat username!' }]}
          className="custom-form-item"
        >
          <Input addonBefore={<span>@</span>} placeholder="snapchat" style={{ padding: '7px 0px' }} />
        </Form.Item>
        <Form.Item
          name="youtube"
          label="YouTube"
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
          rules={[{ required: false, message: 'Please input your youtube username!' }]}
          className="custom-form-item"
        >
          <Input addonBefore={<span>@</span>} placeholder="youtube" style={{ padding: '7px 0px' }} />
        </Form.Item>
        <Form.Item
          name="tiktok"
          label="TikTok"
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
          rules={[{ required: false, message: 'Please input your tiktok username!' }]}
          className="custom-form-item"
        >
          <Input addonBefore={<span>@</span>} placeholder="tiktok" style={{ padding: '7px 0px' }} />
        </Form.Item>
        <Form.Item
          name="other"
          label="Other"
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
          rules={[{ required: false, message: 'Please input your other username!' }]}
          className="custom-form-item"
        >
          <Input addonBefore={<span>@</span>} placeholder="" style={{ padding: '7px 0px' }} />
        </Form.Item>
      </>),
    },
    {
      title: 'Step 3',
      content: (<>
        <Title level={4} style={{ fontWeight: '500', fontSize: '14px', marginBottom: '10px' }}>Tell us about yourself</Title>
        <CheckboxGroup className="checkbox-list" options={plainOptions} value={checkedList} onChange={onChange} />
        <Title level={4} style={{ fontWeight: '500', fontSize: '14px' }}>How did you hear about us?</Title>
        <Select
          showSearch
          style={{
            width: '100%',
          }}
          placeholder="Select one"
          optionFilterProp="children"
          filterOption={(input, option) => (option?.label ?? '').includes(input)}
          filterSort={(optionA, optionB) =>
            (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
          }
          className="custom-form-select"
          onChange={handleChange}
          options={[
            {
              value: 'Not Identified',
              label: 'Not Identified',
            },
            {
              value: 'Closed',
              label: 'Closed',
            },
            {
              value: 'Communicated',
              label: 'Communicated',
            },
            {
              value: 'Identified',
              label: 'Identified',
            },
            {
              value: 'Resolved',
              label: 'Resolved',
            },
            {
              value: 'Cancelled',
              label: 'Cancelled',
            },
          ]}
        />
      </>),
    },
  ];

  const next = () => {
    form.validateFields().then((currentStepData) => {
      
      setFormData({ ...formData, ...currentStepData });
      setCurrent(current + 1);
     
    }).catch((info) => {
      console.log('Validation failed:', info);
    });
  };

  const prev = () => {
    setCurrent(current - 1);
  };

  const handleSubmit = async (values) => {
    setLoading(true);
    try {
      const finalStepData = await form.validateFields();

      const formatDate = ({ day, month, year }) => {
        // Ensure day and month are always two digits
        const formattedDay = day.toString().padStart(2, '0');
        const formattedMonth = month.toString().padStart(2, '0');
        
        // Return formatted date string
        return `${formattedDay}-${formattedMonth}-${year}`;
      };

      // Example usage
      const birthday = formData?.birthday;
      const formattedDate = formatDate(birthday);

      const completeFormData = { ...formData, ...finalStepData, birthday:formattedDate, interests: checkedList,hearAboutUs,code:code };
    
      const response = await post('auth/register', completeFormData);
      if (response && response.status === 200) {
        openNotification('Form submitted successfully!');
        setCurrent(0);
        form.resetFields();
        setCheckedList([]);
        sethearAboutUs(null);
        navigate('/signup/confirmation');
      }else{
        message.error(response.message);
      }
    } catch (error) {
      if (error.response && error.response.data) {
        message.error(error.response.data.message || 'Error submitting form');
      } else {
        message.error(error.message || 'Error submitting form');
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <Spin size="small" spinning={loading}>
    <Form form={form} onFinish={handleSubmit} validateMessages={validateMessages}>
      <Steps className="steps-nav" current={current} style={{ marginBottom: isMobile ? '0px' : '24px' }}>
        {steps.map((item, index) => (
          <Step key={index} />
        ))}
      </Steps>
      <Title level={2} style={{ textAlign: 'center', marginTop: isMobile ? '20px' : '40px', marginBottom: isMobile ? '20px' : '40px' }}>Creator Sign Up</Title>

      {current === 0 && <>
        <Title level={5} style={{ textAlign: 'center', marginTop: isMobile ? '0px' : '40px', marginBottom: '10px', fontSize: '14px' }}>Referral Code (If you have one)</Title>
        <CodeInput setCode={setCode} />
      </>}

      <div className="steps-content">
        {steps[current].content}
      </div>
      <div className="steps-action" style={{ marginTop: '24px' }}>
        <Flex
            vertical
            gap="middle"
            style={{
              width: '100%',
            }}
          >
          {current < steps.length - 1 && (
              <Button type="primary" onClick={() => next()} size="large" style={{ fontWeight: 600 }}>
                Continue
              </Button>
          )}
          {current === steps.length - 1 && (
              <Button type="primary" htmlType="submit" size="large" style={{ fontWeight: 600 }}>
                Submit Application
              </Button>
          )}
          {current > 0 && (
              <Button type="text" icon={<ArrowLeftOutlined />} onClick={() => prev()} size="large" style={{ fontWeight: 600, fontSize: '14px' }}>
                Back
              </Button>
          )}
        </Flex>
      </div>

      {isMobile &&
          <Flex vertical style={{ marginTop: 20, textAlign: 'center' }}>
            {location.pathname === '/signup' ?
              <Paragraph>
                Already have an account? <Link to="/" style={{ fontWeight: 600 }}>Log in</Link>
              </Paragraph>
            : 
              <Paragraph>
                Don’t have an account? <Link to="/signup" style={{ fontWeight: 600 }}>Sign up</Link>
              </Paragraph>
             }
          </Flex>
        }
    </Form>
    </Spin>
  );
};

export default StepForm;
