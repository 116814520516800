import React from 'react';
import { Layout, Col, Row, Typography } from 'antd';

const { Title, Paragraph } = Typography;
const { Content } = Layout;

const PaymentTermsConditions = () => {

  return (
    <Content style={{ padding: '0 30px' }}>
      <Row style={{ justifyContent: 'center' }}>
        <Col 
          xs={{
            span: 24,
          }}
          lg={{
            span: 16,
          }}
        >
          <Title level={2} style={{ marginBottom: 20, marginTop: 0 }}>Payment Terms and Conditions</Title>
          <Paragraph>
            These Payment Terms and Conditions ("Payment Terms") govern all payments made on [Platform Name]. By making any payment or purchase, you agree to these Payment Terms, along with our 
            [Terms of Service](link to Terms of Service) and [Privacy Policy](link to Privacy Policy). Please review these terms carefully.
          </Paragraph>

          <Title level={4}>1. Payment Methods</Title>
          <Paragraph>
            We accept various payment methods, including credit/debit cards, bank transfers, and third-party payment processors. 
            You agree to provide accurate payment information and authorize us to charge the provided payment method for all fees and charges incurred.
          </Paragraph>

          <Title level={4}>2. Fees and Charges</Title>
          <Paragraph>
            All applicable fees, including taxes and other charges, are stated at the time of purchase. You are responsible for these charges, 
            and any failure to pay may result in service suspension or account restrictions. [Platform Name] reserves the right to modify fees 
            and charges at any time.
          </Paragraph>

          <Title level={4}>3. Currency</Title>
          <Paragraph>
            All transactions are processed in [Currency, e.g., USD]. If your local currency differs, conversion rates may apply as determined by your bank or payment provider.
          </Paragraph>

          <Title level={4}>4. Refunds and Cancellations</Title>
          <Paragraph>
            Purchases on [Platform Name] may be subject to limited refund or cancellation policies. Please review any specific terms outlined at the time of purchase, 
            as certain transactions may be final and non-refundable.
          </Paragraph>

          <Title level={4}>5. Payment Disputes</Title>
          <Paragraph>
            If you believe there was an error with a payment or wish to dispute a charge, please contact us at [Contact Information] within 30 days. 
            We will review the issue and determine an appropriate resolution.
          </Paragraph>

          <Title level={4}>6. Failed Transactions</Title>
          <Paragraph>
            If a transaction fails due to insufficient funds, expired payment methods, or incorrect information, you may need to update your payment details or use an alternative method. 
            [Platform Name] reserves the right to retry failed transactions.
          </Paragraph>

          <Title level={4}>7. Chargebacks</Title>
          <Paragraph>
            Unauthorized chargebacks or disputes may result in service suspension and additional fees. Please reach out to our support team for any 
            payment-related issues before initiating a chargeback with your bank or payment provider.
          </Paragraph>

          <Title level={4}>8. Taxes</Title>
          <Paragraph>
            You are responsible for any applicable taxes related to your transactions on [Platform Name]. Tax requirements may vary based on your location and local regulations.
          </Paragraph>

          <Title level={4}>9. Modifications to Payment Terms</Title>
          <Paragraph>
            [Platform Name] reserves the right to modify these Payment Terms at any time. Significant changes will be communicated to users, 
            and continued use of the platform signifies acceptance of the updated terms.
          </Paragraph>

          <Title level={4}>10. Contact Us</Title>
          <Paragraph>
            For any questions or concerns about these Payment Terms and Conditions, please contact us at [Contact Information].
          </Paragraph>
        </Col>
      </Row>
    </Content>
  );
};

export default PaymentTermsConditions;
