import React,{useEffect,useState} from 'react';
import { Layout, Row, Col, Flex, Typography } from 'antd';
import TotalFans from '../../components/Insights/TotalFans';
import ThisWeeksRevenue from '../../components/Insights/ThisWeeksRevenue';
import GenderIdentity from '../../components/Insights/GenderIdentity';
import Locations from '../../components/Insights/Locations';
import Age from '../../components/Insights/Age';
import Engagement from '../../components/Insights/Engagement';
import moment from 'moment';
import { get } from '../../utils/api';
import useScreenSize from '../../utils/useScreenSize';
import './insights.css';

const { Title, Text } = Typography;

const Insights = () =>{
  const [data,setData]=useState(null);
  const { isMobile } = useScreenSize();

  const fetchData=async()=>{
    const response = await get(`creator/analytics`);
    if(response && response?.status){
      setData(response?.data);
    }
  }
  useEffect(()=>{
    fetchData();
  },[]);

  return (
  <Layout style={ isMobile ? { padding: '30px 15px', backgroundColor: '#F9FAFB', minHeight: '100vh' } : { padding: 30, backgroundColor: '#F9FAFB', minHeight: '100vh' }}>
    <Flex className="content-head-area" style={{ marginBottom: '30px' }} vertical justify="space-between">
      <Title level={2} style={{ marginBottom: '8px' }}>Audience Insights</Title>
      <Text style={{ fontSize: '16px' }}>Last updated # {moment().format('D-MM-YYYY HH:mm:ss A')}</Text>
    </Flex>
    <Row gutter={[20, 20]}>
      <Col xs={{ span: 24 }} md={{ span: 8 }} lg={{ span: 8 }}><TotalFans data={data} /></Col>
      <Col xs={{ span: 24 }} md={{ span: 8 }} lg={{ span: 8 }}><ThisWeeksRevenue data={data} /></Col>
      <Col xs={{ span: 24 }} md={{ span: 8 }} lg={{ span: 8 }}><GenderIdentity data={data} /></Col>
    </Row>
    <Row gutter={[20, 20]} style={ isMobile ? { marginTop: 20 } : { marginTop: 20 }}>
      <Col xs={{ span: 24 }} md={{ span: 8 }} lg={{ span: 8 }}><Locations data={data} /></Col>
      <Col xs={{ span: 24 }} md={{ span: 8 }} lg={{ span: 8 }}><Age data={data} /></Col>
      <Col xs={{ span: 24 }} md={{ span: 8 }} lg={{ span: 8 }}><Engagement data={data} /></Col>
    </Row>
  </Layout>)
};

export default Insights;
