import React, { useState ,useEffect,useContext} from 'react';
import { Form, Input, Button, Steps, Typography, DatePicker, Checkbox, Flex, Select, Divider, Modal, Radio, message } from 'antd';
import { ArrowLeftOutlined, PlusOutlined, EditOutlined, CloseCircleOutlined } from '@ant-design/icons';
import { openNotification } from '../../../utils/utils';
import { get, post } from '../../../utils/api';
import { UserContext } from '../../../contexts/UserContext';
import { formatPrice } from '../../../utils/utils';
import { useNavigate } from 'react-router-dom';
const { Step } = Steps;
const { Title, Text } = Typography;
const { TextArea } = Input;


const MyselfForm = ({ currentStep, setCurrentStep,creatorData,setUserName,selectedOption,smsId }) => {
  const [form] = Form.useForm();
  const [formData, setFormData] = React.useState({});
  const [isOccasionModalVisible, setIsOccasionModalVisible] = useState(false);
  const [isInstructionModalVisible, setIsInstructionModalVisible] = useState(false);
  const [selectedOccasion, setSelectedOccasion] = useState('');
  const [selectedInstruction, setSelectedInstruction] = useState('');
  const [customOccasion, setCustomOccasion] = useState('');
  const [selectedDeliveryOption, setSelectedDeliveryOption] = useState('standard');
  const [predefinedOccasions, setPredefinedOccasions] = useState([]);
  const [isCustomRequest, setIsCustomRequest] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [totalFee, setTotalFee] = useState(0);
  const [requestFee, setRequestFee] = useState(0);
  const [platformFee, setPlatformFee] = useState(1);
  const [customRequestFee, setCustomRequestFee] = useState(0);
  const navigate = useNavigate();
  const {user}  = useContext(UserContext);
  
  useEffect(()=>{
    const init=async()=>{
      const response = await get(`user/requestPrivateInfo/${creatorData?.id}`);
      if (response && response.status) {
        const {private_request_list,is_custom_request,expedited}=response?.data;
        setPredefinedOccasions(private_request_list);
        setIsCustomRequest(is_custom_request);
        setCustomRequestFee(expedited);
        
    }
    }
    init();
  },[creatorData]);

  const feeCalCulator=(deliverytype=false)=>{
    let basefee=0;
    let optiontype=(deliverytype)?deliverytype:selectedDeliveryOption;
    
    if(selectedOccasion === 'Custom'){
      basefee=parseFloat(customRequestFee || 0);
    }
    else if(optiontype=="standard"){
      basefee=parseFloat(selectedItem.basePrice);
    }else{
      basefee=parseFloat(selectedItem.expedited);
    }
    setRequestFee(basefee);
    let totalFee=basefee+parseFloat(platformFee);
    setTotalFee(parseFloat(totalFee));
  }


  useEffect(()=>{
    if(selectedOption && selectedOption=="myself"){
      setCurrentStep(1);
    }
    
  },[selectedOption]);

  // const predefinedOccasions = [
  //   { label: 'None', value: 'None', price: 0 },
  //   { label: '{Defined request} $20', value: '$20', price: 20 },
  //   { label: '{Defined request} $30', value: '$30', price: 30 },
  //   { label: '{Defined request} $40', value: '$40', price: 40 },
  //   { label: '{Defined request} $15', value: '$15', price: 15 },
  //   { label: 'Custom', value: 'Custom', price: 0 },
  // ];

  // const deliveryOptions = [
  //   { label: 'Standard', text: '2-5 days', value: 'standard', price: 40 },
  //   { label: '24 hours or less', text: 'Get faster delivery', value: '24hours', price: 100 },
  // ];

  const steps = [
    {
      title: 'Step 1',
      content: (<>
        {/* <Form.Item
          name="to"
          label="To"
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
          rules={[{ required: true, message: 'Please enter a name' }]}
          className="custom-form-item"
          onChange={(e)=>{setUserName(e.target.value)}}
        >
          <Input placeholder="Enter Name" />
        </Form.Item> */}
        { selectedOption && selectedOption!="myself" &&  <Form.Item
          name="from_name"
          label="Friend Name"
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
          rules={[{ required: true, message: 'Please enter a name' }]}
          className="custom-form-item"
        >
          <Input placeholder="Name" />
        </Form.Item>}
      </>),
    },
    {
      title: 'Step 2',
      content: (<>
        <Divider style={{ marginTop: 0, marginBottom: 20 }} />
        <Flex vertical gap={25}>
          { selectedOccasion !== '' && selectedOccasion !== undefined ? (
            <Flex
                gap={10}
                horizontal="true"
                justify="space-between"
                align="start"
                className="selected-request-row"
                style={{
                  border: '1px solid #EAECF0',
                  padding: '10px 14px',
                  borderRadius: '8px',
                }}
              >
              <Flex vertical>
               
                <Text style={{ fontSize: '16px', color: '#475467', fontWeight: 600 }}>Occasion:</Text>
                <Text style={{ fontSize: '16px', color: '#475467' }}>{selectedOccasion === 'Custom' ? customOccasion :<Text>{selectedItem?.title} {formatPrice(selectedItem?.basePrice)}</Text>}</Text>
              </Flex>
              <Flex gap={15} style={{ marginTop: 5 }}>
                <EditOutlined style={{ fontSize: '20px' }} onClick={() => setIsOccasionModalVisible(true)} />
                <CloseCircleOutlined style={{ color: '#D92D20', fontSize: '20px' }} onClick={() => setSelectedOccasion('')} />
              </Flex>
            </Flex>
          ) : (
            <Button
              size="large"
              style={{ fontSize: '14px', fontWeight: 600, color: '#6941C6', borderColor: '#D6BBFB' }}
              icon={<PlusOutlined />}
              onClick={() => setIsOccasionModalVisible(true)}
            >
              Select an occasion
            </Button>
          )}
          {selectedInstruction ? (
            <Flex
              gap={10}
              horizontal="true"
              justify="space-between"
              align="start"
              className="selected-request-row"
              style={{
                border: '1px solid #EAECF0',
                padding: '10px 14px',
                borderRadius: '8px',
              }}
            > 
              <Flex vertical>
                <Text style={{ fontSize: '16px', color: '#475467', fontWeight: 600 }}>Instructions:</Text>
                <Text style={{ fontSize: '16px', color: '#475467' }}>{selectedInstruction}</Text>
              </Flex>
              <Flex gap={15} style={{ marginTop: 5 }}>
                <EditOutlined style={{ fontSize: '20px' }} onClick={() => setIsInstructionModalVisible(true)} />
                <CloseCircleOutlined style={{ color: '#D92D20', fontSize: '20px' }} onClick={() => setSelectedInstruction('')} />
              </Flex>
            </Flex>
          ) : (
            <Button
              size="large"
              style={{ fontSize: '14px', fontWeight: 600, color: '#6941C6', borderColor: '#D6BBFB' }}
              icon={<PlusOutlined />}
              onClick={() => setIsInstructionModalVisible(true)}
            >
              Add Instructions (required)
            </Button>
          )}
        </Flex>
      </>),
    },
    {
      title: 'Step 3',
      content: (<>
        <Flex vertical style={{ marginBottom: 20 }}>
          {/*<Text style={{ fontSize: '16px', fontWeight: 'bold' }}>{formData.to}</Text>*/}
          <Text style={{ display: 'block', marginBottom: 10 }}>{selectedInstruction}</Text>
          <Button
            type="default"
            icon={<EditOutlined />}
            onClick={() => setCurrentStep(1)}
            style={{ fontWeight: 600 }}
          >
            Edit request details
          </Button>
        </Flex>
        <Divider />
      {selectedOccasion!=="Custom" &&
        <Flex vertical style={{ marginBottom: 10 }}>
          <Text style={{ fontSize: '18px', fontWeight: 'bold', marginBottom: 10 }}>Select an option</Text>
          <Radio.Group
            className="occasion-checkbox options-checkbox"
            onChange={(e) => {setSelectedDeliveryOption(e.target.value);feeCalCulator(e.target.value);}}
            value={selectedDeliveryOption}
          >
            {selectedItem && selectedItem?.basePrice && <Radio key="optionPrice1" value="standard" style={{  }}>
              <Flex vertical>
                <Text style={{ fontSize: '16px', color: '#344054' }}>Standard
                  (<span> {formatPrice(selectedItem?.basePrice)}</span>)
                </Text>
                <Text style={{ fontSize: '16px', color: '#344054' }}>2-5 days</Text>
              </Flex>
              </Radio>
            }
             {selectedItem && selectedItem?.expedited && <Radio key="optionPrice2" value="express" style={{  }}>
              <Flex vertical>
                <Text style={{ fontSize: '16px', color: '#344054' }}>24 hours or less
                   (<span> {formatPrice(selectedItem?.expedited)}</span>)
                </Text>
                <Text style={{ fontSize: '16px', color: '#344054' }}>Get faster delivery</Text>
              </Flex>
              </Radio>
            }
          
          </Radio.Group>
        </Flex>}
        <Divider />
        <Flex vertical style={{ marginBottom: 20 }}>
          <Text style={{ fontSize: '18px', fontWeight: 'bold', color: '#344054', marginBottom: 10 }}>Summary</Text>
          <Flex horizontal="true" justify="space-between" style={{ fontWeight: 500, fontSize: '14px', color: '#344054' }}>
            <Text>Request:</Text>
              <Text>{formatPrice(requestFee)}</Text>
          </Flex>
          <Divider style={{ margin: '7px 0' }} />
          <Flex horizontal="true" justify="space-between" style={{ fontWeight: 500, fontSize: '14px', color: '#344054' }}>
            <Text>Booking fee:</Text> <Text>{formatPrice(platformFee)}</Text>
          </Flex>
          <Divider style={{ margin: '7px 0' }} />
          <Flex horizontal="true" justify="space-between" style={{ fontWeight: 600, color: '#344054' }}>
            <Text style={{ fontSize: '16px' }}>Total:</Text> <Text style={{ fontSize: '16px' }}>{formatPrice(totalFee)}</Text>
          </Flex>
        </Flex>
      </>),
    },
  ];

  const next = () => {
    if(currentStep==1 && selectedOccasion===''){
      message.error('Please select occasion');
      return  false; 
    }
    if(currentStep==1 && selectedInstruction===''){
      message.error('Please select instructions');
      return  false; 
    }
    form.validateFields()
      .then((currentStepData) => {
        setFormData({ ...formData, ...currentStepData });
        setCurrentStep((prevStep) => prevStep + 1);
      })
      .catch((info) => {
        console.log('Validation failed:', info);
      });
  };
  const handleApplyOccasion = () => {
    
    if(selectedOccasion===""){
      message.error('Please select occasion');
      return;
    }
    if (selectedOccasion === 'Custom' && customOccasion.trim() === '') {
      message.error('Please enter a custom occasion');
      return;
    }
    setIsOccasionModalVisible(false);
    feeCalCulator();
  };
  const handleOccasionModalCancel = () => {
    setIsOccasionModalVisible(false);
  };
  const handleOccasionChange = (e) => {
    const index=e.target.value;
    setSelectedOccasion(index);
    const selectedItem=predefinedOccasions[index];
    if(selectedItem){
      setSelectedItem(selectedItem);
    }
   
  };
  const handleCustomOccasionChange = (e) => {
    setCustomOccasion(e.target.value);
  };
  const handleInstructionModalCancel = () => {
    setIsInstructionModalVisible(false);
  };
  const handleInstructionChange = (e) => {
    setSelectedInstruction(e.target.value);
  };
  const handleApplyInstruction = () => {
    setIsInstructionModalVisible(false);
  };

  const handleSubmit = async (values) => {
      
    try{
      const sendData={...formData,selectedOccasion,selectedInstruction,selectedDeliveryOption,ctr:creatorData?.id,user};
      const response = await post(`user/sendPrivateRequest`,sendData);
      if(response.status){
        openNotification("Request submitted successfully, awaiting approval.");
        navigate(`/audience/myrequests`);
      }
    }catch(error){
      console.log('Form error',error);
    }finally{

    }
  };

  return (
    <Form form={form} onFinish={handleSubmit}>
      <div className="steps-content">
        {steps[currentStep].content}
      </div>
      <div className="steps-action" style={{ marginTop: '24px' }}>
        <Flex
            vertical
            gap="middle"
            style={{
              width: '100%',
            }}
          >
          {currentStep < steps.length - 1 && (
              <Button type="primary" onClick={() => next()} size="large" style={{ fontWeight: 600 }}>
                Next
              </Button>
          )}
          {currentStep === steps.length - 1 && (
              <Button type="primary" htmlType="submit" size="large" style={{ fontWeight: 600 }}>
                Book
              </Button>
          )}
        </Flex>
      </div>

      {isOccasionModalVisible && (
        <Modal
          open={isOccasionModalVisible}
          onCancel={handleOccasionModalCancel}
          footer={null}
          width={560}
          className="occasion-modal"
        >
        <Flex vertical style={{ padding: 20 }}>
          <Title level={4} style={{ fontSize: '18px', marginTop: 0, marginBottom: 25, textAlign: 'center' }}>Select an Occasion</Title>
          <Radio.Group className="occasion-checkbox" onChange={handleOccasionChange} value={selectedOccasion}>
            {predefinedOccasions.map((occasion,index) => (
              <Radio style={{ width: '100%', margin: '5px 0', fontSize: '16px', color: '#344054' }} key={index} value={index}>{occasion?.title} ${occasion?.basePrice}</Radio>
            ))}
            {isCustomRequest &&   <Radio style={{ width: '100%', margin: '5px 0', fontSize: '16px', color: '#344054' }} key="custom_request" value="Custom">Custom</Radio>}
          </Radio.Group>
          {selectedOccasion === 'Custom' && (
            <Input
              placeholder="Enter custom occasion"
              value={customOccasion}
              onChange={handleCustomOccasionChange}
              style={{ marginTop: '15px' }}
            />
          )}
        </Flex>
        <Flex gap="small" vertical style={{ padding: '0px 20px 20px 20px' }}>
          <Button size="large" type="primary" onClick={handleApplyOccasion} style={{ fontWeight: 600 }}>Save</Button>
        </Flex>
        </Modal>
      )}

      {isInstructionModalVisible && (
        <Modal
          open={isInstructionModalVisible}
          onCancel={handleInstructionModalCancel}
          footer={null}
          width={560}
          className="occasion-modal"
        >
        <Flex vertical style={{ padding: 20 }}>
          <Title level={4} style={{ fontSize: '18px', marginTop: 0, marginBottom: 25, textAlign: 'center' }}>Add Instructions</Title>
          <Form.Item 
            label="Instructions"
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24 }}
            className="custom-form-input" 
            rules={[{ required: true, message: 'Please enter a name' }]}
            style={{ marginBottom: 20 }}>
            <TextArea
              showCount
              maxLength={230}
              onChange={handleInstructionChange}
              placeholder="Instructions added here, this can be up to 300 characters long"
              style={{
                height: 128,
                resize: 'none',
              }}
            />
          </Form.Item>

        </Flex>
        <Flex gap="small" vertical style={{ padding: '0px 20px 20px 20px' }}>
          <Button size="large" type="primary" onClick={handleApplyInstruction} style={{ fontWeight: 600 }}>Save</Button>
        </Flex>
        </Modal>
      )}

    </Form>
  );
};

export default MyselfForm;
