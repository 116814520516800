import React, { useState, useMemo, useEffect } from 'react';
import { Layout, Calendar, Spin, Button, Typography, List, Input, Checkbox, notification, Drawer, Segmented, Space, Flex, Divider, Select, Dropdown, Image,Popconfirm,message,Modal ,Tag,Tooltip} from 'antd';
import { ArrowLeftOutlined, ArrowRightOutlined, SearchOutlined, CloseOutlined, PlusOutlined, EditOutlined, DeleteOutlined,EditFilled } from '@ant-design/icons';
import moment from 'moment';
import momenttmz from 'moment-timezone';
import dayjs from 'dayjs';
import NewMessage from '../../components/GlobalComponents/NewMessage';
import { post,get } from '../../utils/api';
import { openNotification } from '../../utils/utils';
import './calendar.css';
import EllipsisIcon from "../../images/ellipsis-icon.svg";
import useScreenSize from '../../utils/useScreenSize';
import LockIcon from "../../images/lock-icon2.svg";
import DeleteIcon from '../../images/delete-icon-bg.svg';
import CheckCircle from '../../images/check-with-circles.svg';
import PrintMessage from '../../components/GlobalComponents/PrintMessage';

const { Title, Text } = Typography;
const { Content, Sider } = Layout;
const { Option } = Select;
const { confirm } = Modal;

const ScheduleMessage = () => {
  const [view, setView] = useState('Month');
  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedPost, setSelectedPost] = useState(null);
  const [isDrawerVisible, setIsDrawerVisible] = useState(false);
  const [searchCommunity, setSearchCommunity] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [openSendMessage, setSendMessage] = useState(false);
  const [currentMonth, setCurrentMonth] = useState(dayjs());
  const [posts, setPosts] = useState({});
  const [postsList, setPostsList] = useState([]);
  const { isMobile, isTableToDesktop } = useScreenSize();
  const [open, setOpen] = useState(false);

  const [scheduleMessage, setScheduleMessage] = useState(null);
  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);

  const convertUtcToTimeZone=(utcTime, timeZone) =>{
    console.log("fff===>",utcTime, timeZone);
    // Parse the UTC time into a moment object
    const utcMoment = momenttmz.utc(utcTime);
  
    // Convert the UTC time to the desired time zone
    const convertedTime = utcMoment.tz(timeZone).format('YYYY-MM-DD HH:mm:ss');
  
    return convertedTime;
  }

  const [filters, setFilters] = useState({
    scheduled: true,
    sent: true,
  });

  useEffect(()=>{
    fetchPosts();
  },[filters]);

  const fetchPosts = async () => {
    const finaldata = {};
    finaldata.filters=filters;
    
    setIsLoading(true);
    try {
      const mockPosts = {};
      const response = await post('creator/getAllscheduleMessage/', finaldata);
      if (response.status) {
        response.data.forEach((item) => {
          const { schedule_date, message, schedule_time,timezone } = item;
          const content = message;
          const formattedDate = moment(schedule_time, 'MM/DD/YYYY h:mm A').format('YYYY-MM-DD');
         
          const convertedDate = moment.utc(schedule_time).tz(timezone).format('h:mm A');
          if (!mockPosts[formattedDate]) {
            mockPosts[formattedDate] = [];
          }
          mockPosts[formattedDate].push({
            ...item,
            content,
            schedule_time:convertedDate,
          });
        });
        setPosts(mockPosts);
      }
    } catch (error) {
      console.log("error", error);
    } finally {
      setIsLoading(false);
    }
  }

  const formatDate = (date) => {
    const today = moment().startOf('day');
    const yesterday = moment().subtract(1, 'days').startOf('day');
    const messageDate = moment(date).startOf('day');

    if (messageDate.isSame(today)) return 'Today';
    if (messageDate.isSame(yesterday)) return 'Yesterday';
    return messageDate.format('MM/DD/yyyy');
  };

  const fetchListPosts = async (searchCommunity=null) => {
    const finaldata = {q:searchCommunity};
    setIsLoading(true);
    try {
      const mockPosts = {};
      const response = await post('creator/getListscheduleMessage/', finaldata);
      if (response.status) {
        
        setPostsList(response?.data);
      }
    } catch (error) {
      console.log("error", error);
    } finally {
      setIsLoading(false);
    }
  }

  useEffect(()=>{
   const runSearch=async()=>{
    await fetchListPosts(searchCommunity);
   }
   runSearch();
   setScheduleMessage(null);
  },[searchCommunity]);

  useEffect(() => {
    const loadDates = async () => {
      await fetchPosts();
    }
    loadDates();
    
  }, []);

  const handleDateSelect = (value) => {
    setSelectedDate(value);
    setIsDrawerVisible(true); 
  };

  const handleSendMessage = () => {
    setSendMessage(true);
    setOpen(true);
  };

  const handleCloseMessage = async() => {
    setSendMessage(false);
    await fetchPosts();
  };

  const handleClose = () => {
    setSelectedDate(null);
    setIsDrawerVisible(false);
  };

  const onClose = async() => {
    setSendMessage(false);
    await fetchPosts();
    setOpen(false);
  };

  const dateCellRender = (value) => {
    const dateStr = value.format('YYYY-MM-DD');
    if (!posts[dateStr]) return null;
    const dayPosts = posts[dateStr];
    const displayPosts = dayPosts.slice(0, 3);
    const additionalPostsCount = Math.max(0, dayPosts.length - 3);
    return (
      <div className='mainantd-calendarwrapper'>
        <div className="ant-calendar-cell-content">
          {displayPosts.map((post) => (
            <Tag 
              key={post.id}
              className={`mb-1 w-full ${post.is_sent ? 'cstmgreen' : 'cstmwarning'}`}
            >
              <Tooltip title={post.message}>
                <Typography.Text
                  ellipsis={{ tooltip: false }}
                  style={{ maxWidth: '100%' }}
                >
                  {post.name}
                </Typography.Text>
              </Tooltip>
            </Tag>
          ))}
        </div>
        {additionalPostsCount > 0 && (
          <Tag className='more_style'>
            <Typography.Text style={{ fontWeight: 500, fontSize: 12 }}>+{additionalPostsCount} More</Typography.Text>
          </Tag>
        )}
      </div>
    );
  };

  const showDeleteConfirm = () => {
    confirm({
      title: 'Delete Scheduled Message?',
      icon: <DeleteOutlined />,
      content: 'Are you sure you want to delete the scheduled message? This action cannot be undone.',
      okText: 'Delete',
      okType: 'danger',
      cancelText: 'Cancel',
      onOk:deleteconfirm,
      onCancel() {
        console.log('Cancel');
      },
    });
  };

  const deleteconfirm = async() => {
    setIsLoading(true);
        try{
          const response = await get(`creator/deleteScheduled/${selectedPost?.id}`);
            if(response && response.status){
              openNotification('Successfully deleted.');
              await fetchPosts();
            }
        }catch(error){
          console.log(error);
        }finally{
          setIsLoading(false);
        }
  };
  const cancel=()=>{
    console.log("cancel");
  }
  const editPost=async()=>{

  }

  const editpostitems = (
    <Flex vertical align="start" style={{ backgroundColor: '#ffffff', border: '1px solid #EAECF0', borderRadius: '8px', padding: 0 }}>     
        <Button onClick={showDeleteConfirm}  block type="text" size="large" style={{ textAlign: 'left', color: '#344054' }} icon={<DeleteOutlined style={{ color: '#D92D20' }} />}>Delete</Button>
    </Flex>
  );

  const disabledDate = (current) => {
    const today = dayjs().startOf('day');
    const dateStr = current.format('YYYY-MM-DD');
    const hasPosts = !!posts[dateStr];
    return current.isBefore(today) && !hasPosts;
  };

  const handleMonthChange = (isNext) => {
    const newMonth = isNext ? currentMonth.add(1, 'month') : currentMonth.subtract(1, 'month');
    setCurrentMonth(newMonth);
    setSelectedDate(null);
  };

   const handleTodayClick = () => {
    const today = dayjs();
    setSelectedDate(today);
    setCurrentMonth(today);
  };

  const handleCheckboxChange = (checked, key) => {
    setFilters((prev) => ({ ...prev, [key]: checked }));
  };

  // const handleMonthSelect = (value) => {
  //   const newMonth = currentMonth.month(value);
  //   setCurrentMonth(newMonth);
  //   setSelectedDate(null);
  // };

  const selectedFanStyles = useMemo(() => 
    isMobile ? { 
      position: 'absolute',
      right: 0,
      background: '#ffffff', 
      padding: '15px', 
      borderLeft: '1px solid #e3e3e3',
      height: '100%',
      overflow: 'auto', 
      scrollbarWidth: 'thin', 
      scrollbarColor: 'unset', 
      zIndex: 2,
    } : isTableToDesktop ? { 
      position: 'absolute',
      right: 0,
      background: '#ffffff', 
      padding: '20px', 
      borderLeft: '1px solid #e3e3e3',
      height: '100%',
      overflow: 'auto', 
      scrollbarWidth: 'thin', 
      scrollbarColor: 'unset', 
      zIndex: 2,
    } : { 
      background: '#ffffff', 
      padding: '0px', 
      borderLeft: '1px solid #e3e3e3'
    }
  , [isMobile, isTableToDesktop]);

  const selectedScheduledStyles = useMemo(() => 
    isMobile ? {
      // backgroundColor: '#ffffff', 
      // position: 'absolute', 
      // width: '100%', 
      // left: 0,
      // height: 'calc(100vh - 70px)',
      // display: 'flex', 
      // flexDirection: 'column' 
    } : { 
      // height: '100vh', 
      display: 'flex', 
      flexDirection: 'column' 
    }
  , [isMobile]);

  const months = dayjs.months();

  const handleSelectMessage = (schedule) => {
    setScheduleMessage(schedule);
  };

  const showDeleteModal = () => {
    setIsDeleteModalVisible(true);
  };

  const handleDeleteCancel = () => {
    setIsDeleteModalVisible(false);
  };

  const openNotification = (message) => {
    notification.success({
      message: message,
      placement: 'topRight',
      duration: 3,
      icon: <Image src={CheckCircle} preview={false} /> 
    });
  };

  const handleDeleteConfirm = async() => {
    setIsDeleteModalVisible(false);
    setIsLoading(true);
        try{
          const response = await get(`creator/deleteScheduled/${scheduleMessage?.id}`);
            if(response && response.status){
              setScheduleMessage(null);
              openNotification("Campaign successfully deleted");
              await fetchListPosts();
            }
        }catch(error){
          console.log(error);
        }finally{
          setIsLoading(false);
        }
  }
 
  // const messagesData = [
  //   {
  //     id: 1,
  //     name: 'Campaign Title 1',
  //     message: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit',
  //     date: "2024-09-25T14:30:00Z",

  //   },
  //   {
  //     id: 2,
  //     name: 'Campaign Title 2',
  //     message: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit',
  //     date: "2024-09-25T14:30:00Z",
  //   },
  //   {
  //     id: 3,
  //     name: 'Campaign Title 3',
  //     message: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit',
  //     date: "2024-09-25T14:30:00Z",
  //   },
  //   {
  //     id: 4,
  //     name: 'Campaign Title 4',
  //     message: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit',
  //     date: "2024-09-25T14:30:00Z",
  //   },
  //   {
  //     id: 5,
  //     name: 'Campaign Title 5',
  //     message: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit',
  //     date: "2024-09-25T14:30:00Z",
  //   },
  //   {
  //     id: 6,
  //     name: 'Campaign Title 6',
  //     message: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit',
  //     date: "2024-09-25T14:30:00Z",
  //   },
  //   {
  //     id: 7,
  //     name: 'Campaign Title 7',
  //     message: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit',
  //     date: "2024-09-25T14:30:00Z",
  //   },
  //   {
  //     id: 8,
  //     name: 'Campaign Title 8',
  //     message: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit',
  //     date: "2024-09-25T14:30:00Z",
  //   },
  //   {
  //     id: 9,
  //     name: 'Campaign Title 9',
  //     message: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit',
  //     date: "2024-09-25T14:30:00Z",
  //   },
  //   {
  //     id: 10,
  //     name: 'Campaign Title 10',
  //     message: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit',
  //     date: "2024-09-25T14:30:00Z",
  //   },
  // ];

  return (
    <Layout style={{ backgroundColor: '#ffffff', minHeight: '100vh', position: 'relative' }}>
    {openSendMessage != true &&<>
      <Spin spinning={isLoading}>
        <Content>

          <Flex vertical style={{ padding: '20px 20px 0px 20px' }}>
            <Flex horizontal justify="space-between" align="center" style={{ marginBottom: 20 }}>
              <Title style={{ marginTop: 0, marginBottom: 0 }} level={2}>Scheduled</Title>
              <Space style={{ alignItems: 'center' }}>
                <Segmented
                  options={['Month', 'List']}
                  value={view}
                  onChange={(value) =>{ 
                    setView(value);
                    fetchListPosts();
                  }}
                  style={{ fontWeight: 600, verticalAlign: 'middle' }}
                  className="nav-tab-group"
                />
                <Button type="primary" size="large" icon={<PlusOutlined />} onClick={handleSendMessage} style={{ fontWeight: 600 }}>
                  New
                </Button>
              </Space>
            </Flex>

            {view == 'Month' &&
              <Space className="calendar-main" style={{ marginBottom: 20, display: 'flex', flexDirection: isMobile && 'column', justifyContent: 'space-between' }}>
                <Space size="small" className="calendar-head" style={ isMobile && { justifyContent: 'space-between', width: '100%' }}>
                  <Button style={{ fontWeight: 600 }} onClick={handleTodayClick}>Today</Button>
                  <Button type="text" icon={<ArrowLeftOutlined />} onClick={() => handleMonthChange(false)} />
                  <Button type="text" icon={<ArrowRightOutlined />} onClick={() => handleMonthChange(true)} />
                  <Title level={4}>{currentMonth.format('MMMM YYYY')}</Title>
                </Space>

                <Space className="calendar-select-main" style={ isMobile && { justifyContent: 'space-between', width: '100%', marginTop: 10 }}>
                  <Checkbox
                    checked={filters.scheduled}
                    style={{ border: '1px solid #D0D5DD', borderRadius: 6, padding: '4px 2px 4px 7px', fontWeight: 500 }}
                    onChange={(e) => handleCheckboxChange(e.target.checked, 'scheduled')}
                  >
                    <Space>
                      <span style={{ color: '#FFA500', fontSize: '10px', fontWeight: 'bold' }}>●</span>
                      Scheduled
                    </Space>
                  </Checkbox>
                  <Checkbox
                    checked={filters.sent}
                    style={{ border: '1px solid #D0D5DD', borderRadius: 6, padding: '4px 2px 4px 7px', fontWeight: 500 }}
                    onChange={(e) => handleCheckboxChange(e.target.checked, 'sent')}
                  >
                    <Space>
                      <span style={{ color: '#4CAF50', fontSize: '10px', fontWeight: 'bold' }}>●</span>
                      Sent
                    </Space>
                  </Checkbox>
                  {/*<Select
                    value={currentMonth.month()}
                    onChange={handleMonthSelect}
                    className="select-month-dropdown"
                    style={{ width: '100%' }}
                  >
                    {months.map((month, index) => (
                      <Option key={index} value={index}>
                        {month}
                      </Option>
                    ))}
                  </Select>*/}
                
                </Space>

              </Space>
            }
          </Flex>

          {view == 'Month' &&
            <Calendar
              fullscreen={true}
              value={selectedDate || currentMonth} // Use selectedDate if available, otherwise fallback to currentMonth
              onSelect={handleDateSelect}
              cellRender={dateCellRender}
              disabledDate={disabledDate} // Disable past dates without posts
              className="calendar-admin"
              headerRender={() => null}
            />
          }

          {view == 'List' && 
            <Flex horizontal style={{ borderTop: '1px solid #D0D5DD', height: 'calc(100vh - 87px)' }}>
              <Flex vertical style={{ width: 360, minWidth: 360, borderRight: '1px solid #EAECF0' }}>
                <div style={{ padding: 15, borderBottom: '1px solid #EAECF0' }}>
                    <Input
                      placeholder="Search scheduled messages"
                      onChange={e => setSearchCommunity(e.target.value)}
                      prefix={<SearchOutlined />}
                      className="search-scheduled"
                      style={{ marginBottom: 0 }}
                    />
                  </div>

                  <List
                    itemLayout="horizontal"
                    dataSource={postsList}
                    className="schedule-data"
                    style={{ flex: 1, overflowY: 'auto', scrollbarWidth: 'thin', scrollbarolCor: 'unset' }}
                    renderItem={(item) =>{
                      return (<List.Item onClick={() => handleSelectMessage(item)} style={{ cursor: 'pointer', background: item.id === scheduleMessage?.id ? '#F9FAFB' : 'transparent', padding: '20px 20px' }}>
                        <List.Item.Meta
                          title={<Text style={{ fontSize: 13, color: '#344054', borderRadius: '8px', border: '1px solid #D0D5DD', padding: '6px 10px' }}>{formatDate(item.schedule_time)}</Text>}
                          description={
                            <Flex vertical style={{ marginTop: 15 }}>
                               <Text style={{ fontSize: 16, color: '#101828' }}>{item.name}</Text>
                              <Text>{item.message}</Text>
                              <Text style={{ fontSize: 12, color: '#101828' }}>Will be sent on {moment(item?.schedule_time).format('MMMM DD, YYYY hh:mm A')}</Text>
                            </Flex>
                          }
                        />
                      </List.Item>);
                    }}
                  />
              </Flex>

              {scheduleMessage &&
                <Content style={selectedScheduledStyles}>
                  <Flex horizontal justify="space-between" style={{ borderBottom: '1px solid #D0D5DD', padding: 20 }}>
                    <Flex vertical>
                      <Title level={4} style={{ marginBottom: 2, marginTop: 0, fontSize: '18px', color: '#101828' }}>
                        {scheduleMessage.name}
                      </Title>         
                      <Text>{scheduleMessage?.userCount} Recipients</Text>         
                    </Flex>
                    <Flex gap={10} align="center">
                      
                      <Button type="text" onClick={showDeleteModal} style={{ padding: 0, background: 'transparent' }}><DeleteOutlined style={{ fontSize: 20, color: '#B42318' }} /></Button>
                    </Flex>
                  </Flex>
                  <Flex vertical style={{ flex: 1, overflowY: 'auto', scrollbarWidth: 'thin', scrollbarolCor: 'unset', background: '#F9FAFB', padding: '12px 25px' }}>
                    <Text style={{ fontSize: 12, textAlign: 'center', marginBottom: 10 }}>Will be sent on {moment(scheduleMessage?.schedule_time).format('MMMM DD, YYYY hh:mm A')}</Text>
                  <Flex className="message-response" justify="flex-end">  
                    <PrintMessage message={scheduleMessage?.message} type={scheduleMessage?.type}/>
                  </Flex>
                    {/* <Flex gap={8} vertical className="message-response" align="end">
                      <Flex vertical style={{ maxWidth: '230px', width: '100%', textAlign: 'center', marginLeft: 'auto' }}>
                        <Flex vertical justify="center" align="center" style={{ backgroundColor: '#4D5159', padding: 25, borderRadius: '12px 12px 0px 0px', width: '100%', minHeight: '236px' }}>
                         <Image
                            width={48}
                            src={LockIcon}
                            preview={false}
                            style={{ marginBottom: 0, background: '#ffffff', borderRadius: '50%', padding: 10 }}
                          />
                        </Flex>
                        <Flex vertical justify="center" align="start" style={{ backgroundColor: '#E5E5EA', padding: '8px 15px', borderRadius: '0px 0px 12px 12px', width: '100%' }}>
                          <Text style={{ color: '#101828', fontSize: '16px' }}>See content to unlock</Text>
                          <Text style={{ fontSize: '12px' }}>blonde.social</Text>
                        </Flex>
                      </Flex>

                      <Text className="chat-bubble chat_me" style={{ fontSize: '17px', backgroundColor: '#7F56D9', padding: '6px 14px', color: '#ffffff', borderRadius: 18, maxWidth: '75%' }}>Lorem ipsum dolor sit amet consectetur adipiscing elit, adipiscing elitsed Lorem ipsum dolor sit amet consectetur adipiscing elit, adipiscing elitsed incidi...</Text>

                      </Flex> */}
                    </Flex>
                </Content>
              }

            </Flex>
          }
          
        </Content>
      </Spin>

       {selectedDate && 
        <Drawer className="schedule-sidebar" width={ isMobile ? 250 : isTableToDesktop ? 300 : 375} style={selectedFanStyles} open={isDrawerVisible}>
          <Flex vertical gap="middle">
            <Button
              type="link"
              icon={<CloseOutlined style={{ color: '#101828' }}/>}
              onClick={handleClose}
              style={{ position: 'absolute', paddingTop: 0, marginBottom: '20px', alignSelf: 'flex-end' }}
            >
            </Button>
            <Title style={{ marginTop: 0 }} level={ isMobile ? 5 : 4}>{selectedDate.format('MMMM DD, YYYY')}</Title>
            <Divider style={{ margin: 0 }} />
            <Flex vertical gap={20} style={{ padding: '10px 0' }}>
              {posts[selectedDate.format('YYYY-MM-DD')] ? (
                posts[selectedDate.format('YYYY-MM-DD')].map((post, index) => (
                  <div className="schedule-post" key={index}>
                    <div style={{ alignItems: 'center' }} className={`mb-1 w-full ${post.is_sent ? 'cstmgreen' : 'cstmwarning'}`}>
                      <span dangerouslySetInnerHTML={{ __html: post.content }} style={{ whiteSpace: 'pre-wrap' }} />
                      {post?.schedule_time &&
                        <span> - {post?.schedule_time}</span>
                      }
                    </div>
                  </div>
                ))
              ) : (
                <p>No posts for this date</p>
              )}
            </Flex>
          </Flex>
        </Drawer>
      }
    </>}

    {openSendMessage &&
      <NewMessage visible={openSendMessage} handleCancel={handleCloseMessage} onClose={onClose} open={open} isScheduled={true} />
    }

    <Modal
      open={isDeleteModalVisible}
      onCancel={handleDeleteCancel}
      footer={null}
      width={400}
      className="delete-request-modal"
    >
      <Flex vertical style={{ textAlign: 'center', alignItems: 'center', paddingTop: 10 }}>
        <Image 
          src={DeleteIcon}
          preview={false}
          width={48}
          style={{ marginBottom: 15 }}
        />
        <Title level={4} style={{ fontSize: '18px', marginTop: 0, marginBottom: 5 }}>Delete Scheduled Message?</Title>
        <Text style={{ marginBottom: 15 }}>Are you sure you want to delete the scheduled message? This action cannot be undone.</Text>
      </Flex>
      <Flex gap={5} className="ant-modal-footer">
        <Button size="large" onClick={handleDeleteCancel} style={{ fontWeight: 600 }}>Cancel</Button>
        <Button size="large" type="primary" danger onClick={handleDeleteConfirm} style={{ fontWeight: 600 }}>Yes, Delete</Button>
      </Flex>
    </Modal>

    </Layout>
  );
};

export default ScheduleMessage;
