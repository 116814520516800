import React,{useEffect,useState} from 'react';
import { Button, Card, Flex, Input, Spin, Divider, Typography } from 'antd';
import { get } from '../utils/api';
import { 
  MessageOutlined, 
  PhoneOutlined, 
  VideoCameraOutlined, 
  MailOutlined, 
  DollarOutlined, 
  CheckCircleFilled,
  ShareAltOutlined,
  HeartOutlined
} from '@ant-design/icons';

import { useParams,useNavigate } from 'react-router-dom';
import AvatarImage from '../components/AvatarImage';

const { Title, Text } = Typography;

const ContactCard=()=>{
    const [userPhone,setUserPhone]=useState(null);
    const [firstName,setFirstName]=useState(null);
    const [lastName,setLastName]=useState(null);
    const [imageId,setImageId]=useState(null);
    const [isLoading,setIsLoading]=useState(false);
    const { userName } = useParams();
    const navigate = useNavigate();
    useEffect(()=>{
        const init=async()=>{
            setIsLoading(true);
            try{
        const response=await get(`/auth/contact-info/${userName}`);
            if(response && response?.status){
                    const {first_name,last_name,assign_number,uploadedIds}=response.data || {};
                    const imageId = uploadedIds && uploadedIds.length > 0 ? uploadedIds[0] : null;
                    setImageId(imageId);
                    setUserPhone(assign_number);
                    setFirstName(first_name);
                    setLastName(last_name);
                    window.location.href=`sms://+${assign_number}/`;
                    
                }else{
                    navigate('/404');
                }
            }catch(error){
                    console.log("error",error);
            }finally{
                setIsLoading(false);
            }
        }
        init();
        
        
    },[userName]);
    
    return (
      <Flex vertical style={{ background: '#F2F1F6', height: '100vh'}}>
        <Spin spinning={isLoading}>
        <a href={`sms://+1${userPhone}`}>
        <div style={{ maxWidth: 500, margin: '0 auto', padding: '16px' }}>
          <div style={{ textAlign: 'center', marginBottom: 24 }}>
          
            <AvatarImage imageId={imageId} size={96} title={`${firstName} ${lastName}`}/>
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: 8 }}>
              <Title level={2} style={{ margin: 0 }}>{firstName} {lastName}</Title>
              <CheckCircleFilled style={{ color: '#1890ff', fontSize: 20 }} />
            </div>
          </div>
    
          {/* Action Buttons */}
          <div style={{ 
            display: 'grid', 
            gridTemplateColumns: 'repeat(5, 1fr)', 
            gap: 8,
            marginBottom: 24 
          }}>
            <Button type="text" style={{ height: 'auto', padding: 8, background: '#ffffff', borderRadius: 10 }}>
              <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <MessageOutlined style={{ fontSize: 24, color: '#1890ff' }} />
                <Text style={{ color: '#1890ff', marginTop: 4 }}>message</Text>
              </div>
            </Button>
            <Button type="text" style={{ height: 'auto', padding: 8, background: '#ffffff', borderRadius: 10 }}>
              <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <PhoneOutlined style={{ fontSize: 24, color: '#1890ff' }} />
                <Text style={{ color: '#1890ff', marginTop: 4 }}>call</Text>
              </div>
            </Button>
            <Button type="text" style={{ height: 'auto', padding: 8, background: '#ffffff', borderRadius: 10 }}>
              <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <VideoCameraOutlined style={{ fontSize: 24, color: '#8c8c8c' }} />
                <Text style={{ color: '#8c8c8c', marginTop: 4 }}>video</Text>
              </div>
            </Button>
            <Button type="text" style={{ height: 'auto', padding: 8, background: '#ffffff', borderRadius: 10 }}>
              <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <MailOutlined style={{ fontSize: 24, color: '#8c8c8c' }} />
                <Text style={{ color: '#8c8c8c', marginTop: 4 }}>mail</Text>
              </div>
            </Button>
            <Button type="text" style={{ height: 'auto', padding: 8, background: '#ffffff', borderRadius: 10 }}>
              <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <DollarOutlined style={{ fontSize: 24, color: '#8c8c8c' }} />
                <Text style={{ color: '#8c8c8c', marginTop: 4 }}>pay</Text>
              </div>
            </Button>
          </div>
    
          {/* Contact Details */}
          <Card className="contact-card-info" bordered={false} style={{ marginBottom: 16, borderRadius: 10 }}>
            <div>
              <Text type="primary" style={{ color: '#000000' }}>mobile</Text>
              <div>
                <Text style={{ color: '#1890ff', fontSize: 16 }}>+1 {userPhone}</Text>
              </div>
            </div>
          </Card>

          <Card className="contact-card-info" bordered={false} style={{ marginBottom: 16, borderRadius: 10 }}>
            <div>
              <Text type="primary" style={{ color: '#000000' }}>Message</Text>
              <Input disabled style={{ background: '#f5f5f5' }} />
            </div>
          </Card>

          <Card className="contact-card-info" bordered={false} style={{ marginBottom: 16, borderRadius: 10 }}>
            <div>
              <Text type="primary" style={{ color: '#000000' }}>Notes</Text>
              <Input disabled style={{ background: '#f5f5f5' }} />
            </div>
          </Card>
    
          {/* Action Links */}
          <Flex vertical style={{ background: '#ffffff', borderRadius: 10, padding: 15 }}>
            <Button 
              type="text" 
              style={{ 
                justifyContent: 'flex-start',
                color: '#1890ff',
                height: 'auto',
                padding: '0',
                background: 'transparent',
                fontSize: '16px'
              }}
            >
              Send Message
            </Button>
            <Divider style={{ margin: '8px 0' }} />
            <Button 
              type="text" 
              style={{ 
                justifyContent: 'flex-start',
                color: '#1890ff',
                height: 'auto',
                padding: '0',
                background: 'transparent',
                fontSize: '16px'
              }}
            >
              Share Contact
            </Button>
            <Divider style={{ margin: '8px 0' }} />
            <Button 
              type="text" 
              style={{ 
                justifyContent: 'flex-start',
                color: '#1890ff',
                height: 'auto',
                padding: '0',
                background: 'transparent',
                fontSize: '16px'
              }}
            >
              Add to Favorites
            </Button>
          </Flex>
        </div>
        </a>
        </Spin>
      </Flex>
    );
}

export default ContactCard;