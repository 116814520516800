import React from 'react';
import { Layout, Col, Row, Typography } from 'antd';

const { Title, Paragraph } = Typography;
const { Content } = Layout;

const ContentGuidelines = () => {

  return (
    <Content style={{ padding: '0 30px' }}>
      <Row style={{ justifyContent: 'center' }}>
      <Col 
        xs={{
          span: 24,
        }}
        lg={{
          span: 16,
        }}
      >
      <Title level={2} style={{ marginBottom: 20, marginTop: 0 }}>Content Guidelines</Title>
      <Paragraph>We strive to create a positive, respectful, and safe environment for all users. Our Content Guidelines ("Guidelines") are designed to outline the types of content that are acceptable on our platform, as well as content that we do not permit. By using our Service, you agree to abide by these Guidelines. Violating these Guidelines may result in content removal, account suspension, or termination.
      </Paragraph>

      <Title level={4}>1. Respectful and Inclusive Content</Title>
      <Paragraph>We encourage users to share content that is constructive, respectful, and inclusive. Content should not promote or incite hatred, discrimination, or violence against individuals or groups based on race, ethnicity, nationality, religion, gender, sexual orientation, disability, or any other protected characteristic.</Paragraph>

      <Title level={4}>2. Prohibited Content</Title>
      <Paragraph>The following types of content are strictly prohibited on our platform:</Paragraph>
      <Paragraph>
        <ul>
          <li>Harassment and Bullying: Content that harasses, threatens, or bullies others, including any form of personal attack or targeting.</li>
          <li>Hate Speech and Discrimination: Content that promotes or glorifies hate speech, discrimination, or any form of violence against individuals or groups.</li>
          <li>Violence and Threats: Content that promotes, glorifies, or threatens violence, harm, or illegal activities.</li>
          <li>Sexually Explicit Material: Any sexually explicit or adult content, including pornography or graphic sexual descriptions.</li>
          <li>Illegal Activities: Content promoting illegal activities, such as drug use, fraud, or piracy.</li>
          <li>Misinformation: Content that spreads false information or promotes conspiracy theories with the intent to mislead.</li>
        </ul>
      </Paragraph>

      <Title level={4}>3. Intellectual Property</Title>
      <Paragraph>All users must respect copyright and intellectual property rights. Do not upload or share content that you do not own or have permission to use. This includes copyrighted music, videos, photos, and other proprietary material.</Paragraph>
      

      <Title level={4}>4. Privacy Violations</Title>
      <Paragraph>Respect the privacy of others. Do not share personal information, such as addresses, phone numbers, or private photos or videos of others without their consent. Unauthorized sharing of private information is prohibited and may result in immediate account suspension.</Paragraph>

      <Title level={4}>5. Spam and Misleading Content</Title>
      <Paragraph>We do not allow spam or deceptive content, including:</Paragraph>
      <Paragraph>
        <ul>
          <li>Unsolicited Promotions: Excessive or irrelevant promotional content that disrupts user experience.</li>
          <li>Clickbait: Misleading titles or descriptions designed to trick users into engaging with content.</li>
          <li>Impersonation: Content that impersonates or misrepresents another individual or organization.</li>
        </ul>
      </Paragraph>

      <Title level={4}>6. Quality and Relevance</Title>
      <Paragraph>We encourage users to post content that is meaningful and relevant to the platform's community. Avoid posting low-quality, repetitive, or irrelevant content that detracts from the community experience.</Paragraph>

      <Title level={4}>7. Reporting Violations</Title>
      <Paragraph>If you encounter content that violates these Guidelines, please report it to us. We review all reports and take appropriate action based on these Guidelines.</Paragraph>

      <Title level={4}>8. Consequences of Violations</Title>
      <Paragraph>Failure to comply with these Guidelines may result in:</Paragraph>
      <Paragraph>
        <ul>
          <li>Content removal</li>
          <li>Temporary suspension of account access</li>
          <li>Permanent account termination for severe or repeated violations</li>
        </ul>
      </Paragraph>

      <Title level={4}>9. Changes to These Guidelines</Title>
      <Paragraph>We reserve the right to update or modify these Guidelines at any time. Any changes will be effective immediately upon posting, and your continued use of the Service constitutes acceptance of any changes.</Paragraph>

      <Title level={4}>10. Contact Us</Title>
      <Paragraph>If you have any questions about these Guidelines, please contact us at [Contact Information].</Paragraph>

      </Col>
      </Row>
    </Content>
  );
};

export default ContentGuidelines;
