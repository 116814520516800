import React, { useState, useEffect, useContext } from 'react';
import { Layout, Typography, Breadcrumb, Button, Progress, Flex, Radio, Divider } from 'antd';
import { RightOutlined, BarChartOutlined, ArrowLeftOutlined } from '@ant-design/icons';
import { UserContext } from '../../../contexts/UserContext';
import { get } from '../../../utils/api';
import useScreenSize from '../../../utils/useScreenSize';
import { useNavigate } from 'react-router-dom';

const { Title: AntTitle, Text } = Typography;

const LocationsReport = () => {

  const [selectedRange, setSelectedRange] = useState('12 months');
  const {user}  = useContext(UserContext);
  const [locationData,setLocationData]  = useState([]);
  const [loading,setLoading]  = useState(false);
  const [typeValue,setTypeValue]  = useState('percent');
  const { isMobile } = useScreenSize();
  const navigate = useNavigate();
   
  const handleRangeChange = (range) => {
    const ageRange=range.target.value;
    setSelectedRange(ageRange);
  };

  const handletypeValue=async (type)=>{
    const ageType=type.target.value;
    setTypeValue(ageType);
  }

  const fetchChartData=async(params={})=>{
    setLoading(true);
    try{
      const response = await get('creator/locationReport', params);
      if(response && response.status){
        setLocationData(response?.data);
      }
    }catch (error) {
      console.error('Error fetching users:', error);
    }finally {
      setLoading(false);
    }
  }

  const handleClose = () => {
    navigate(-1);
  };

  useEffect(()=>{
    fetchChartData({gender:selectedRange});
  },[selectedRange]);

  return (
    <Layout style={ isMobile ? { minHeight: '100vh', padding: '30px 15px' } : { minHeight: '100vh', padding: 30 }}>
       
      {isMobile ?<div>
          <Button
            type="text"
            icon={<ArrowLeftOutlined style={{ color: '#101828' }}/>}
            onClick={handleClose}
            style={{ padding: 0, fontWeight: 600, marginBottom: 10, background: 'transparent' }}
          >Back</Button>
        </div> 
        : <>
         {user?.role==="Team"?
        <Breadcrumb className="breadcrumb-insights" separator={<RightOutlined />} style={{ marginBottom: 25 }}>
          <Breadcrumb.Item href="/team/insights">
            <BarChartOutlined />
          </Breadcrumb.Item>
          <Breadcrumb.Item href="/team/insights">
            <span>Audience Insights</span>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <span>Locations</span>
          </Breadcrumb.Item>
        </Breadcrumb>:<Breadcrumb className="breadcrumb-insights" separator={<RightOutlined />} style={{ marginBottom: 25 }}>
          <Breadcrumb.Item href="/insights">
            <BarChartOutlined />
          </Breadcrumb.Item>
          <Breadcrumb.Item href="/insights">
            <span>Audience Insights</span>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <span>Locations</span>
          </Breadcrumb.Item>
        </Breadcrumb>}</>
      }

      <Flex style={ isMobile ? { flexDirection: 'column', alignItems: 'start', marginBottom: 0 } : { flexDirection: 'row', marginBottom: 40 }}>
        <Flex vertical>
          <AntTitle level={2} style={{ marginBottom: 5, marginTop: 0 }}>Locations report</AntTitle>
          <Text>View and see your top locations and engage with your community</Text>
        </Flex>
        <Flex className="button-date-group" style={ isMobile ? { marginTop: 15 } : { marginLeft: 'auto' }}>
          <Radio.Group value={typeValue} onChange={handletypeValue}>
            <Radio.Button value="percent">Percent %</Radio.Button>
            <Radio.Button value="number">Number #</Radio.Button>
          </Radio.Group>
        </Flex>
      </Flex>

      <Flex horizontal="true" align="center">
        <Flex vertical>
          <AntTitle level={4} style={{ marginTop: 0, marginBottom: 0, fontSize: '18px' }}>Metrics</AntTitle>
        </Flex>
        <Flex className="button-date-group" style={{ marginLeft: 'auto' }}>
          <Radio.Group value={selectedRange} onChange={handleRangeChange}>
            <Radio.Button value="city">City</Radio.Button>
            <Radio.Button value="state">State/Province</Radio.Button>
            <Radio.Button value="country">Country</Radio.Button>
          </Radio.Group>
        </Flex>
      </Flex>

      <Divider />

      {locationData.map((location, index) =>{
        if(location?.count>0){
          return (<div key={index} style={{ marginBottom: '25px', width: 'calc(100% - 18px)' }}>
            <Text style={{ color: '#344054' }}>{location?.city}</Text>
            <Progress className="progress-bar" percent={location.percentage} format={(percent)=>{
              return (typeValue=="number")?location?.count:`${percent}%`;
            }} />
          </div>)
        }
      })}

      
    </Layout>
  );
};

export default LocationsReport;
