import React, { useState,useEffect } from 'react';
import { Upload, Modal, message,Spin } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { openNotification } from '../../utils/utils';
import { del } from '../../utils/api';

const getBase64 = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
};

const ImageUpload = ({setImageId,imageUrl,photoId}) => {
  const [previewVisible, setPreviewVisible] = useState(false);
  const [previewImage, setPreviewImage] = useState('');
  const [previewTitle, setPreviewTitle] = useState('');
  const [fileList, setFileList] = useState([]);
  const [loading,setLoading]=useState(false);

  const handleCancel = () => setPreviewVisible(false);

  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }

    setPreviewImage(file.url || file.preview);
    setPreviewVisible(true);
    setPreviewTitle(file.name || file.url.substring(file.url.lastIndexOf('/') + 1));
  };

  const handleChange = ({ fileList }) => setFileList(fileList);

  const beforeUpload = (file) => {
    const isImage = file.type.startsWith('image/');
    if (!isImage) {
      message.error('You can only upload image files!');
    }
    return isImage;
  };

  const customRequest = async ({ file, onSuccess, onError }) => {
    try {
      const formData = new FormData();
      formData.append('singleImage', file);
      
      // Replace with your actual API endpoint
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/auth/single-upload`, {
        method: 'POST',
        body: formData
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const data = await response.json();
      const {image}=data;
      setImageId(image?.id);
      onSuccess(data, file);
    } catch (error) {
      console.error('Upload failed:', error);
      onError(error);
    }
  };

  const handleRemove = async file => {
    try {
        setLoading(true);
        if (file.uid === photoId) {
            await del(`auth/delete-image/${photoId}`);
            openNotification('Image removed successfully');
            setImageId(null);
        }
        setFileList(prevFileList => prevFileList.filter(item => item.uid !== file.uid));
        openNotification('Image removed successfully');
    } catch (error) {
      openNotification('Image removed successfully');
    }finally{
      setLoading(false);
    }
  };

  useEffect(()=>{
    if(imageUrl){
      setFileList([
        {
          uid:photoId,
          url: imageUrl,
        },
      ]);
    }
    
  },[imageUrl,photoId]);

  const uploadButton = (
    <div>
      <PlusOutlined />
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  );

  return (
    <>
    <Spin spinning={loading}>
      <Upload
        name="image"
        listType="picture-card"
        fileList={fileList}
        onPreview={handlePreview}
        onChange={handleChange}
        beforeUpload={beforeUpload}
        customRequest={customRequest}
        onRemove={handleRemove}
      >
        {fileList.length >= 1 ? null : uploadButton}
      </Upload>
      <Modal
        visible={previewVisible}
        title={previewTitle}
        footer={null}
        onCancel={handleCancel}
      >
        <img alt="example" style={{ width: '100%' }} src={previewImage} />
      </Modal>
      </Spin>
    </>
  );
};

export default ImageUpload;
