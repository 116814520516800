import React, { useContext, useState, useEffect } from 'react';
import { Modal, Button, Input, Form, Typography, Flex, Drawer, Dropdown, Divider, Radio, Image, Slider, Checkbox, DatePicker, Calendar, TimePicker, Select, notification, Menu, Spin,message as antdMessage,Avatar } from 'antd';
import { CloseOutlined, EditOutlined, CloseCircleOutlined, PlusOutlined, LeftOutlined, RightOutlined } from '@ant-design/icons';
import { post,get } from '../../utils/api';
import AddressAutocomplete from '../AddressAutoComplete';
import ChatInputNew from './ChatInputNew';
import { UserContext } from '../../contexts/UserContext';
import { getImageUrl,getInitials,openNotification } from '../../utils/utils';
import ReactPlayer from 'react-player';
import dayjs from 'dayjs';
import momenttmz from 'moment-timezone';
import useScreenSize from '../../utils/useScreenSize';
import { debounce } from 'lodash';
import moment from 'moment';
import UnlockIcon from "../../images/unlock-icon.svg";
import CalendarPlusIcon from '../../images/calendar-plus-icon.svg';
import CurrencyDollarIcon from '../../images/currency-dollar-icon.svg';
import CheckCircle from '../../images/check-with-circles.svg';

const { Title, Text } = Typography;
const { Option } = Select;

const NewMessage = ({ visible, handleCancel,setSelectedPrice,basePrice, onClose, open,isScheduled=false }) => {
  const [form] = Form.useForm();
  const {user}  = useContext(UserContext);
  const [fillterform] = Form.useForm();
  const [testMessageForm] = Form.useForm();
  const [message, setMessage] = useState('');
  const [selectedGender, setSelectedGender] = useState('all');
  const [selectAgeRange, setSelectAgeRange] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isSeningTestMessage, setIsSeningTestMessage] = useState(false);
  const [title, setTitle] = useState('Untitled Campaign');
  const { isMobile } = useScreenSize();

  const [price, setPrice] = useState(null);
  const [isEditingTitle, setIsEditingTitle] = useState(false);
  const [filtersData, setFiltersData] = useState([]);
  const [isPriceModalVisible, setIsPriceModalVisible] = useState(false);
  const [isScheduleModalVisible, setIsScheduleModalVisible] = useState(false);
  const [priceInput, setPriceInput] = useState('0.99');
  const [isMessageSending, setIsMessageSending] = useState(false);
  const [isFilterVisible, setIsFilterVisible] = useState(false);
  const [ageRange, setAgeRange] = useState([16, 25]); 
  const [selectedCommunities, setSelectedCommunities] = useState([]); 
  const [communitiesList, setCommunitiesList] = useState([]);
  const [scheduleMessage, setScheduleMessage] = useState(null);
  const [selectedTime, setSelectedTime] = useState(null);
  const [selectedAmPm, setSelectedAmPm] = useState('AM');

  const [isSendTestModalVisible, setIsSendTestModalVisible] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);

  const [users, setUsers] = useState([]);
  const [searchloading, setSearchLoading] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [testUser, setTestUser] = useState(null);
  
  const [chatImages, setChatImages] = useState([]);
  
  const [selectedTimezone, setSelectedTimezone] = useState('America/Los_Angeles');
  const [schedule, setSchedule] = useState('');
  const [selectedDate, setSelectedDate] = useState(dayjs());
  const [currentMonth, setCurrentMonth] = useState(dayjs());
  const checkAll = communitiesList.length === selectedCommunities.length;
  const [appliedFilters, setAppliedFilters] = useState({
    communities: [],
    gender: null,
    selectAgeRange: [],
    location: '',
  });

  const openNotification = (message) => {
    notification.success({
      message: message,
      placement: 'topRight',
      duration: 3,
      icon: <Image src={CheckCircle} preview={false} /> 

    });
  };
  
  const timezones = momenttmz.tz.names();
  const timeZoneAbbreviation = momenttmz.tz.guess(true); 
  useEffect(()=>{
    
    setSelectedTimezone(timeZoneAbbreviation);
  },[timeZoneAbbreviation]);

  const handleInputChange = (value) => {
    setMessage(value);

    const newValue = value;
    setMessage(newValue);

    if (newValue === '') {
      handleClearPrice();
      handleClearScheduleDate();
    }
  };

  const handleImagesChange = (images) => {
    setChatImages(images);

    const newImageValue = images;
    setChatImages(newImageValue);

    if (newImageValue === '') {
      setChatImages([]);
    }
  };

  const loadCommunities = async (current, pageSize) => {
    setCommunitiesList([]);
    setIsLoading(true);
    try {
      const response = await get('creator/communities/');
      if(response){
        const formattedData = response.map(item => ({
          value: item.id,
          label: item.name
      }));
      formattedData.unshift({value: 'Favorites',label:'Favorites'});
       setCommunitiesList(formattedData);
      }
    } catch (error) {
      console.log("error", error);
    } finally {
      setIsLoading(false);
    }
  }

  useEffect(() => {
    const init = async () => {
      await loadCommunities();
    };
  
    init();
  }, []);
  

  useEffect(()=>{
    setPriceInput(basePrice);
    
  },[basePrice]);

  useEffect(() => {
    if(message){
      form.validateFields(['messagebox']);
    }
  }, [message, form]);

  const handleTitleChange = (e) => {
    setTitle(e.target.value);
  };

  const toggleEditTitle = () => {
    setIsEditingTitle(!isEditingTitle);
  };

  const handleTitleBlur = () => {
    setIsEditingTitle(false);
  };

  const handleGenderChange = (e) => {
    setSelectedGender(e.target.value);
  };

  const handleAgeChange = (value) => {
    setAgeRange(value);
  };

  const handleFilterVisibleChange = (flag) => {
    setIsFilterVisible(flag);
  };

  const handlePriceClick = () => {
    setIsPriceModalVisible(true);
    if(basePrice){
      setPriceInput(basePrice);
    }else{
      setPriceInput('0.99');
    }
    
  }

  const handlePriceModalCancel = () => {
    setIsPriceModalVisible(false);
  };
  const handlePriceInputChange = (e) => {
    setPriceInput(e.target.value);
  };
  const handleApplyPrice = () => {
    const priceValue = parseFloat(priceInput);
    if (!isNaN(priceValue) && priceValue >= 0.99) {
      setPrice(priceValue);
      setIsPriceModalVisible(false);
    } else {
      alert("Price must be at least $0.99");
    }
  };

  const handleClearPrice = () => {
    setPriceInput(null);
    setIsPriceModalVisible(false);
  };

  const handleClearScheduleDate = () => {
    setScheduleMessage(null);
    setIsScheduleModalVisible(false);
  };

  const showModal = () => {
    setIsScheduleModalVisible(true);
  };

  const handleOk = () => {
    if (selectedDate && selectedTime) {
      const formattedDate = selectedDate.format('MM/DD/YYYY');
      const formattedTime = selectedTime.format('h:mm') + ` ${selectedAmPm}`;
      const formattedSchedule = `Schedule for ${formattedDate} at ${formattedTime} - ${selectedTimezone}`;
      setScheduleMessage(formattedSchedule);
      setIsScheduleModalVisible(false);
    }else{
      antdMessage.error('Please choose a time to send the SMS.');
    }
  };

  const applyFillters = async (values)=>{
    setIsLoading(true);
    form.setFieldsValue({
      to: '0 Fans'
    });
   // setTitle('Untitled');
    try{
      const collectSelected = checkAll ? { ...selectedCommunities, all: "all" } : selectedCommunities;
      const stringValues = [];
      const numericValues = [];

      Object.values(collectSelected).forEach(value => {
        if (typeof value === 'string') {
          stringValues.push(value);
        } else if (typeof value === 'number') {
          numericValues.push(value);
        }
      });
      const finaldata={...values,stringValues,numericValues,ageRange};
      setFiltersData(finaldata);
      const response = await post('creator/users-filters/',finaldata);
      if(response.status && response.data>0){
        form.setFieldsValue({
          to: `${response.data} Fans`
        });
        if(!title){
          setTitle(`${response.data} Fans`);
        }
       
        setIsFilterVisible(false);
      }else{
        antdMessage.error('0 records match the selected criteria. Please modify your search and try again.');
      }
    }catch(error){
      console.log("error", error);
    } finally {
      setIsLoading(false);
    }
  }

  const getRealPath = async (file ) => {
    try {
      const formData = new FormData();
      formData.append('singleImage', file);
      
      // Replace with your actual API endpoint
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/auth/single-upload`, {
        method: 'POST',
        body: formData
      });

      if (!response.ok) {
        return false;
      }

      const data = await response.json();
     return data;
    } catch (error) {
      console.error('Upload failed:', error);
     return false;
    }
  };

  const sendMessageHandler=async (values)=>{
   
    try{
      let schedule={};
      if(selectedDate && selectedTime){
        const formattedDate = selectedDate.format('MM/DD/YYYY');
        const formattedTime = selectedTime.format('h:mm');
  
         schedule={
          formattedDate,
          selectedAmPm,
          selectedTimezone,
          formattedTime
        };
      }else if(isScheduled){

        const defaultDate = moment();
        const defaultTime = (() => {
          const now = moment();
          const minutes = now.minutes();
          const roundedMinutes = Math.ceil(minutes / 15) * 15;
          let time = now.clone().minutes(roundedMinutes).seconds(0);
          
          // If rounded time is in past, add 15 minutes
          if (time.isBefore(now)) {
            time = time.add(15, 'minutes');
          }
          return time;
        })();

        const formattedDate = (selectedDate)?selectedDate.format('MM/DD/YYYY'):defaultDate.format('MM/DD/YYYY');
        const formattedTime = (selectedTime)?selectedTime.format('h:mm'):defaultTime.format('h:mm');
        const getAMPM = defaultTime.format('A'); // 'A' will return AM/PM
        setSelectedAmPm(getAMPM);
       
         schedule={
          formattedDate,
          selectedAmPm:getAMPM,
          selectedTimezone,
          formattedTime
        };

       

      }
      let mediaPath=null;
      if(chatImages.length>0){
        const FileUrl=chatImages[0] || null;
        const fileType=chatImages[0]?.type || null;
        const source=chatImages[0]?.source || null;
       
        if(fileType=="gif"){
            mediaPath=FileUrl?.file;
        }else if(source=="vault"){
          mediaPath=FileUrl?.thumbUrl;
        }else{
          const urlId=await getRealPath(FileUrl?.file);
          if(urlId && urlId?.image){
            mediaPath=urlId?.image?.filepath;
          } 
        }
      }
      
      const completeFormData = { ...values,filtersData,message,mediaPath,title,schedule,price};
      const response = await post(`creator/sendMessage`,completeFormData);
      if(response && response.status){
        form.resetFields();
        openNotification("New message sent successfully!");
        onClose();
      }
     }catch(error){
      console.log("loading......",error);
    } finally {
      setIsMessageSending(false);
    }
  }

  const handleCheckboxChange = (checkedValues) => {
    setSelectedCommunities(checkedValues);
  };

  const onCheckAllChange = (e) => {
    const crm = [];
  
    communitiesList.forEach(c => {
      crm.push(c?.value);
    });

    setSelectedCommunities(e.target.checked ?crm : []);
  };

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };
  const handleTimeChange = (time) => {
    if (time) {
      const getAMPM = time.format('A'); // 'A' will return AM/PM
      setSelectedAmPm(getAMPM);
      setSelectedTime(time);
    } else {
      setSelectedAmPm('');
      setSelectedTime(null);
    }
  };

  const handleMonthChange = (isNext) => {
    const newMonth = isNext ? currentMonth.add(1, 'month') : currentMonth.subtract(1, 'month');
    setCurrentMonth(newMonth);
    setSelectedDate(newMonth);
  };

  const handleTodayClick = () => {
    const today = dayjs();
    setSelectedDate(today);
    setCurrentMonth(today);
  };

  const handleScheduleCancel = () => {
    setIsScheduleModalVisible(false);
  }

  const onPanelChange = (value, mode) => {
    setCurrentMonth(value);
  };


  const showSendTestModal = () => {
    setIsSendTestModalVisible(true);
  };

  const handleSendTestCancel = () => {
    setIsSendTestModalVisible(false);
  };

  const handleSendTestMessage = async() => {
    setIsSeningTestMessage(true);
    try{
      let mediaPath=null;
      if(chatImages.length>0){
        const FileUrl=chatImages[0] || null;
        const urlId=await getRealPath(FileUrl?.file);
       
     
        if(urlId && urlId?.image){
          mediaPath=urlId?.image?.filepath;
        } 
      }

      if(testMessageForm.validateFields()){
        const completeFormData={
          uId:testUser,
          msg:message,
          mediaPath,
          }
        const response = await post(`creator/TestMessage`,completeFormData);
        if(response && response.status){
          openNotification('Test message sent successfully!');
        }
      }
    
   }catch(error){
    console.log("loading......",error);
  } finally {
    setIsMessageSending(false);
    setIsSeningTestMessage(false);
  }
    // console.log("Selected user ID:", testUser);
    // setIsSendTestModalVisible(false);
  };

  const filterMenu = (
    <Menu style={{ maxWidth: '600px', minWidth: isMobile ? '100%' : '500px', width: '100%' , padding: 0 }}>
      <Spin spinning={isLoading}>
      <Form form={fillterform}  onFinish={applyFillters} layout="vertical" className="fan-communities">

        <Flex vertical style={{ padding: '20px 25px 0px 25px' }}>
       
          <Form.Item name="fanscommunities" label="Fans & Communities" style={{ marginBottom: 0 }}>
          <Checkbox onChange={onCheckAllChange} checked={checkAll}>
              All
            </Checkbox>
            <Checkbox.Group
              style={{ gap: '14px' }}
              options={communitiesList}
              value={selectedCommunities}
              onChange={handleCheckboxChange}
            />
          </Form.Item>
        </Flex> 

        <Divider style={{ marginTop: 12, marginBottom: 12 }} />

        <Flex vertical style={{ padding: '0px 25px 0px 25px' }}>
          <Form.Item name="gender" label="Gender" style={{ marginBottom: 0 }}>
            <Radio.Group defaultValue="all" value={selectedGender} onChange={handleGenderChange}>
              <Radio value="all">All</Radio>
              <Radio value="male">Male</Radio>
              <Radio value="female">Female</Radio>
              <Radio value="other">Other</Radio>
            </Radio.Group>
          </Form.Item>
        </Flex>

        <Divider style={{ marginTop: 12, marginBottom: 12 }} />

        <Flex vertical style={{ padding: '0px 25px 0px 25px' }}>
          <Form.Item name="ageRange" label="Age">
            <Slider range min={16} max={80} defaultValue={ageRange} onChange={handleAgeChange} />
          </Form.Item>

          <Form.Item name="location" label="Location" style={{ marginBottom: 10 }}>
              <AddressAutocomplete/>
          </Form.Item>
        </Flex>

        <Divider style={{ marginTop: 12, marginBottom: 12 }} />

        <Flex vertical style={{ padding: '0px 25px 20px 25px' }}>
          <Form.Item style={{ marginBottom: 0 }}>
            <Button type="default" onClick={() => {
              setIsFilterVisible(false);
              fillterform.resetFields();
              }} style={{ marginRight: '10px' }}>Cancel</Button>
            <Button type="primary" htmlType='submit'>Apply</Button>
          </Form.Item>
        </Flex>
      </Form>
      </Spin>
    </Menu>
  );

  const displayFilters = () => {
    const { communities, gender, ageRange } = appliedFilters;

    return (
      <div>
        {communities.length > 0 && (
          <span style={{ display: 'inline-block', marginRight: '10px' }}>
            Communities: {communities.join(', ')}
          </span>
        )}
        {gender && (
          <span style={{ display: 'inline-block', marginRight: '10px' }}>
            Gender: {gender}
          </span>
        )}
        {selectAgeRange[0] && (
          <span style={{ display: 'inline-block', marginRight: '10px' }}>
            Age: {selectAgeRange[0]} - {selectAgeRange[1]}
          </span>
        )}
      </div>
    );
  };


  const fetchUsers = async (search = '') => {
    try {
      setSearchLoading(true);
      const response = await get(`creator/allFans`,{
        _page:1,
        _limit: 20,
        q: search ||'',
      });
      if (response && response.data) {
       
          // Transform the data for select options
          const options = response?.data?.map(user => ({
            value: user?.user_id,
            label: `${user?.info?.first_name} ${user?.info?.last_name}`,
          }));
          setUsers(options);
        }
    } catch (error) {
      console.error('Error fetching users:', error);
    } finally {
      setSearchLoading(false);
    }
  };

  useEffect(() => {
    fetchUsers();
  }, []);

  // Debounced search handler
  const debouncedSearch = debounce(async (value) => {
    await fetchUsers(value);
  }, 500);

  const handleSearch = (value) => {
    setSearchValue(value);
    debouncedSearch(value);
  };
  const handleChange = (e) => {
    const value=e.target.value;
    
    setTestUser(value);
  };

  const disablePastDates = (current) => {
    return current && current < moment().startOf('day');
  };



  const disabledTime = () => {
    const now = dayjs();
    const currentHour = now.hour();
    const currentMinute = now.minute();
    const IsToday=moment().format('DD-MM-YYYY');
    if(selectedDate && IsToday!=selectedDate.format('DD-MM-YYYY')){
      return false;
     
    }
   
   
  
    return {
      disabledHours: () => {
        // Disable all hours before current hour
        const hours = [];
        for (let i = 0; i < currentHour; i++) {
          hours.push(i);
        }
        return hours;
      },
      disabledMinutes: (selectedHour) => {
        // If selected hour is current hour, disable past minutes
        if (selectedHour === currentHour) {
          const minutes = [];
          for (let i = 0; i < currentMinute; i++) {
            minutes.push(i);
          }
          return minutes;
        }
        return [];
      },
      disabledSeconds: (selectedHour, selectedMinute) => {
        // If selected hour and minute is current time, disable past seconds
        if (selectedHour === currentHour && selectedMinute === currentMinute) {
          const seconds = [];
          for (let i = 0; i < now.second(); i++) {
            seconds.push(i);
          }
          return seconds;
        }
        return [];
      }
    };
  };

  const getDefaultValue = () => {
    const now = dayjs();
    const currentMinute = now.minute();
    const nextSlot = Math.ceil(currentMinute / 15) * 15;
    return now.minute(nextSlot).second(0);
  };

  const VideoURLChecker = (videoURL) => {
    if (!videoURL) return false;
    if (typeof videoURL !== 'string') return false;
    const SUPPORTED_VIDEO_EXTENSIONS = [
      '.mp4', 
      '.avi', 
      '.mov', 
      '.wmv', 
      '.flv', 
      '.webm', 
      '.mkv', 
      '.m4v', 
      '.mpeg', 
      '.mpg'
    ];
    try {
      const normalizedURL = String(videoURL || '');
      return SUPPORTED_VIDEO_EXTENSIONS.some(ext => 
        normalizedURL.toLowerCase().endsWith(ext)
      );
    } catch (error) {
      console.error('Error in VideoURLChecker:', error);
      return false;
    }
  };

  return (
    <Drawer
      placement="right"
      onClose={onClose}
      open={open}
      width="100%"
      className="new-message"
      extra={null}
    >
    <Flex
      // open={visible}
      // onOk={handleCancel}
      // onCancel={handleCancel}
      // width={940}
      style={{ display: 'block', width: '100%' }}
      // footer={false}
    >
      <Spin spinning={isMessageSending}>
        <Form form={form}  onFinish={sendMessageHandler} layout="vertical">

          <Flex horizontal="true" className="head" justify="space-between">
            <Title level={4}>New Message</Title>
            <Button
              type="link"
              icon={<CloseOutlined style={{ color: '#101828' }}/>}
              onClick={handleCancel}
              style={{ alignSelf: 'flex-end' }}
            >
            </Button>
          </Flex>

          {/*<div style={{ padding: '20px 20px 0px 20px' }}>
            {isEditingTitle ? (
              <Flex>
                <Input 
                  value={title} 
                  onChange={handleTitleChange} 
                  onBlur={handleTitleBlur} 
                  placeholder="Enter campaign name"
                  autoFocus 
                  style={{ marginRight: 10, width: 'auto', fontSize: 18, fontWeight: 700, padding: 0, border: 0, borderBottom: '1px solid #EAECF0', boxShadow: '0 0', borderRadius: 0 }} 
                />
              </Flex>
            ) : (
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <Text style={{ fontSize: 18, fontWeight: 700, color: '#000000' }}>{title}</Text>
                <Button type="text" icon={<EditOutlined style={{ color: '#000000' }} />} onClick={toggleEditTitle} />
              </div>
            )}
          </div>*/}

          <Flex align="start">
            <Flex vertical style={{ flex: 1, padding: 20 }}>

              <Flex style={{ marginBottom: 20 }}>
                <Input 
                  value={title} 
                  onChange={handleTitleChange} 
                  onBlur={handleTitleBlur} 
                  placeholder="Enter campaign name"
                  autoFocus 
                  className="custom-form-item"
                  style={{ marginRight: isMobile ? '' : 10, width: isMobile ? '100%' : '400px', fontSize: 16 }} 
                />
              </Flex>

              <Flex gap={10} horizotanl="true" style={{ marginBottom: 10 }}>
                <Form.Item 
                  name="to" 
                  rules={[{ required: true, message: "Please add the receiver's phone numbers.!" }]}
                  className="custom-form-item"
                  style={{ flex: '1', marginBottom: 0 }}
                >
                  <Input
                    placeholder=""
                    addonBefore={<Text>To:</Text>}
                    readOnly={true}
                  />
                </Form.Item>
                <Dropdown
                  overlay={filterMenu}
                  trigger={['click']}
                  onVisibleChange={handleFilterVisibleChange}
                  visible={isFilterVisible}
                  className="dropdown-btn"
                  placement="bottomRight"
                >
                  <Button icon={<PlusOutlined />} style={{ height: '44px', minWidth: '40px' }}></Button>
                </Dropdown>
              </Flex>

              {displayFilters() && (
                <Flex vertical style={{ marginTop: '10px' }}>
                  {displayFilters()}
                </Flex>
              )}
             
              <Form.Item 
               name="messagebox"
               labelCol={{ span: 24 }}
               wrapperCol={{ span: 24 }}
               rules={[{ required: !message && chatImages?.length==0, message: 'Please input your message!' }]}
               className="custom-form-item custom-message-box"
              >
                <ChatInputNew setSelectedPrice={setSelectedPrice} onInputChange={handleInputChange} onImagesChange={handleImagesChange} />
              </Form.Item>
              {(message || isScheduled) && <>
                {(isScheduleModalVisible || isScheduled) &&
                  <Flex gap={10} wrap="wrap" horizotanl align="center" style={{ padding: '15px 0px', borderBottom: '1px solid #d9d9d9' }}>
                    <Text style={{ marginTop: 0, marginBottom: 0 }}>Send on</Text>
                    <Flex gap={10} className="date-today-button">
                      <DatePicker 
                          value={selectedDate ? dayjs(selectedDate, 'MM/DD/YYYY') : null}
                          onChange={handleDateChange}
                          format="MM/DD/YYYY"
                          style={{ flex: 1 }}
                          disabledDate={disablePastDates}
                      />
                    </Flex>
                    <Text style={{ marginTop: 0, marginBottom: 0 }}>at</Text>
                    <Form.Item 
                       className="custom-form-item timepicker-input"
                       style={{ marginBottom: 0 }}
                      >
                      <TimePicker
                        use24Hours={false} // Ensure it's 12-hour format
                        format="h:mm A" // Show AM/PM in the picker
                        style={{ flex: 1 }}
                        onChange={handleTimeChange}
                        minuteStep={15} // Optional: Step to adjust minutes selection
                        disabledTime={disabledTime}
                        defaultValue={getDefaultValue()}
                      />
                    </Form.Item>
                    <Text style={{ marginTop: 0, marginBottom: 0 }}>{timeZoneAbbreviation}</Text>
                    <CloseCircleOutlined style={{ color: '#D92D20', fontSize: '20px' }} onClick={handleClearScheduleDate} />
                  </Flex>
                }

                {isPriceModalVisible &&
                  <Flex gap={10} horizotanl align="center" style={{ padding: '15px 0px', borderBottom: '1px solid #d9d9d9' }}>
                    <Text style={{ marginTop: 0, marginBottom: 0 }}>Content Price</Text>
                    <Form.Item className="custom-form-input-price" style={{ marginBottom: 0 }}>
                      <Input
                        placeholder=""
                        addonBefore={<Text>$</Text>}
                        value={priceInput}
                        onChange={handlePriceInputChange}
                        style={{ marginBottom: '0px' }}
                      />
                    </Form.Item>
                    <CloseCircleOutlined style={{ color: '#D92D20', fontSize: '20px' }} onClick={handleClearPrice} />
                  </Flex>
                }
              </>}

              <Flex
                vertical
                justify="end"
                gap="small"
                style={{
                  width: '100%',
                  marginTop: 10
                }}
              >

              {price !== null ? ( 
                  <Flex
                    horizotanl="true"
                    justify="end"
                    gap={12}
                    style={{
                      width: '100%',
                      marginTop: '20px'
                    }}
                  >
                 <span style={{ color: '#344054', fontSize: '16px', fontWeight: 600 }}>{`$${price.toFixed(2)}`}</span> <EditOutlined style={{ fontSize: '20px' }} onClick={() => setIsPriceModalVisible(true)} /> <CloseCircleOutlined style={{ color: '#D92D20', fontSize: '20px' }} onClick={handleClearPrice} />
                </Flex>
                ) : (
                  ''
              )}

              {scheduleMessage !== null ? ( 
                  <Flex
                    horizotanl="true"
                    justify="end"
                    gap={12}
                    style={{
                      width: '100%',
                      marginTop: '0px'
                    }}
                  >
                    <span style={{ color: '#344054', fontSize: '16px' }}>{scheduleMessage}</span> <EditOutlined style={{ fontSize: '20px' }} onClick={() => setIsScheduleModalVisible(true)} /> <CloseCircleOutlined style={{ color: '#D92D20', fontSize: '20px' }} onClick={handleClearScheduleDate} />
                  </Flex>
                ) : (
                ''
              )}
              </Flex>
     
              <Flex
                horizotanl
                justify="end"
                gap="small"
                wrap="wrap"
                style={{
                  width: '100%',
                  marginTop: 20
                }}
              >

              {isScheduleModalVisible == true  ? ( 
                 ''
                ) : (
                <>
                {(message || chatImages.length > 0) && !isScheduled?
                  <Button size="large" style={{ fontSize: '14px', fontWeight: 600, display: 'flex', alignItems: 'center', padding: '7px 15px 7px 15px', flex: isMobile ? '1' : '' }} onClick={showModal}>Schedule</Button>
                : "" 
                } 
              </>)}

              {isPriceModalVisible == true ? ( 
                  ''
                ) : (<>
                {chatImages.length > 0 ?
                  <Button
                    size="large"
                    style={{ fontSize: '14px', fontWeight: 600, display: 'flex', alignItems: 'center', padding: '7px 15px 7px 15px', flex: isMobile ? '1' : '' }}
                    onClick={handlePriceClick}
                  >
                    Set Price
                  </Button>
                  :
                  ""
                }
              </>)}

              {message || chatImages.length > 0 ?
                <Button key="submit" htmlType='submit' type="primary" size="medium" style={{ fontWeight: 600, paddingTop: 7, paddingBottom: 7, height: 'auto', width: isMobile ? '100%' : '' }}>
                 {isScheduleModalVisible || isScheduled?'Create Schedule':'Send'}  
                </Button>
              :
                ""
              }

              </Flex>

            </Flex>
            {isMobile ?
              ""
            :
              <Flex vertical style={{ flex: '0 1 316px', maxWidth: '316px', background: 'linear-gradient(to top, #F5F1FF, #FFFFFF)', borderLeft: '1px solid #EAECF0', minHeight: 'calc(100vh - 73px)' }}>
                <Flex vertical style={{ flex: 1, overflow: 'hidden', padding: '24px 24px' }}>
                  <Flex gap={10} vertical align="center" style={{ textAlign: 'center', marginBottom: 10, paddingTop: 20 }}>
                  {user?.profileUrl ?
                      <Avatar size={40} src={getImageUrl(user?.profileUrl)} />          
                      :
                      <Avatar size={40} style={{ fontSize: 16, fontWeight: 600 }}>{getInitials(`${user?.first_name} ${user?.last_name}`)}</Avatar>
                    }
                   <Text>{user?.first_name} {user?.last_name}</Text>
                  </Flex>

                  <div style={{ flex: '1 1 0%', overflow: 'hidden auto', scrollbarWidth: 'none' }}>

                    {isPriceModalVisible == true ? (<>
                      <Flex vertical style={{ maxWidth: '230px', width: '100%', textAlign: 'center' }}>
                        <Flex vertical justify="end" align="center" style={{ backgroundColor: '#4D5159', padding: 25, borderRadius: '12px 12px 0px 0px', width: '100%', minHeight: '236px', boxSizing: 'border-box' }}>
                         <Image
                            width={56}
                            src={UnlockIcon}
                            preview={false}
                            style={{ marginBottom: 20 }}
                          />
                          <Button size="large" style={{ backgroundColor: '#ffffff', fontWeight: 600 }}>Unlock for ${priceInput}</Button>
                        </Flex>
                        <Flex vertical justify="center" align="start" style={{ backgroundColor: '#E5E5EA', padding: '8px 15px', borderRadius: '0px 0px 12px 12px', width: '100%', boxSizing: 'border-box' }}>
                          <Text style={{ color: '#101828', fontSize: '16px' }}>See content to unlock</Text>
                          <Text style={{ fontSize: '12px' }}>blonde.social</Text>
                        </Flex>
                      </Flex>
                     </>) : (<>

                        {chatImages.length > 0 && (
                          <Flex className="images-preview-grid" wrap gap={10} style={{ marginBottom: 10 }}>
                            {chatImages.map((image) => (
                              <>
                               {VideoURLChecker(image?.name)?
                                <ReactPlayer width="100%" height="100px" url={image?.url} controls />
                               : <Image 
                                  src={image.type === 'gif' ? image.file : image.url}
                                  alt={image.name}
                                  preview={false}
                                  style={{ borderRadius: 8, objectFit: 'cover', flex: '1 auto' }}
                                />}
                                </>
                            ))}
                          </Flex>
                        )}          
                      </>)
                    }

                    {message &&
                      <Flex style={{ padding: '10px 0px', maxWidth: '80%'}}>
                        <div style={{ background: '#E9E9EB', borderRadius: 18, padding: '6px 12px', overflowWrap: 'anywhere' }}>
                           <div dangerouslySetInnerHTML={{ __html: message }} style={{ whiteSpace: 'pre-wrap' }} />
                        </div>
                      </Flex>
                    }

                  </div>

                </Flex>
                <Flex style={{ padding: '20px 24px', background: '#ffffff', borderTop: '1px solid #D0D5DD' }}>
                  {message || chatImages.length > 0? 
                   <Button onClick={showSendTestModal} type="default" block style={{ fontWeight: 600, paddingTop: 7, paddingBottom: 7, height: 'auto' }}>Send a test message</Button>
                   :<Button type="default" disabled="true" block style={{ fontWeight: 600, paddingTop: 7, paddingBottom: 7, height: 'auto' }}>Send a test message</Button>}
                
               
                </Flex>
              </Flex>
            }
          </Flex>

          </Form>
        </Spin>

        <Modal
          title="Send Test Message"
          open={isSendTestModalVisible}
          onOk={handleSendTestMessage}
          onCancel={handleSendTestCancel}
          okText="Send"
          confirmLoading={isSeningTestMessage}
        >
          <Spin spinning={isSeningTestMessage}>
          <Form form={testMessageForm} layout="vertical">
            {/* <Form.Item name="testUser" label="Select User"  rules={[{ required: true, message: "Please Select User." }]}>
              <Select
                showSearch
                placeholder="Choose a user"
                filterOption={false}
                notFoundContent={searchloading ? <Spin size="small" /> : 'No users found'}
                onSearch={handleSearch}
                onChange={handleChange}
             
              >
                {users.map((user) => (
                  <Select.Option key={user.value} value={user.value}>
                    {user.label} {console.log("user",user)}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item> */}
            <Form.Item name="testPhone" label="To phone number"  rules={[{ required: true, message: "Please add to phone number." }]}>
            <Input
                placeholder=""
                prefix="+1"
                style={{ marginBottom: '5px' }}
                onChange={handleChange}
              />
            </Form.Item>
          </Form>
          </Spin>
        </Modal>

        {/*{isPriceModalVisible && (
          <Modal
            open={isPriceModalVisible}
            onCancel={handlePriceModalCancel}
            footer={null}
            width={560}
            className="price-modal"
          >

          <Flex vertical style={{ padding: 20 }}>
            <Title level={4} style={{ fontSize: '18px', marginTop: 0, marginBottom: 25 }}>Content Price</Title>
            <Form.Item className="custom-form-input-price" style={{ marginBottom: 5 }}>
              <Input
                placeholder=""
                prefix="$"
                value={priceInput}
                onChange={handlePriceInputChange}
                style={{ marginBottom: '5px' }}
              />
            </Form.Item>
            <Text>$0.99 is standard minimum starting number.</Text>
          </Flex>

          <Flex gap="small" justify="flex-end" style={{ marginTop: '10px', borderTop: '1px solid #EAECF0', padding: '15px 20px' }}>
            <Button size="large" onClick={handlePriceModalCancel} style={{ fontWeight: 600 }}>Cancel</Button>
            <Button size="large" type="primary" onClick={handleApplyPrice} style={{ fontWeight: 600 }}>Apply</Button>
          </Flex>
          </Modal>
        )}*/}

        {/*<Modal visible={isScheduleModalVisible} onOk={handleOk} onCancel={handleScheduleCancel} width={688} className="schedule-modal" footer={[
          <Flex gap={14} justify="end" style={{ padding: '16px 20px', borderTop: '1px solid #EAECF0' }}>
            <Button key="cancel" size="large" onClick={handleCancel} style={{ fontWeight: 600 }}>Cancel</Button>
            <Button key="add" size="large" 
            type="primary" 
            onClick={handleOk} 
            style={{ fontWeight: 600 }}
            >Apply</Button>
          </Flex>]}>
          <Flex horizotanl style={{ display: 'flex', flexDirection: isMobile && 'column', justifyContent: 'space-between' }}>
            
            <Flex vertical style={{ borderRight: isMobile ? '' : '1px solid #EAECF0', padding: '20px 10px' }}>
              <Flex vertical style={{ padding: '0 10px 0 15px', marginBottom: 20 }}>
                <Text style={{ color: '#101828', fontSize: '16px', marginBottom: 15, display: 'block' }}>Send on</Text>
                <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: 10 }}>
                  <Button type="text" icon={<LeftOutlined />} onClick={() => handleMonthChange(false)} />
                  <Text style={{ fontWeight: 600 }}>{currentMonth.format('MMMM YYYY')}</Text>
                  <Button type="text" icon={<RightOutlined />} onClick={() => handleMonthChange(true)} />
                </div>
                <Flex gap={10} className="date-today-button">
                  <Input 
                    value={selectedDate ? selectedDate.format('MMM DD, YYYY') : ''}
                    readOnly
                    style={{ flex: 1 }}
                  />
                  <Button onClick={handleTodayClick}>Today</Button>
                </Flex>
              </Flex>
              <Calendar
                fullscreen={false}
                value={selectedDate || currentMonth}
                onSelect={handleDateChange}
                onPanelChange={onPanelChange} 
                className="schedule-calendar"
                headerRender={() => null}
              />
            </Flex>

            <Flex vertical style={{ minWidth: '300px', padding: 20, borderTop: isMobile && '1px solid #EAECF0' }}>
              <Text style={{ color: '#101828', fontSize: '16px', marginBottom: 15, display: 'block' }}>At this time</Text>
              <Flex horizotanl gap={10} align="center">
                <TimePicker 
                  use12Hours 
                  format="h:mm" 
                  style={{ flex: 1 }}
                  onChange={(time) =>{
                   
                    if(time){
                      const getAMPM=time.format('a');
                      setSelectedAmPm(getAMPM);
                    }
                    
                    setSelectedTime(time)
                  }} 
                  changeOnScroll 
                  needConfirm={false}
                />
                <Text>at</Text>
                <Select 
                  value={selectedAmPm} 
                  onChange={(value) => setSelectedAmPm(value)}
                >
                  <Option value="am">AM</Option>
                  <Option value="pm">PM</Option>
                </Select>
              </Flex>

              <div style={{ marginTop: '20px' }}>
                <Text>Timezone</Text>
                <Select 
                  allowClear
                  style={{ width: '100%', marginTop: '10px' }} 
                  value={selectedTimezone} 
                  onChange={(value) => setSelectedTimezone(value)}
                  showSearch
                  optionFilterProp="children"
                >
                   {timezones.map((timezone) => {
                      const offset = momenttmz.tz(timezone).format('Z');
                      return (
                        <Option key={timezone} value={`${timezone}`}>
                          {`${timezone} UTC${offset}`}
                        </Option>
                      );
                    })}
                </Select>
              </div>

            </Flex>
          </Flex>
        </Modal>*/}

    </Flex>
    </Drawer>
  );
};

export default NewMessage;
