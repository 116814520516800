import React from 'react';
import { Layout, Col, Row, Typography } from 'antd';

const { Title, Paragraph } = Typography;
const { Content } = Layout;

const AgencyTermsUse = () => {

  return (
    <Content style={{ padding: '0 30px' }}>
      <Row style={{ justifyContent: 'center' }}>
      <Col 
        xs={{
          span: 24,
        }}
        lg={{
          span: 16,
        }}
      >
      <Title level={2} style={{ marginBottom: 20, marginTop: 0 }}>Agency Terms of Use</Title>
      <Paragraph>Welcome to [Platform Name]! These Agency Terms of Use ("Agency Terms") govern the access and activities of agencies on our platform. By using [Platform Name] as an agency, you agree to comply with these Agency Terms, in addition to our [Terms of Service](link to Terms of Service), [Privacy Policy](link to Privacy Policy), and [Content Guidelines](link to Content Guidelines). Please review these terms carefully.
      </Paragraph>

      <Title level={4}>1. Eligibility and Registration</Title>
      <Paragraph>To use [Platform Name] as an agency, you must:</Paragraph>
      <Paragraph>
        <ul>
          <li>Be an authorized representative of a legitimate agency, brand, or business entity.</li>
          <li>Register and maintain an active agency account with accurate, current information about your organization and representatives.</li>
          <li>Ensure that all employees or representatives accessing our platform comply with these Agency Terms.</li>
        </ul>
      </Paragraph>
      <Paragraph>By registering, you confirm that your agency has the legal capacity to enter into this agreement and accept responsibility for compliance.</Paragraph>

      <Title level={4}>2. Authorized Use</Title>
      <Paragraph>Agencies are permitted to use [Platform Name] for legitimate business purposes, including:</Paragraph>
      <Paragraph>
        <ul>
          <li>Managing creator profiles, content, and interactions on behalf of clients.</li>
          <li>Engaging with other users, creators, or brands as an official agency representative.</li>
          <li>Participating in advertising, promotional, or partnership programs as allowed by the platform.</li>
        </ul>
      </Paragraph>
      <Paragraph>Misuse of the platform, including unauthorized access to other users’ accounts or engaging in prohibited activities, may lead to account suspension or termination.</Paragraph>


      <Title level={4}>3. Responsibilities of the Agency</Title>
      <Paragraph>Agencies are expected to operate in good faith and follow all platform guidelines. As an agency, you agree to:</Paragraph>
      <Paragraph>
        <ul>
          <li>Represent creators and brands accurately and honestly.</li>
          <li>Respect the rights and privacy of all users, and not engage in deceptive or misleading practices.</li>
          <li>Ensure that all content, communications, and interactions adhere to our [Content Guidelines](link to Content Guidelines) and [Community Standards](link if applicable).</li>
          <li>Obtain any necessary rights, licenses, or permissions required for content you upload, publish, or promote on the platform.</li>
        </ul>
      </Paragraph>

      <Title level={4}>4. Prohibited Actions</Title>
      <Paragraph>The following activities are prohibited on [Platform Name]:</Paragraph>
      <Paragraph>
        <ul>
          <li>Spam and Misrepresentation: Posting unsolicited content, deceptive information, or spamming other users.</li>
          <li>Unauthorized Access: Attempting to access accounts, data, or resources that are not explicitly authorized for your use.</li>
          <li>Infringing Content: Uploading, sharing, or promoting content that violates third-party intellectual property rights.</li>
          <li>Data Scraping and Harvesting: Using bots, scraping tools, or automated processes to collect data or content from the platform.</li>
        </ul>
      </Paragraph>
      <Paragraph>Violations of these terms may result in immediate suspension or termination of your agency account.</Paragraph>


      <Title level={4}>5. Intellectual Property and Content Rights</Title>
      <Paragraph>Agencies must respect and protect the intellectual property rights of creators, brands, and other users on [Platform Name]. By uploading or promoting content on the platform, you represent and warrant that:</Paragraph>
      <Paragraph>
        <ul>
          <li>You have the right and authorization to use, publish, and promote the content on behalf of your client.</li>
          <li>The content does not infringe any third-party rights, including copyrights, trademarks, or privacy rights.</li>
        </ul>
      </Paragraph>
      <Paragraph>You grant [Platform Name] a non-exclusive, worldwide, royalty-free license to use, display, distribute, and promote your content as part of our platform services. This license is revocable upon content removal but may persist in platform backups for a limited period.</Paragraph>


      <Title level={4}>6. Confidentiality and Privacy</Title>
      <Paragraph>As an agency, you may have access to private information about creators or users. You agree to:</Paragraph>
      <Paragraph>
        <ul>
          <li>Protect the confidentiality of any private or sensitive information shared with you.</li>
          <li>Not disclose, sell, or misuse any personal information obtained from the platform without consent.</li>
          <li>Follow all applicable data protection regulations, including [Platform Name]’s [Privacy Policy](link to Privacy Policy) and any relevant legal obligations in your jurisdiction.</li>
        </ul>
      </Paragraph>

      <Title level={4}>7. Payments and Fees</Title>
      <Paragraph>Agencies may incur fees or charges for certain services on [Platform Name], including advertising or promotional services. You agree to:</Paragraph>
      <Paragraph>
        <ul>
          <li>Pay all fees associated with your agency account and use of the platform.</li>
          <li>Accept responsibility for any additional charges, taxes, or processing fees that may apply.</li>
          <li>Review and agree to any payment terms provided at the time of purchase or subscription.</li>
        </ul>
      </Paragraph>
      <Paragraph>All transactions are final, and refund policies may vary by service or subscription type.</Paragraph>

      <Title level={4}>8. Account Suspension and Termination</Title>
      <Paragraph>[Platform Name] reserves the right to suspend or terminate agency accounts that violate these Agency Terms, [Content Guidelines](link to Content Guidelines), or [Terms of Service](link to Terms of Service). Reasons for suspension or termination may include:</Paragraph>
      <Paragraph>
        <ul>
          <li>Engaging in prohibited actions or deceptive practices.</li>
          <li>Violating intellectual property or privacy rights.</li>
          <li>Repeatedly violating platform policies or community standards.</li>
        </ul>
      </Paragraph>
      <Paragraph>Upon termination, any rights or licenses granted to the platform regarding agency content may be revoked, except where necessary for platform backups or compliance purposes.</Paragraph>

      <Title level={4}>9. Indemnity and Liability</Title>
      <Paragraph>You agree to indemnify and hold harmless [Platform Name], its employees, affiliates, and partners from any claims, losses, damages, liabilities, and expenses arising from:</Paragraph>
      <Paragraph>
        <ul>
          <li>Your agency’s use of the platform.</li>
          <li>Violations of these Agency Terms or any other platform policies.</li>
          <li>Infringement of third-party rights, including intellectual property or privacy rights.</li>
        </ul>
      </Paragraph>
      <Paragraph>[Platform Name] assumes no liability for actions or representations made by agencies on behalf of creators or brands.</Paragraph>

      <Title level={4}>10. Modifications to the Terms</Title>
      <Paragraph>We may update these Agency Terms from time to time to reflect changes in our platform, services, or legal requirements. Notice of significant changes will be provided, and continued use of the platform constitutes acceptance of revised terms.</Paragraph>

      <Title level={4}>11. Contact Us</Title>
      <Paragraph>If you have any questions or require assistance regarding these Agency Terms of Use, please contact us at [Contact Information].</Paragraph>

      </Col>
      </Row>
    </Content>
  );
};

export default AgencyTermsUse;
