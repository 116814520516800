import React from 'react';
import { Button, Row, Col, Image, Checkbox,Typography,Radio } from 'antd';
import { UploadOutlined} from '@ant-design/icons';
import FileIcon from '../../images/image-icon.svg';
import ReactPlayer from 'react-player';

const { Title } = Typography;
const Videotab=(props)=>{
    const {
      showUploadModal,
      handleCheckboxChange,
      uploadedFiles,
      selectedFileIndices,
      handleRadioChange,
      singleSelection,
      selectionSelect,
    } = props;

    const handleImageSelect = (id, filepath) => {
      if (singleSelection) {
        handleRadioChange(id, filepath);
      } else {
        handleCheckboxChange(id, filepath);
      }
    };

    return (
        <div>
        {uploadedFiles.length === 0 ? (
          <div style={{ textAlign: 'center', marginTop: 50 }}>
            <Image
              width={48}
              src={FileIcon}
              preview={false}
            />
            <Title level={4} style={{ marginTop: 10, marginBottom: '30px' }}>No videos found</Title>
            <Button type="primary" size="large" icon={<UploadOutlined />} onClick={showUploadModal} style={{ fontWeight: 600 }}>Upload</Button>
          </div>
        ) : (
          <div style={{ marginTop: 20, marginBottom: 20 }}>
            <Row gutter={[10, 10]}>
              {uploadedFiles.map((file, index) => (
                <Col key={index} span={6}>
                  <div
                    onClick={() => handleImageSelect(file?.id, file?.Image?.filepath)}
                    style={{
                      padding: 0,
                      border:
                      selectionSelect && selectedFileIndices.includes(file?.id)
                        ? '2px solid #7F56D9'
                        : '2px solid transparent',
                      textAlign: 'center',
                      cursor: 'pointer',
                      position: 'relative',
                    }}
                    className={selectionSelect ? '': 'video-active'}
                  >
                    {/* {selectedFileIndices.includes(file?.id) && 
                      <Checkbox
                        style={{ position: 'absolute', bottom: 15, right: 15, zIndex: 1 }}
                        checked={selectedFileIndices.includes(file?.id)}
                      />
                    } */}

                    {selectionSelect &&<>
                      {selectedFileIndices.includes(file?.id) && (
                        singleSelection ? (
                          <Radio
                            checked={selectedFileIndices.includes(file?.id)}
                            style={{ position: 'absolute', bottom: 15, right: 15, zIndex: 1 }}
                          />
                        ) : (
                          <Checkbox
                            checked={selectedFileIndices.includes(file?.id)}
                            style={{ position: 'absolute', bottom: 15, right: 15, zIndex: 1 }}
                          />
                        )
                      )}
                    </>}

                     <ReactPlayer width="100%" height="220px" url={`${process.env.REACT_APP_API_BASE_URL}/${file?.Image?.filepath}`} controls />
                  </div>
                </Col>
              ))}
            </Row>
          </div>
        )}
      </div>
    );
}

export default Videotab;