import React, { useState, useContext, useEffect } from 'react';
import { Layout, Flex, Typography, Tabs, Spin, Select } from 'antd';
import Automation from '../../components/Settings/Automation';
import Billing from '../../components/Settings/Billing';
import Notifications from '../../components/Settings/Notifications';
import Password from '../../components/Settings/Password';
import PrivateRequests from '../../components/Settings/PrivateRequests';
import DefaultPrice from '../../components/Settings/DefaultPrice';
import MyProfile from '../../components/Settings/MyProfile';
import { UserContext } from '../../contexts/UserContext';
import './settings.css';
import { get } from '../../utils/api';
import { imageUrl } from '../../utils/utils';
import useScreenSize from '../../utils/useScreenSize';

const { Title } = Typography;
const { Option } = Select;

const Settings = () => {
  const { isMobile } = useScreenSize();
  const [settinginfo, setSettinginfo] = useState(null);
  const [loading, setLoading] = useState(false);
  const [photo, setPhoto] = useState(null);
  const [photoId, setPhotoId] = useState(null);
  const { user } = useContext(UserContext);
  const [activeKey, setActiveKey] = useState('1');

  const init = async (id) => {
    try {
      setLoading(true);
      const response = await get(`/creator/settings/${id}`);
      if (response && response.data) {
        setSettinginfo(response.data);
        if (response.data.photo) {
          const photoId = response.data.photo;
          setPhotoId(photoId);
          const imagePath = await imageUrl(photoId);
          if (imagePath) {
            setPhoto(imagePath);
          }
        }
      }
    } catch (error) {
      console.log('error', error);
    } finally {
      setLoading(false);
    }
  };

  const onChange = (key) => {
    setActiveKey(key);
    if (key !== "4") {
      init(user?.id);
    }
  };

  useEffect(() => {
    init(user?.id);
  }, [user?.id]);

  const items = [
    {
      key: '1',
      label: 'Automations',
      children: <Automation photoId={photoId} photo={photo} settinginfo={settinginfo} />,
    },
    {
      key: '2',
      label: 'Content Pricing',
      children: <DefaultPrice settinginfo={settinginfo} />,
    },
    {
        key: '3',
        label: 'Private Requests',
        children: <PrivateRequests settinginfo={settinginfo} />,
    },
    {
      key: '4',
      label: 'Billing',
      children: <Billing settinginfo={settinginfo} />,
    },
    {
      key: '5',
      label: 'Notifications',
      children: <Notifications settinginfo={settinginfo} />,
    },
    {
      key: '6',
      label: 'Profile',
      children: <MyProfile />
    },
    {
      key: '7',
      label: 'Password',
      children: <Password />,
    }
  ];

  if (!settinginfo) {
    return (<Layout className="profile-page" style={{ padding: 30 }}><Spin spinning={true}></Spin></Layout>);
  }

  const renderContent = () => {
    const activeItem = items.find(item => item.key === activeKey);
    return activeItem ? activeItem.children : null;
  };

  return (
    <Layout style={isMobile ? { padding: '30px 15px' } : { padding: 30 }}>
      <Spin spinning={loading}>
        <Flex className="content-head-area" horizontal justify="space-between" align="center">
          <Title level={2} style={{ marginBottom: '25px' }}>Settings</Title>
        </Flex>
        {isMobile ? (<>
          <Select
            style={{ width: '100%', marginBottom: '20px' }}
            value={activeKey}
            onChange={onChange}
            className="setting-dropdpwn"
          >
            {items.map(item => (
              <Option key={item.key} value={item.key}>{item.label}</Option>
            ))}
          </Select>
          {renderContent()}
        </>) : (
          <Tabs className="settings-tabs" activeKey={activeKey} items={items} onChange={onChange} />
        )}
        
      </Spin>
    </Layout>
  );
};

export default Settings;