import React from 'react';
import { Layout, Col, Row, Typography } from 'antd';

const { Title, Paragraph } = Typography;
const { Content } = Layout;

const CreatorTermsUse = () => {

  return (
    <Content style={{ padding: '0 30px' }}>
      <Row style={{ justifyContent: 'center' }}>
      <Col 
        xs={{
          span: 24,
        }}
        lg={{
          span: 16,
        }}
      >
      <Title level={2} style={{ marginBottom: 20, marginTop: 0 }}>Fan Terms of Use</Title>
      <Paragraph>Welcome to [Platform Name]! These Fan Terms of Use ("Fan Terms") govern your participation as a fan on our platform. By supporting creators on [Platform Name], you agree to comply with these Fan Terms, as well as our [Terms of Service](link to Terms of Service), [Privacy Policy](link to Privacy Policy), and [Content Guidelines](link to Content Guidelines). Please read these terms carefully.
      </Paragraph>

      <Title level={4}>1. Eligibility</Title>
      <Paragraph>To become a fan on [Platform Name], you must:</Paragraph>
      <Paragraph>
        <ul>
          <li>Be at least [Age Requirement] years old or the age of majority in your jurisdiction.</li>
          <li>Register and maintain an active account.</li>
          <li>Provide accurate, complete, and current information as requested.</li>
        </ul>
      </Paragraph>
      <Paragraph>By registering as a fan, you confirm that all information provided is accurate and agree to update it as necessary.</Paragraph>

      <Title level={4}>2. Fan Conduct and Responsibilities</Title>
      <Paragraph>As a fan on [Platform Name], you agree to:</Paragraph>
      <Paragraph>
        <ul>
          <li>Show respect to all creators and other users on the platform.</li>
          <li>Refrain from harassing, threatening, or bullying creators or other fans.</li>
          <li>Avoid sharing any exclusive content received from creators without permission, including downloading, recording, or redistributing content that is not intended for public access.</li>
        </ul>
      </Paragraph>

      <Title level={4}>3. Interacting with Creators</Title>
      <Paragraph>Fans may have the opportunity to interact with creators through comments, messages, or other platform features. Please be aware that:</Paragraph>
      <Paragraph>
        <ul>
          <li>Interactions should be respectful, constructive, and supportive.</li>
          <li>Any offensive, abusive, or inappropriate behavior may lead to the suspension or termination of your account.</li>
          <li>Any agreements or exchanges made directly between you and creators are not endorsed or enforced by [Platform Name], and we assume no responsibility for these interactions.</li>
        </ul>
      </Paragraph>

      <Title level={4}>4. Payment and Subscriptions</Title>
      <Paragraph>If you support creators financially through donations, subscriptions, or other methods, please note:</Paragraph>
      <Paragraph>
        <ul>
          <li>Payments are processed through secure third-party providers, and any payment information provided is subject to their terms and conditions.</li>
          <li>[Platform Name] may charge a platform fee or service fee on transactions, which will be clearly stated at the time of payment.</li>
          <li>All transactions are final, and refunds may be limited based on the policies of [Platform Name] and the specific terms of the creator.</li>
        </ul>
      </Paragraph>

      <Title level={4}>5. Prohibited Actions</Title>
      <Paragraph>As a fan, you agree not to engage in any activities that:</Paragraph>
      <Paragraph>
        <ul>
          <li>Violate any laws or regulations.</li>
          <li>Infringe on the intellectual property rights of creators or the platform.</li>
          <li>Involve unauthorized access to restricted parts of the platform or circumventing platform security measures.</li>
          <li>Spread spam, solicitations, or unauthorized advertising to creators or other fans.</li>
        </ul>
      </Paragraph>

      <Title level={4}>6. Intellectual Property</Title>
      <Paragraph>All content provided by creators and [Platform Name] is protected by copyright and intellectual property laws. By accessing this content, you agree not to:</Paragraph>
      <Paragraph>
        <ul>
          <li>Reproduce, distribute, or publicly display content without explicit permission.</li>
          <li>Share or download exclusive content provided by creators unless explicitly allowed by the creator.</li>
        </ul>
      </Paragraph>
      <Paragraph>Violating intellectual property rights may result in immediate account suspension and potential legal action.</Paragraph>


      <Title level={4}>7. Content Guidelines and Reporting</Title>
      <Paragraph>Fans are expected to follow our [Content Guidelines](link to Content Guidelines) when posting or interacting with content on [Platform Name]. If you encounter content that violates our guidelines or find any content you believe to be inappropriate, please report it. We will review all reports and take necessary action to maintain a safe and respectful environment.</Paragraph>

      <Title level={4}>8. Account Suspension and Termination</Title>
      <Paragraph>[Platform Name] reserves the right to suspend or terminate your account if you violate these Fan Terms, the [Content Guidelines](link to Content Guidelines), or any other platform policies. Grounds for suspension or termination may include:</Paragraph>
      <Paragraph>
        <ul>
          <li>Engaging in prohibited activities.</li>
          <li>Violating platform guidelines or laws.</li>
          <li>Disruptive behavior that undermines the experience for other users.</li>
        </ul>
      </Paragraph>


      <Title level={4}>9. Changes to These Terms</Title>
      <Paragraph>We may update these Fan Terms from time to time to reflect changes in our platform, services, or legal requirements. We will notify you of any significant changes, and your continued use of the platform constitutes acceptance of the revised terms.</Paragraph>

      <Title level={4}>10. Contact Us</Title>
      <Paragraph>If you have any questions about these Fan Terms of Use, please contact us at [Contact Information].</Paragraph>

      </Col>
      </Row>
    </Content>
  );
};

export default CreatorTermsUse;
