import React,{useState,useContext} from 'react';
import { Button, Flex, Form, Input,  Divider, Typography,message ,Spin} from 'antd';
import { Link } from 'react-router-dom';
import { UserContext } from '../../contexts/UserContext';
import { put } from '../../utils/api';
import useScreenSize from '../../utils/useScreenSize';
import { openNotification } from '../../utils/utils';

const { Title, Text } = Typography;

const Password = () => {

    const [form] = Form.useForm();
    const [loading, setLoading]=useState(false);
    const {user}  = useContext(UserContext);
    const { isMobile } = useScreenSize();

    const onSubmit = async(values) => {
      const currentStepData = await form.validateFields();

      if(!currentStepData){
        return false;
      }
      setLoading(true);
      try {
        const response = await put(`creator/change-password/${user?.id}`,values);
        if (response && response.status === 200) {
          form.resetFields();
          openNotification(response.message);
        } else {
          message.error(response.message);
        }

      }catch (error) {
        if (error.response && error.response.data) {
          message.error(error.response.data.message);
        } else {
          message.error(error.message);
        }
      }finally {
        setLoading(false);
      }
    };

    const validateNewPassword = (_, value) => {
        const currentPassword = form.getFieldValue('currentPassword');
        if (value === currentPassword) {
            return Promise.reject(new Error('New password must be different from the current password'));
        }
        return Promise.resolve();
    };

    const validateConfirmPassword = (_, value) => {
        const newPassword = form.getFieldValue('newPassword');
        if (value !== newPassword) {
            return Promise.reject(new Error('The two passwords that you entered do not match!'));
        }
        return Promise.resolve();
    };

    return(
        <div>
          <Spin spinning={loading}>
          <Flex className="head-inner" horizotanl justify="space-between">
            <Flex vertical>
                <Title level={4} style={{ marginBottom: '8px', fontSize: '18px' }}>Password</Title>
                <Text>Please enter your current password to change your password.</Text> 
            </Flex>
          </Flex>

          <Divider />

          <Form form={form} onFinish={onSubmit} className="setting-form">

            <Form.Item
              label="Current password"
              name="currentPassword"
              labelCol={ isMobile ? { span: 24 } : { span: 8 }}
              wrapperCol={ isMobile ? { span: 24 } : { span: 12 }}
              rules={[{ required: true, message: 'Please enter your current password' }]}
              className="custom-form-password"
            >
              <Input.Password placeholder="Current password" />
            </Form.Item>

            {/* <Link style={{ color: '#475467' }}>Forgot passowrd</Link> */}

            <Divider />

            <Form.Item
                label="New password"
                name="newPassword"
                labelCol={ isMobile ? { span: 24 } : { span: 8 }}
                wrapperCol={ isMobile ? { span: 24 } : { span: 12 }}
                rules={[
                    { required: true, message: 'Please input your new password!' },
                    { min: 8, message: 'Your new password must be more than 8 characters.' },
                    { pattern: /[!@#$%^&*(),.?":{}|<>]/, message: 'Your new password must contain at least one special character.' },
                    { validator: validateNewPassword }
                ]}
                className="custom-form-password"
            >
                <Input.Password placeholder="New password" />
            </Form.Item>

            <Divider />

            <Form.Item
                label="Confirm new password"
                name="confirmNewPassword"
                labelCol={ isMobile ? { span: 24 } : { span: 8 }}
                wrapperCol={ isMobile ? { span: 24 } : { span: 12 }}
                dependencies={['newPassword']}
                className="custom-form-password"
                rules={[
                    { required: true, message: 'Please confirm your new password' },
                    { validator: validateConfirmPassword }
                ]}
            >
                <Input.Password placeholder="Confirm new password" />
            </Form.Item>

            <Divider />

            <Flex
                horizotanl
                justify="end"
                gap="middle"
                style={{
                  width: '100%',
                  marginTop: '20px'
                }}
              >
              <Button style={{ fontWeight: 600 }} size="large">
                Cancel
              </Button>
                <Button type="primary" htmlType='submit' size="large" style={{ fontWeight: 600 }}>
                  Update password
                </Button>
            </Flex>

          </Form>
          </Spin>
        </div>
    );
};

export default Password;
