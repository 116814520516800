import React from "react";
import { Typography,Image,Flex,Button } from "antd";
import AudioPlayer from "./AudioPlayer";
import UnlockIcon from "../../images/unlock-icon.svg";
import ReactPlayer from "react-player";
const {  Text } = Typography;

const PrintMessage=({message,type,userType="me",priceInput})=>{


    if(type=="text" || type==null){
        return (<Text className={`chat-bubble chat_${userType}`}> 
            <div dangerouslySetInnerHTML={{ __html: message?.replace(/<p><br><\/p>/g, '') }} style={{ whiteSpace: 'pre-wrap' }} />
          </Text>);
    }
    if(type=="link"){
        return (<Text className={`chat-bubble chat_${userType}`}> 
                <a href={message} target="_blank">{message}</a>
          </Text>);
    }
    if(type=="image"){
       const LinkMedia= (message.match('https://media[1-3].giphy.com/media/'))?message:`${process.env.REACT_APP_API_BASE_URL}/${message}`;
        return (<Text className={`chat-image`}> 
                <Image src={LinkMedia} preview={false} />
          </Text>);
    }
    if(type=="paidcontent"){
        return (<Flex vertical style={{ maxWidth: '230px', width: '100%', textAlign: 'center' }}>
            <a href={message} target="_blank"><Flex vertical justify="end" align="center" style={{ backgroundColor: '#4D5159', padding: 25, borderRadius: '12px 12px 0px 0px', width: '100%', minHeight: '236px', boxSizing: 'border-box' }}>
             <Image
                width={56}
                src={UnlockIcon}
                preview={false}
                style={{ marginBottom: 20 }}
              />
              <Button size="large" style={{ backgroundColor: '#ffffff', fontWeight: 600 }}>Unlock for ${priceInput}</Button>
            </Flex>
            <Flex vertical justify="center" align="start" style={{ backgroundColor: '#E5E5EA', padding: '8px 15px', borderRadius: '0px 0px 12px 12px', width: '100%', boxSizing: 'border-box'  }}>
              <Text style={{ color: '#101828', fontSize: '16px' }}>See content to unlock</Text>
              <Text style={{ fontSize: '12px' }}>blonde.social</Text>
            </Flex></a>
          </Flex>);
    }

    if(type=="audio"){
        const audioURL=`${process.env.REACT_APP_API_BASE_URL}/${message}`;
      return (<AudioPlayer audioURL={audioURL}/>);
    }

    if(type=="video"){
      const VideoURL=`${process.env.REACT_APP_API_BASE_URL}/${message}`;
      
      return (<ReactPlayer width="50%" height="200px" url={VideoURL} controls />)
    }

    
}

export default PrintMessage;