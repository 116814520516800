import React, { useState, useRef, useEffect } from 'react';
import { Form, Button, Upload, Modal, Dropdown, Input, Flex, Tabs, Typography, Avatar, Tooltip,message as antdMessage} from 'antd';
import { SmileOutlined, CameraOutlined, GifOutlined, SearchOutlined, AudioOutlined, PlusOutlined, PictureOutlined, EditOutlined, CloseCircleOutlined, LockOutlined ,AudioMutedOutlined} from '@ant-design/icons';
import ReactQuill,{ Quill } from 'react-quill';
import 'react-quill/dist/quill.snow.css';
// import EmojiPicker from 'emoji-picker-react';
import Gifs from './Gifs';

import data from '@emoji-mart/data';
import Picker from '@emoji-mart/react';
import useOutsideClick from '../../utils/useOutsideClick';
import ContentVaultUpload from './ContentVaultUpload';
import CurrencyDollarIcon from '../../images/currency-dollar-icon.svg';
import './AudioBlot';
import { removeTags } from '../../utils/utils';
import { post } from '../../utils/api';

const { TabPane } = Tabs;
const { Title, Text } = Typography;

class ImageBlot extends Quill.import('formats/image') {
    static create(value) {
        let node = super.create(value);
        node.setAttribute('src', value);
        return node;
    }

    static formats(node) {
        return node.getAttribute('src');
    }

    static value(node) {
        return node.getAttribute('src');
    }
}
Quill.register(ImageBlot);



const ChatInput = ({ onInputChange, setPriceButton, setSendButton, setLoading,setSelectedPrice=() => {},isShow=true }) => {

  const [message, setMessage] = useState('');
  const [isEmojiPickerVisible, setEmojiPickerVisible] = useState(false);
  const [isAudioModalVisible, setAudioModalVisible] = useState(false);
  const [isVideoModalVisible, setVideoModalVisible] = useState(false);
  const [isContentVaultVisible, setContentVaultVisible] = useState(false);
  const [isRecordingStarted, setIsRecordingStarted] = useState(false);
  const [audioURL, setAudioURL] = useState('');
  const [audioBlob, setAudioBlob] = useState(null);
  const mediaRecorderRef = useRef(null);
  const [contentVault, setContentVault] = useState([]);
  const [price, setPrice] = useState(null);
  const [priceInput, setPriceInput] = useState('');
  const [isPriceModalVisible, setIsPriceModalVisible] = useState(false);
  const emojiPickerRef = useRef(null);
  const quillRef = useRef(null);
  const beepSound = useRef(null);
  const [gifsVisible,setGifsVisible]=useState(false);

  const [searchTerm, setSearchTerm] = useState('');
  const [visible, setVisible] = useState(false);
  const dropdownRef = useRef(null);
 
  const onSelectGifs=async(savedImagePath)=>{
    const quill = quillRef.current.getEditor();
    const range = quill.getSelection();
    if (range) {
          quill.insertEmbed(range.index, 'image', savedImagePath);
          quill.setSelection(range.index + 1);
      } else {
          quill.insertEmbed(0, 'image', savedImagePath);
          quill.setSelection(1);
      }
      
      const updatedMessage = quill.root.innerHTML;
      setMessage(updatedMessage);
      onInputChange(updatedMessage);
      setGifsVisible(false);
  }

  const handleEmojiClick = (event) => {
    const quill = quillRef.current.getEditor();
    const range = quill.getSelection(true);

    // Check if event has the emoji property
    if (event && event.native) {
      quill.insertText(range.index, event.native); // Use event.native for the emoji
      
      const updatedMessage = quill.root.innerHTML;
      setMessage(updatedMessage);
      onInputChange(updatedMessage);
      setEmojiPickerVisible(false);
    } else {
      console.error('Emoji not found in event:', event);
    }
  };

  useOutsideClick(emojiPickerRef, () => setEmojiPickerVisible(false));

  // const handleUploadChange = async({ file }) => {
  //   const objectUrl = URL.createObjectURL(file);
  //   const quill = quillRef.current.getEditor();
  //   const range = quill.getSelection();

  //   const formData = new FormData();
  //   formData.append('multiImage', file, 'recording.wav');
  //   const response = await post('creator/content-vault', formData);
  //   if (response?.data && response.data.length > 0) {
  //     const savedImagePath=` ${process.env.REACT_APP_API_BASE_URL}/${response.data[0]}`;
  //     if (range) {
  //         quill.insertEmbed(range.index, 'image', savedImagePath);
  //         quill.insertText(range.index + 1, '\n');
  //         quill.setSelection(range.index + 2);
  //     } else {
  //         quill.insertEmbed(0, 'image', savedImagePath);
  //         quill.insertText(1, '\n');
  //         quill.setSelection(2);
        
  //     }
  //   }
  //     // Revoke object URL after image loads
  //     const img = new Image();
  //     img.src = objectUrl;
  //     img.onload = () => {
  //       URL.revokeObjectURL(objectUrl);
  //     };
      
  //     const updatedMessage = quill.root.innerHTML;
  //     setMessage(updatedMessage);
  //     onInputChange(updatedMessage);
  // };

  const handleUploadChange = async ({ file }) => {
    const objectUrl = URL.createObjectURL(file);
    const quill = quillRef.current.getEditor();
    let range = quill.getSelection();

    const formData = new FormData();
    formData.append('multiImage', file, 'recording.wav');
    const response = await post('creator/content-vault', formData);

    if (response?.data && response.data.length > 0) {
        const savedImagePath = `${process.env.REACT_APP_API_BASE_URL}/${response.data[0]}`;

        if (range) {
            if (range.index > 0) {
                quill.insertText(range.index, '\n');
                range = quill.getSelection();
            }
            quill.insertEmbed(range.index, 'image', savedImagePath);
            quill.insertText(range.index + 1, '\n');
            quill.setSelection(range.index + 2);
        } else {
            quill.insertEmbed(0, 'image', savedImagePath);
            quill.insertText(1, '\n');
            quill.setSelection(2);
        }
    }

    // Revoke object URL after image loads
    const img = new Image();
    img.src = objectUrl;
    img.onload = () => {
        URL.revokeObjectURL(objectUrl);
    };

    const updatedMessage = quill.root.innerHTML;
    setMessage(updatedMessage);
    onInputChange(updatedMessage);
};


  const handleMessageChange = (content, delta, source, editor) => {
    setMessage(content);
    onInputChange(content);
  };

  const updateImageClasses = () => {
      const quill = quillRef.current.getEditor();
      const editor = quill.root;
      editor.querySelectorAll('p').forEach(p => {
          if (p.querySelector('img')) {
              p.classList.add('custom-image-class');
          } else {
              p.classList.remove('custom-image-class');
          }
      });
  };

  useEffect(() => {
      updateImageClasses();
  }, [message]);

  const contentVaultData = (data) => {
    setContentVault(data); // Update the state with data from the child
  };
  // const handleAddContentVault = () =>{
  //   if(contentVault.length>0){

  //     contentVault.forEach((item, index)=>{
  //       const quill = quillRef.current.getEditor();
  //       const range = quill.getSelection();

  //       if (range) {
  //           if (range.index > 0) {
  //               quill.insertText(range.index, '\n');
  //           }
  //           quill.insertEmbed(range.index, 'image', `${process.env.REACT_APP_API_BASE_URL}/${item}`);
  //           quill.insertText(range.index + 1, '\n');
  //           quill.setSelection(range.index + 2);
  //       } else {
  //           quill.insertEmbed(0, 'image', `${process.env.REACT_APP_API_BASE_URL}/${item}`);
  //           quill.insertText(1, '\n');
  //           quill.setSelection(2);
  //       }

        
  //       const updatedMessage = quill.root.innerHTML;
  //       setMessage(updatedMessage);
  //       onInputChange(updatedMessage);
  //     });
  //     setContentVaultVisible(false);
  //   }else{
  //     antdMessage.error("Please select at least one image.");
  //   }
  //   console.log("contentVault",contentVault);
  // };


  const handleAddContentVault = () => {
      if (contentVault.length > 0) {
          const quill = quillRef.current.getEditor();
          contentVault.forEach((item, index) => {
              const range = quill.getSelection();

              if (range) {
                  // Insert a new line before the image if it's not at the beginning
                  if (range.index > 0) {
                      const before = quill.getText(range.index - 1, 1);
                      if (before !== '\n') {
                          quill.insertText(range.index, '\n');
                          quill.setSelection(range.index + 1);
                      }
                  }

                  // Insert the image
                  quill.insertEmbed(range.index, 'image', `${process.env.REACT_APP_API_BASE_URL}/${item}`);

                  // Insert a new line after the image
                  quill.insertText(range.index + 1, '\n');
                  quill.setSelection(range.index + 2);
              } else {
                  // If there's no range (caret is at the start), add the image at the beginning
                  quill.insertEmbed(0, 'image', `${process.env.REACT_APP_API_BASE_URL}/${item}`);
                  quill.insertText(1, '\n'); 
                  quill.setSelection(2);
              }
          });

          const updatedMessage = quill.root.innerHTML;
          setMessage(updatedMessage);
          onInputChange(updatedMessage);
          setContentVaultVisible(false);
      } else {
          antdMessage.error("Please select at least one image.");
      }
      console.log("contentVault", contentVault);
  };


  const handleClearPrice = () => {
    setPrice(null);
  };

  const handlePriceModalCancel = () => {
    setIsPriceModalVisible(false);
  };
  const handlePriceInputChange = (e) => {
    setPriceInput(e.target.value);
  };

  const handleApplyPrice = () => {
    const priceValue = parseFloat(priceInput);
    if (!isNaN(priceValue) && priceValue >= 0.99) {
      setPrice(priceValue);
      setIsPriceModalVisible(false);
    } else {
      alert("Price must be at least $0.99");
    }
  };

  const handleRecording = async () => {
    if (!navigator.mediaDevices || !navigator.mediaDevices.getUserMedia) {
      message.error("Your browser doesn't support audio recording.");
      return;
    }
  
    if (!isRecordingStarted) {
      // Recording is about to start
      await startRecording();
    } else {
      // Recording is about to stop
      await stopRecording();
    }
  
    // Toggle recording state
    setIsRecordingStarted(prevState => !prevState);
  };

  const startRecording = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      mediaRecorderRef.current = new MediaRecorder(stream);
      const chunks = [];
  
      mediaRecorderRef.current.ondataavailable = (e) => {
        chunks.push(e.data);
      };
  
      mediaRecorderRef.current.onstop = async () => {
        const blob = new Blob(chunks, { type: 'audio/wav' });
        const url = URL.createObjectURL(blob);
        setAudioURL(url);
        setAudioBlob(blob);
        
        // After stopping, immediately handle the audio blob
        await sendOnlyRecording(blob);
      };
  
      mediaRecorderRef.current.start();
  
      if (beepSound.current) {
        beepSound.current.play();
      }
    } catch (error) {
      message.error("Failed to start recording.");
      console.error("Error accessing media devices.", error);
    }
  };

  const stopRecording = async () => {
   
    if (mediaRecorderRef.current) {
      mediaRecorderRef.current.stop();
      mediaRecorderRef.current = null;
    }
   
  };

  const clickMe=async()=>{
    console.log("clickkkk,")
  }

  const sendOnlyRecording=async (audioBlob)=>{
    if (audioBlob) {
      const formData = new FormData();
      formData.append('multiImage', audioBlob, 'recording.wav');
      const response = await post('creator/content-vault', formData);
      if (response?.data && response.data.length > 0) {
        const savedImagePath=` ${process.env.REACT_APP_API_BASE_URL}/${response.data[0]}`;
        const quill = quillRef.current.getEditor();
        const range = quill.getSelection();
        const HtmlClipBoard = `
            <div>
              <audio src="${savedImagePath}" preload="auto"></audio>
            </div>
          `;
        if(range){
          quill.clipboard.dangerouslyPasteHTML(range.index, HtmlClipBoard);
        
          quill.setSelection(range.index + 1);
        }else{
          quill.clipboard.dangerouslyPasteHTML(0, HtmlClipBoard);
          quill.setSelection( 1);
        }
        const updatedMessage = quill.root.innerHTML;
        setMessage(updatedMessage);
        onInputChange(updatedMessage);
      }
      console.log("response",response);
    }else{
      console.log("audio error");
    }
  }

  useEffect(()=>{
    setSelectedPrice(price);
  },[price]);

  const items = [
    {
      label: <Button type="text" icon={<CameraOutlined style={{ fontSize: 20 }} />} style={{ padding: 0, background: 'transparent' }}>Photo</Button>,
      key: '0',
    },
    {
      label: <Upload
          key="Allimageonly"
          style={{ margin: 0 }}
          accept="image/*"
          showUploadList={false}
          beforeUpload={() => false} // Prevent automatic upload
          onChange={handleUploadChange}
      >
          <Button type="text" icon={<PictureOutlined style={{ fontSize: 20 }} />} style={{ padding: 0, background: 'transparent' }}>Upload</Button>
      </Upload>,
      key: '1',
    },
    {
      label: <>{isShow &&
        <Button type="text" onClick={() => setContentVaultVisible(true)} icon={<LockOutlined style={{ fontSize: 20 }} />} style={{ padding: 0, background: 'transparent' }}>Content</Button>
      }</>,
      key: '3',
    },
  ];

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleTabChange = (key) => {
    if (key === '1') {
      setGifsVisible(false);
      setEmojiPickerVisible(!isEmojiPickerVisible);
    } else if (key === '2') {
      setGifsVisible(true);
    }
  };

  const handleVisibleChange = (flag) => {
    setVisible(flag);
  };

  const dropdownContent = (
    <div ref={dropdownRef} className="emoji-gif-popup" style={{ width: '320px', maxHeight: '350px', overflow: 'auto' }}>
      <Input
        placeholder="Search"
        prefix={<SearchOutlined />}
        onChange={handleSearch}
        value={searchTerm}
        style={{ marginBottom: 10 }}
      />
      <Tabs defaultActiveKey="1" onChange={handleTabChange}>
        <TabPane tab="Emoji" key="1">
         {isEmojiPickerVisible && (
          <div ref={emojiPickerRef}>
            <Picker data={data} onEmojiSelect={handleEmojiClick} />
          </div>
          )}
        </TabPane>

        <TabPane tab="Gif" key="2">
          <Gifs
            gifsVisible={gifsVisible}
            setGifsVisible={setGifsVisible}
            onSelectGifs={(gifUrl) => console.log(gifUrl)} // Replace with your logic
            searchTerm={searchTerm}
          />
        </TabPane>
      </Tabs>
    </div>
  );

  const insertFirstName = () => {
    const quill = quillRef.current.getEditor();
    const selection = quill.getSelection(); 
    if (selection) {
      const cursorPosition = selection.index;
      quill.clipboard.dangerouslyPasteHTML(cursorPosition, '{First Name}');
    } else {
      alert('Please place the cursor where you want to insert {First Name}');
    }
  };

  return (
    <Flex vertical className="message-inner-box" style={{ background: '#ffffff', borderRadius: '6px', border: '1px solid #d9d9d9' }}>
      <Form.Item style={{ flex: 1, margin: 0, }}>
   
        {/* Unlock box */}
        {/*<Flex vertical style={{ maxWidth: '230px', width: '100%', textAlign: 'center', marginLeft: 'auto' }}>
          <Flex justify="center" align="end" style={{ backgroundColor: '#4D5159', padding: 25, borderRadius: '12px 12px 0px 0px', width: '100%', minHeight: '236px' }}>
            <Button size="large" style={{ backgroundColor: '#ffffff', fontWeight: 600 }}>Unlock for $10</Button>
          </Flex>
          <Flex vertical justify="center" align="start" style={{ backgroundColor: '#E5E5EA', padding: '8px 15px', borderRadius: '0px 0px 12px 12px', width: '100%' }}>
            <Text style={{ color: '#101828', fontSize: '16px' }}>See content to unlock</Text>
            <Text style={{ fontSize: '12px' }}>blonde.social</Text>
          </Flex>
        </Flex>*/}

        <ReactQuill
          ref={quillRef}
          className="send-message-box"
          value={message}
          onChange={handleMessageChange}
          modules={{ toolbar: false }}
          placeholder="Send a message..."
          theme="snow"
        />
      </Form.Item>
  
      <Flex className="message-action-row" gap={10} align="center" justify="start" style={{ marginBottom: 0, padding: '10px 15px' }}>
          <Dropdown
            menu={{
              items,
            }}
            placement="topLeft"
            trigger={['click']}
          >
            <span style={{ cursor: 'pointer' }} onClick={(e) => e.preventDefault()}>
              <PlusOutlined style={{ fontSize: 18 }} />
            </span>
          </Dropdown>
          <Tooltip title="Emoji / GIF">
            <Dropdown
              overlay={dropdownContent}
              trigger={['click']}
              visible={visible}
              onVisibleChange={handleVisibleChange}
              placement="bottomLeft"
              getPopupContainer={() => document.body} // Ensures dynamic positioning
            >
              <Button
                type="text"
                icon={<SmileOutlined style={{ fontSize: 18 }} />}
                style={{ padding: 0 }}
                onClick={() => setEmojiPickerVisible(!isEmojiPickerVisible)}
              />
            </Dropdown>
          </Tooltip>
          {/*<Tooltip title="GIF" className="message-upload">
            <Button type="text" onClick={()=>setGifsVisible(true)} icon={<GifOutlined style={{ fontSize: 22 }} />} style={{ padding: 0 }} />
          </Tooltip>*/}
          {/*<Tooltip title="Record Podcast" style={{ display: 'none' }}>
            <Button type="text" onClick={handleRecording} icon={isRecordingStarted?<AudioOutlined style={{ fontSize: 20,color:"green" }} />:<AudioMutedOutlined style={{ fontSize: 20,color:"red" }} />} style={{ padding: 0 }} />
            <audio ref={beepSound} src="/mp3/beep.mp3" preload="auto"></audio>
          </Tooltip>*/}
          {/*<Tooltip title="Emoji">
            <Button
              type="text"
              icon={<SmileOutlined style={{ fontSize: 18 }} />}
              style={{ padding: 0 }}
              onClick={() => setEmojiPickerVisible(!isEmojiPickerVisible)}
            />
          </Tooltip>*/}
          <Tooltip title="Insert First Name">
            <Button type="text" onClick={insertFirstName} style={{ padding: 0, fontWeight: 600 }}>
              {`{+}`}
            </Button>
          </Tooltip>
         {/* <Tooltip title="Attach Image" className="message-upload">
            <Upload
                key="Allimageonly"
                style={{ margin: 0 }}
                accept="image/*"
                showUploadList={false}
                beforeUpload={() => false} // Prevent automatic upload
                onChange={handleUploadChange}
            >
                <Button type="text" icon={<PictureOutlined style={{ fontSize: 20 }} />} style={{ padding: 0 }} />
            </Upload>
          </Tooltip>*/}
         {/* {isShow &&
          <Tooltip title="My Gallery">
            <Button type="text" onClick={() => setContentVaultVisible(true)} icon={<LockOutlined style={{ fontSize: 20 }} />} style={{ padding: 0 }} />
          </Tooltip>}*/}
          {setPriceButton && <>
              {price !== null ? (
                <Flex
                  horizotanl="true"
                  justify="end"
                  gap={6}
                >
               <span style={{ color: '#344054', fontSize: '14px', fontWeight: 600 }}>{`$${price.toFixed(2)}`}</span> <EditOutlined style={{ fontSize: '16px' }} onClick={() => setIsPriceModalVisible(true)} /> <CloseCircleOutlined style={{ color: '#D92D20', fontSize: '16px' }} onClick={handleClearPrice} />
              </Flex>
              ) : (
              (removeTags(message))?<Button
                size="large"
                style={{ fontSize: '14px', fontWeight: 600, color: '#6941C6', borderColor: '#D6BBFB', display: 'flex', alignItems: 'center', padding: '7px 15px 7px 10px' }}
                onClick={() => setIsPriceModalVisible(true)}
                icon={<Avatar size={18} src={<img src={CurrencyDollarIcon} />} />}
              >
               
                Set Price
              </Button>:null
            )}
            </>
          }
          {setSendButton &&
            <Button loading={setLoading} type="primary" htmlType='submit' size="large" style={{ fontWeight: 600 }}>Send</Button>
          }
      </Flex>

      <Modal
        key="Record Audio"
        title="Record Audio"
        open={isAudioModalVisible}
        onCancel={() => setAudioModalVisible(false)}
        footer={null}
      >
        {/* Add audio recording component here */}
      </Modal>
      
      <Modal
       key="Upload Video"
        title="Upload Video"
        open={isVideoModalVisible}
        onCancel={() => setVideoModalVisible(false)}
        footer={null}
      >
        {/* Add video upload component here */}
      </Modal>

      <ContentVaultUpload handleAddContentVault={handleAddContentVault} isContentVaultVisible={isContentVaultVisible} setContentVaultVisible={setContentVaultVisible}  contentVaultData={contentVaultData}/>
      {/*<Gifs gifsVisible={gifsVisible} setGifsVisible={setGifsVisible} onSelectGifs={onSelectGifs} />*/}
      
      {isPriceModalVisible && (
        <Modal
          open={isPriceModalVisible}
          onCancel={handlePriceModalCancel}
          footer={null}
          width={560}
          className="price-modal"
        >
        <Flex vertical style={{ padding: 20 }}>
          <Title level={4} style={{ fontSize: '18px', marginTop: 0, marginBottom: 25 }}>Content Price</Title>
          <Form.Item className="custom-form-input-price" style={{ marginBottom: 5 }}>
            <Input
              placeholder=""
              prefix="$"
              value={priceInput}
              onChange={handlePriceInputChange}
              style={{ marginBottom: '5px' }}
            />
          </Form.Item>
          <Text>$0.99 is standard minimum starting number.</Text>
        </Flex>
        <Flex gap="small" justify="flex-end" style={{ marginTop: '10px', borderTop: '1px solid #EAECF0', padding: '15px 20px' }}>
          <Button size="large" onClick={handlePriceModalCancel} style={{ fontWeight: 600 }}>Cancel</Button>
          <Button size="large" type="primary" onClick={handleApplyPrice} style={{ fontWeight: 600 }}>Apply</Button>
        </Flex>
        </Modal>
      )}

    </Flex>
  );
};

export default ChatInput;