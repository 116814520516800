import React,{useState,useContext} from 'react';
import {  Row, Col, Button, Checkbox, Flex, Form, Input, Select, Divider, Typography,message,Spin } from 'antd';
import { UserContext } from '../../contexts/UserContext';
import { put } from '../../utils/api';
import useScreenSize from '../../utils/useScreenSize';
import { openNotification } from '../../utils/utils';

const { Title, Text } = Typography;
const { Option } = Select;

const Billing = ({settinginfo}) => {

    const [form] = Form.useForm();
    const [loading, setLoading]=useState(false);
    const {user}  = useContext(UserContext);
    const { isMobile } = useScreenSize();

    const onSubmit =async (values) => {
      setLoading(true);
      try {
        const response = await put(`creator/save-settings/${user?.id}`,values);
        if (response && response.status === 200) {
          openNotification(response.message);
        } else {
          message.error(response.message || 'Login failed');
        }

      }catch (error) {
        if (error.response && error.response.data) {
          message.error(error.response.data.message);
        } else {
          message.error(error.message);
        }
      }finally {
        setLoading(false);
      }
    };

    console.log(settinginfo, 'settinginfo')

    if(!settinginfo){
      return false;
    }

    return(
        <div>
          <Spin spinning={loading}>
          <Flex className="head-inner" horizotanl justify="space-between">
            <Flex vertical>
                <Title level={4} style={{ marginBottom: '8px', fontSize: '18px' }}>Billing</Title>
                <Text>We may still send you important notifications about your account outside of your notification settings.</Text> 
            </Flex>
          </Flex>

          <Divider />

          <Form form={form} onFinish={onSubmit} initialValues={settinginfo} className="setting-form">
            <Row>
              <Col xs={{ span: 24 }} md={{ span: 6 }} lg={{ span: 6 }}>
                  <Flex vertical style={isMobile ? { marginBottom: 20 } : ""}>
                    <Text level={4} style={{ fontWeight: 600 }}>Account holder details</Text>
                    <Text>These are notifications for messages on your chats and replies.</Text> 
                  </Flex>
              </Col>
              <Col xs={{ span: 24 }} md={{ span: 1 }} lg={{ span: 1 }}></Col>
              <Col xs={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }}>
                <Form.Item
                  name="firstname"
                  label="First Name (If Individual)"
                  labelCol={{ span: 24 }}
                  wrapperCol={{ span: 24 }}
                  rules={[{ required: false, message: 'Please input your first name!' }]}
                  className="custom-form-item"
                >
                  <Input placeholder="Enter first name" style={{ padding: '7px 10px' }} />
                </Form.Item> 
                <Form.Item
                  name="lastname"
                  label="Last Name (If Individual)"
                  labelCol={{ span: 24 }}
                  wrapperCol={{ span: 24 }}
                  rules={[{ required: false, message: 'Please input your last name!' }]}
                  className="custom-form-item"
                >
                  <Input placeholder="Enter last name" style={{ padding: '7px 10px' }} />
                </Form.Item> 
                <Form.Item
                  name="companyname"
                  label="Company Name (If Company)"
                  labelCol={{ span: 24 }}
                  wrapperCol={{ span: 24 }}
                  rules={[{ required: false, message: 'Please input your company name!' }]}
                  className="custom-form-item"
                >
                  <Input placeholder="Enter company name" style={{ padding: '7px 10px' }} />
                </Form.Item>
                <Form.Item
                  name="companytype"
                  label="Company Type (If Company)"
                  labelCol={{ span: 24 }}
                  wrapperCol={{ span: 24 }}
                  rules={[{ required: false, message: 'Please input your company type!' }]}
                  className="custom-form-item"
                >
                  <Select placeholder="Select One">
                      <Option value="S Corp">S Corp</Option>
                      <Option value="LLC">LLC</Option>
                      <Option value="Single Member LLC">Single Member LLC</Option>
                      <Option value="Sole Prope">Sole Prope</Option>
                    </Select>
                </Form.Item>
                <Form.Item
                  name="ssn"
                  label="SSN / EIN"
                  labelCol={{ span: 24 }}
                  wrapperCol={{ span: 24 }}
                  rules={[{ required: false, message: 'Please input your SSN / EIN type!' }]}
                  className="custom-form-item"
                >
                  <Input placeholder="Enter SSN / EIN" style={{ padding: '7px 10px' }} />
                </Form.Item> 
                <Form.Item
                  name="address"
                  label="Address 1"
                  labelCol={{ span: 24 }}
                  wrapperCol={{ span: 24 }}
                  rules={[{ required: false, message: 'Please input your address' }]}
                  className="custom-form-item"
                >
                  <Input placeholder="Enter address number" style={{ padding: '7px 10px' }} />
                </Form.Item> 
                <Form.Item
                  name="addressop"
                  label="Address 2 (Optional)"
                  labelCol={{ span: 24 }}
                  wrapperCol={{ span: 24 }}
                  rules={[{ required: false, message: 'Please input your address' }]}
                  className="custom-form-item"
                >
                  <Input placeholder="Enter address number" style={{ padding: '7px 10px' }} />
                </Form.Item> 

                <Row gutter={16}>
                  <Col span={12}>
                    <Form.Item
                      label="City"
                      name="city"
                      labelCol={{ span: 24 }}
                      wrapperCol={{ span: 24 }}
                      rules={[{ required: false, message: 'Please enter your city' }]}
                      className="custom-form-item"
                    >
                      <Input placeholder="City" />
                    </Form.Item>
                  </Col>

                  <Col span={12}>
                    <Form.Item
                      label="State/Province"
                      name="state"
                      labelCol={{ span: 24 }}
                      wrapperCol={{ span: 24 }}
                      rules={[{ required: false, message: 'Please select your state or province' }]}
                      className="custom-form-item"
                    >
                      <Select placeholder="Select">
                        <Option value="S Corp">S Corp</Option>
                        <Option value="LLC">LLC</Option>
                        <Option value="Single Member LLC">Single Member LLC</Option>
                        <Option value="Sole Prope">Sole Prope</Option>
                        <Option value="Orlando Diggs">Orlando Diggs @orlando</Option>
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      label="Zip/Postal Code"
                      name="zip"
                      labelCol={{ span: 24 }}
                      wrapperCol={{ span: 24 }}
                      rules={[{ required: false, message: 'Please enter your zip or postal code' }]}
                      className="custom-form-item"
                    >
                      <Input placeholder="Zip/Postal Code" />
                    </Form.Item>
                  </Col>
                </Row>

                <Form.Item
                  name="is_account_agree"
                  valuePropName="checked"
                  wrapperCol={{ span: 24 }}
                  className="custom-form-item"
                >
                  <Checkbox>
                    I hereby agree above entered information is correct
                  </Checkbox>
                </Form.Item>

                <Flex
                    horizotanl
                    justify="end"
                    gap="middle"
                    style={{
                      width: '100%',
                      marginTop: '20px'
                    }}
                  >
                  <Button size="large">
                    Cancel
                  </Button>

                  <Button type="primary" htmlType='submit' size="large" style={{ fontWeight: 600 }}>
                    Confirm
                  </Button>

                </Flex>

              </Col>
            </Row>

          </Form>

          <Divider />

          <Form form={form} onFinish={onSubmit} initialValues={settinginfo} className="setting-form">
            <Row>
              <Col xs={{ span: 24 }} md={{ span: 6 }} lg={{ span: 6 }}>
                  <Flex vertical style={isMobile ? { marginBottom: 20 } : ""}>
                    <Text level={4} style={{ fontWeight: 600 }}>Bank Information</Text>
                    <Text>These are notifications for messages on your chats and replies.</Text> 
                  </Flex>
              </Col>
              <Col xs={{ span: 24 }} md={{ span: 1 }} lg={{ span: 1 }}></Col>
              <Col xs={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }}>
                  
                <Form.Item
                  name="bankname"
                  label="Bank Name"
                  labelCol={{ span: 24 }}
                  wrapperCol={{ span: 24 }}
                  rules={[{ required: false }]}
                  className="custom-form-item"
                >
                  <Input placeholder="Enter bank name" style={{ padding: '7px 10px' }} />
                </Form.Item>

                <Form.Item
                  name="routingnumber"
                  label="Routing number / ABA#"
                  labelCol={{ span: 24 }}
                  wrapperCol={{ span: 24 }}
                  rules={[{ required: false }]}
                  className="custom-form-item"
                >
                  <Input placeholder="Enter routing number / ABA#" style={{ padding: '7px 10px' }} />
                </Form.Item>

                <Form.Item
                  name="accountname"
                  label="Account number"
                  labelCol={{ span: 24 }}
                  wrapperCol={{ span: 24 }}
                  rules={[{ required: false }]}
                  className="custom-form-item"
                >
                  <Input placeholder="Enter account number" style={{ padding: '7px 10px' }} />
                </Form.Item>

                <Form.Item
                  name="confirmaccountname"
                  label="Confirm Account number"
                  labelCol={{ span: 24 }}
                  wrapperCol={{ span: 24 }}
                  rules={[{ required: false }]}
                  className="custom-form-item"
                >
                  <Input placeholder="Confirm account number" style={{ padding: '7px 10px' }} />
                </Form.Item>

                <Form.Item
                  name="bank_agree"
                  valuePropName="checked"
                  wrapperCol={{ span: 24 }}
                  className="custom-form-item"
                >
                  <Checkbox>
                    I hereby agree above entered information is correct
                  </Checkbox>
                </Form.Item>

                <Flex
                    horizotanl
                    justify="end"
                    gap="middle"
                    style={{
                      width: '100%',
                      marginTop: '20px'
                    }}
                  >
                  <Button style={{ fontWeight: 600 }} size="large">
                    Cancel
                  </Button>

                  <Button type="primary" htmlType='submit' size="large" style={{ fontWeight: 600 }}>
                    Confirm
                  </Button>
                
                </Flex>

              </Col>
            </Row>

          </Form>
          </Spin>
        </div>
    );
};

export default Billing;