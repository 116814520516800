import React, { useEffect, useState, useContext } from 'react';
import { Layout, Typography, Flex, List, Avatar, Badge, Space, Spin, Pagination } from 'antd';
import { RightOutlined } from '@ant-design/icons';
import { UserContext } from '../../contexts/UserContext';
import socket from '../../services/socket';
import { get } from '../../utils/api';
import AvatarImage from '../../components/AvatarImage';
import TimeAgo from '../../components/TimeAgo';
import { useNavigate } from "react-router-dom";
import './notification.css';
const { Title, Text } = Typography;

const NotificationCenter = () => {
  const [notificationData, setNotificationData] = useState([]);
  const [notificationCount, setNotificationCount] = useState(0);
  const [pagination, setPagination] = useState({ current: 1, pageSize: 10, total: 0 });
  const { user } = useContext(UserContext);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const fetchData = async (paginationParams) => {
    setIsLoading(true);
    try {
      const response = await get('creator/notification', {
        _page: paginationParams.current,
        _limit: paginationParams.pageSize,
      });

      const ncresponse = await get('creator/nc');
      
      if(ncresponse && ncresponse.status){
        setNotificationCount(ncresponse?.count);
      }

      if (response && response.data) {
        setNotificationData(response.data);
        setPagination(prev => ({
          ...prev,
          total: response.pagination.total,
        }));
      }
    } catch (error) {
      console.log("error", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchData(pagination);
    const handleNewMessage = (message) => {
      if (message && message.status) {
        const { output } = message;
        if (output?.creator_id === user?.id) {
          setNotificationData(prevData => updateOrAppendMessage(prevData, output));
        }
      }
    };

    socket.on('notifications', handleNewMessage);

    return () => {
      socket.off('notifications', handleNewMessage);
    };
  }, [user]);

  const updateOrAppendMessage = (data, newMessage) => {
    const index = data.findIndex(item => item?.id === newMessage?.id);
    if (index !== -1) {
      data[index] = { ...data[index], updatedAt: new Date().toISOString() };
    } else {
      data.push(newMessage);
    }
    data.sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt));
    return [...data];
  };

  const readMessage = async (item) => {
    setIsLoading(true);
    try {
      const response = await get('creator/rn', { q: item?.id });
      if (response && response.status) {
        if (item?.event_type === "new_fan_push") {
          navigate(`/communities/${item?.event_id}`);
        }
        if (item?.event_type === "new_purchases_push") {
          navigate(`/revenue-report`);
        }
      }
    } catch (error) {
      console.log("error", error);
    } finally {
      setIsLoading(false);
    }
  };

  const changePagination = (page, pageSize) => {
    setPagination(prev => {
      const newPagination = {
        ...prev,
        current: page,
        pageSize: pageSize,
      };
      fetchData(newPagination);
      return newPagination;
    });
  };

  return (
    <Layout>
      <Spin spinning={isLoading}>
        <Flex vertical style={{ height: '100vh' }}>
          <div style={{ flex: 1 }}>
          <Space direction="vertical" style={{ width: '100%' }}>
            <Flex gap={14} className="notification-head-area" align="center">
              <Title level={4}>Notification Center</Title>
              {notificationCount>0 && <Badge color="#F9FAFB" count={notificationCount} overflowCount={99} style={{ width: 24, height: 24, lineHeight: '24px', borderRadius: 50, border: '1px solid #EAECF0', boxShadow: '0 0', padding: 0, color: '#344054' }}></Badge>}
            </Flex>

            <List
              itemLayout="horizontal"
              dataSource={notificationData}
              className="notification-list"
              renderItem={(item) => {
                const { first_name, last_name } = item?.notifyUser || {};
                const textdesc = item?.event_type === "new_fan_push" ? "Subscribed" : item?.message;
                return (
                  <List.Item className={item?.isRead ? 'read' : 'unread'} style={{ cursor: 'pointer' }} onClick={() => { readMessage(item) }}>
                    <List.Item.Meta
                      avatar={
                        <AvatarImage
                          size={48}
                          style={{ background: '#98A2B3', fontSize: 20 }}
                          title={`${first_name} ${last_name}`}
                        />
                      }
                      title={
                        <Flex gap={5} className="notification-top-head">
                          <Text className="title" strong>
                            {first_name} {last_name}
                          </Text>
                          <Text
                            style={{ minWidth: '50px', display: 'block', textAlign: 'right' }}
                            type="secondary"
                          >
                            <TimeAgo timestamp={item.updatedAt} />
                          </Text>
                        </Flex>
                      }
                      description={textdesc}
                    />
                    <RightOutlined style={{ fontSize: '16px', color: '#8C8C8C' }} />
                  </List.Item>
                );
              }}
            />
          </Space>
          </div>
          {pagination.total > 0 && (
            <Pagination
              align="center"
              // showTotal={(total) => `Total ${total} items`}
              onChange={changePagination}
              current={pagination.current}
              pageSize={pagination.pageSize}
              total={pagination.total}
              style={{ padding: '20px 20px', borderTop: '1px solid #EAECF0', marginTop: 10 }}
            />
          )}
        </Flex>
      </Spin>
    </Layout>
  );
};

export default NotificationCenter;
