import React from 'react';
import { Layout, Form, Flex, Input, Col, Row, Select, Checkbox, Typography, Button } from 'antd';

const { Title } = Typography;
const { Content } = Layout;

const Contact = () => {
  const handleSubmit = (values) => {
    console.log('Form Submitted:', values);
  };
  const handleChange = (value)=>{
    console.log('valuevaluevalue:', value);
  }
  return (
    <Content>
      <Flex vertical style={{ maxWidth: '416px', margin: '0 auto' }}>
        <Title level={2} style={{ marginBottom: 20, marginTop: 0, textAlign: 'center' }}>Contact Us</Title>
        <Form
          name="contact-form"
          layout="vertical"
          onFinish={handleSubmit}
          initialValues={{
            agreement: false,
            smsUpdates: false,
          }}
        >
          <Form.Item
            label="Subject"
            name="subject"
            className="custom-form-item"
            rules={[{ required: true, message: 'Please enter the subject' }]}
          >
            <Input placeholder="Enter your subject" />
          </Form.Item>

          <Form.Item
            label="Name"
            name="name"
            className="custom-form-item"
            rules={[{ required: true, message: 'Please enter your name' }]}
          >
            <Input placeholder="Enter your name" />
          </Form.Item>

          <Form.Item
            label="Email"
            name="email"
            className="custom-form-item"
            rules={[
              { required: true, message: 'Please enter your email' },
              { type: 'email', message: 'Please enter a valid email address' }
            ]}
          >
            <Input placeholder="Enter your email" />
          </Form.Item>

          <Form.Item
            label="Phone Number"
            name="phoneNumber"
            className="custom-form-item"
            rules={[{ required: true, message: 'Please enter your phone number' }]}
          >
            <Input placeholder="Enter your phone number" />
          </Form.Item>

          <Form.Item
            label="Company Name"
            name="companyName"
            className="custom-form-item"
          >
            <Input placeholder="Enter your company name" />
          </Form.Item>

          <Title level={4} style={{ fontWeight: '500', fontSize: '14px' }}>How did you hear about us?</Title>
          <Select
            showSearch
            style={{
              width: '100%',
              marginBottom: 10
            }}
            placeholder="Select one"
            optionFilterProp="children"
            filterOption={(input, option) => (option?.label ?? '').includes(input)}
            filterSort={(optionA, optionB) =>
              (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
            }
            className="custom-form-select"
            onChange={handleChange}
            options={[
              {
                value: 'Not Identified',
                label: 'Not Identified',
              },
              {
                value: 'Closed',
                label: 'Closed',
              },
              {
                value: 'Communicated',
                label: 'Communicated',
              },
              {
                value: 'Identified',
                label: 'Identified',
              },
              {
                value: 'Resolved',
                label: 'Resolved',
              },
              {
                value: 'Cancelled',
                label: 'Cancelled',
              },
            ]}
          />

          <Form.Item
            label="Message"
            name="message"
            className="custom-form-item"
            rules={[{ required: true, message: 'Please enter your message' }]}
          >
            <Input.TextArea rows={4} placeholder="Enter your message" style={{ border: '1px solid #d9d9d9' }} />
          </Form.Item>


          <Form.Item
            name="agreement"
            valuePropName="checked"
            style={{ marginBottom: 0 }}
            className="checkbox-with-text"
            rules={[
              {
                validator: (_, value) =>
                  value ? Promise.resolve() : Promise.reject('You must agree to the terms'),
              },
            ]}
          >
            <Checkbox>
              I agree to the <a href="#">Terms of Use</a> and <a href="#">Privacy Policy</a>.
            </Checkbox>
          </Form.Item>

          <Form.Item
            name="smsUpdates"
            valuePropName="checked"
            className="sms-updates"
          >
            <Checkbox>
              I agree to receive SMS messages and updates. (Reply "STOP" to end SMS messages and updates. Reply "HELP" for help.)
            </Checkbox>
          </Form.Item>

          <Form.Item>
            <Button type="primary" size="large" htmlType="submit" block style={{ fontWeight: 600 }}>
              Submit
            </Button>
          </Form.Item>
        </Form>
      </Flex>
    </Content>
  );
};

export default Contact;
