import React, { useState ,useEffect,useContext} from 'react';
import { Layout, List, Avatar, Typography, Button, Card, Divider, Flex, Input,Spin ,message,Tabs} from 'antd';
import { CloseOutlined, SearchOutlined } from '@ant-design/icons';
import AvatarImage from '../../components/AvatarImage';
import useScreenSize from '../../utils/useScreenSize';
import { formatPrice,cleanPhoneNumber,openNotification } from '../../utils/utils';
import socket from '../../services/socket';
import { post } from '../../utils/api';
import { UserContext } from '../../contexts/UserContext';
import PrivateRequestFillter from '../../components/PrivateRequest';
import './privaterequests.css';

const { Sider, Content } = Layout;
const { Text, Title, Paragraph } = Typography;

const PrivateRequests = () => {
  const { isMobile, isTablet } = useScreenSize();
  const [selectedRequest, setSelectedRequest] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [requestsData, setRequestsData] = useState([]);
  const [dataCounts, setdataCounts] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const {user}  = useContext(UserContext);

  const handleClose = () => {
    setSelectedRequest(false);
  };


  const handleNewRequest =async (message) => {
    console.log("output",message);
    if (message && message.status) {
      const { output } = message;
     
      if (cleanPhoneNumber(output?.creator_phone) === cleanPhoneNumber(user?.creator_phone)) {
       
       // setMessagesData(prevData => updateOrAppendMessage(prevData, output));

      }
    }
  };

  const init=async(fillter=false)=>{
    setIsLoading(true);
    try{
      const fromdata={
          status:fillter || "all"
      }
      const response=await post('creator/private/privateRequestList',fromdata);
      if(response.status){
        const {rows,count}=response?.data;
        setRequestsData(rows);
        setdataCounts(count);
      }
    }catch(error){
      console.log("error",error);
    }finally{
      setIsLoading(false);
    }
  }
  
  useEffect(()=>{
    init();
    socket.on('privateRequest', handleNewRequest);

    // Clean up on component unmount
    return () => {
      socket.off('privateRequest', handleNewRequest);
    };
  },[]);

  const requestUpdate=async(requestType)=>{
    setIsLoading(true);
    try{
      const response=await post('creator/private/status',{...selectedRequest,requestType});
      if(response.status){
        openNotification(`Request has been ${requestType} successfully.`);
        setSelectedRequest(false);
        await init();
      }
    }catch(error){
      console.log("error",error);
    }finally{
      setIsLoading(false);
    }
  }


  const baseStyles = {
      backgroundColor: '#ffffff',
      borderRight: '1px solid #EAECF0',
      display: 'flex',
      flexDirection: 'column',
  };
  const conditionalStyles = isMobile ? {} : { height: '100vh' };
  const combinedStyles = { ...baseStyles, ...conditionalStyles };


  const items = [
    {
      key: 'all',
      label: 'All',
      children: <PrivateRequestFillter setSelectedRequest={setSelectedRequest} requestsData={requestsData} isMobile={isMobile} selectedRequest={selectedRequest}/>,
    },
    {
      key: '0',
      label: 'Pending',
      children: <PrivateRequestFillter setSelectedRequest={setSelectedRequest} requestsData={requestsData} isMobile={isMobile} selectedRequest={selectedRequest}/>,
    },
    {
      key: '1',
      label: 'Approved',
      children: <PrivateRequestFillter setSelectedRequest={setSelectedRequest} requestsData={requestsData} isMobile={isMobile} selectedRequest={selectedRequest}/>,
    },
    {
      key: '2',
      label: 'Decline',
      children: <PrivateRequestFillter setSelectedRequest={setSelectedRequest} requestsData={requestsData} isMobile={isMobile} selectedRequest={selectedRequest}/>,
    },
  ];

  const onChange =async (key) => {
    await init(key);
    //console.log(key);
  };
  

  return (
    <Layout style={{ minHeight: '100vh' }}>
      <Spin spinning={isLoading}>
      <Sider width={isMobile ? '100%' : isTablet ? 330 : 360} style={combinedStyles}>
        <div className="head-area" style={{ padding: '20px' }}>
          <Title level={2}>Private Requests</Title>
          <Text className="count" strong>{dataCounts}</Text>
        </div>

        <div style={{ padding: '0 20px 20px 20px' }}>
          <Input
            placeholder="Search private requests"
            onChange={e => setSearchTerm(e.target.value)}
            prefix={<SearchOutlined />}
            style={{ width: '100%' }}
          />
        </div>
        <Tabs className="message-tabs request-tabs"   defaultActiveKey="all" items={items} onChange={onChange} />
        
      </Sider>
      </Spin>
    
      {selectedRequest ? (
        <Content style={ isMobile ? { backgroundColor: '#F9FAFB', position: 'fixed', width: '100%', top: 0, left: 0, zIndex: 99, height: '100%' } : { backgroundColor: '#F9FAFB' }}>
          <Card className="request-card" style={{ borderRadius: 0, border: 0 }}>
            <Flex className="head" justify="space-between">
              <Title level={4}>Request Details</Title>
              <Button
                type="link"
                icon={<CloseOutlined style={{ color: '#101828' }}/>}
                onClick={handleClose}
                style={{ alignSelf: 'flex-end' }}
              >
              </Button>
            </Flex>
            <Flex vertical style={{ backgroundColor: '#ffffff', padding: '25px 20px', borderBottom: '1px solid #D0D5DD' }}>
              <Flex horizontal="true" justify="space-between">
                <Flex vertical>
                  <Title level={3} style={{ marginBottom: 8, marginTop: 0, display: 'flex', alignItems: 'center' }}>{formatPrice(selectedRequest?.price||0)} 
                    <Text style={{ backgroundColor: '#FCFAFF', color: '#6941C6', borderRadius: 8, padding: '5px 10px', fontWeight: 600, border: '1px solid #E9D7FE', textTransform: 'capitalize', marginLeft: 10 }}>2-5 Days</Text>
                  </Title>
                  <Text type="secondary" style={{ fontSize: '16px', color: '#475467' }}>{selectedRequest?.title}</Text>
                </Flex>
                <Flex vertical>  
                  <Text style={{ fontSize: '16px', color: '#344054' }}>{selectedRequest?.date}</Text>
                </Flex>
              </Flex>
              <Divider />
              <Paragraph style={{ fontSize: '16px', color: '#475467' }}><strong style={{ fontSize: '14px'}}>To:</strong><br/> {selectedRequest?.to_name}</Paragraph>
              <Paragraph style={{ fontSize: '16px', color: '#475467' }}>
                <strong style={{ fontSize: '14px'}}>Instructions:</strong><br/> {selectedRequest?.message}
              </Paragraph>
            </Flex>
            {selectedRequest?.status===0 && 
            <Flex justify="flex-end" style={{ marginTop: 'auto', backgroundColor: '#FFFFFF', borderTop: '1px solid #EAECF0', padding: '20px 20px' }}>
              <Button onClick={()=>requestUpdate('Decline')} type="primary" size="large" style={{ marginRight: '10px', backgroundColor: '#F04438', borderColor: '#F04438', fontWeight: 600 }}>Decline</Button>
              <Button  onClick={()=>requestUpdate('Approve')} type="primary" size="large" style={{ backgroundColor: '#17B26A', borderColor: '#17B26A', fontWeight: 600 }}>Approve</Button>
            </Flex>}
          </Card>
        </Content>
      ) : (
        ''
      )}
       
    </Layout>
  );
};

export default PrivateRequests;
