import React from 'react';
import { Layout, Col, Row, Typography } from 'antd';

const { Title, Paragraph } = Typography;
const { Content } = Layout;

const PrivacyPolicy = () => {

  return (
    <Content style={{ padding: '0 30px' }}>
      <Row style={{ justifyContent: 'center' }}>
      <Col 
        xs={{
          span: 24,
        }}
        lg={{
          span: 16,
        }}
      >
      <Title level={2} style={{ marginBottom: 20, marginTop: 0 }}>Privacy Policy</Title>
      <Paragraph>By using our Service, you agree to the collection and use of information in accordance with this Privacy Policy.
      </Paragraph>

      <Title level={4}>1. Information We Collect</Title>
      <Paragraph>We collect several types of information to provide and improve our Service to you:</Paragraph>
      <Paragraph>
        <ul>
          <li>Personal Information: When you register, contact us, or make a purchase, we may collect personal information, including your name, email address, phone number, and billing information.</li>
          <li>Usage Data: We may collect information on how you access and use the Service, such as your IP address, browser type, pages visited, and other diagnostic data.</li>
          <li>Cookies and Tracking Technologies: We use cookies and similar tracking technologies to track the activity on our Service and hold certain information. You can disable cookies in your browser settings, though some parts of the Service may become unusable.</li>
        </ul>
      </Paragraph>

      <Title level={4}>2. How We Use Your Information</Title>
      <Paragraph>We use the information we collect in the following ways:</Paragraph>
      <Paragraph>
        <ul>
          <li>To provide and maintain the Service: Including troubleshooting, data analysis, and improvements.</li>
          <li>To communicate with you: Responding to inquiries, sending notifications, updates, and promotional offers.</li>
          <li>To improve our Service: Using data analytics to understand and optimize user experience.</li>
          <li>To comply with legal obligations: For legal requests and compliance with applicable laws.</li>
        </ul>
      </Paragraph>

      <Title level={4}>3. How We Share Your Information</Title>
      <Paragraph>We may share your personal information with third parties under the following circumstances:</Paragraph>
      <Paragraph>
        <ul>
          <li>Service Providers: We may employ third-party companies to facilitate our Service, perform Service-related services, or assist us in analyzing how our Service is used.</li>
          <li>Legal Requirements: We may disclose your personal information if required to do so by law or in response to valid requests by public authorities.</li>
          <li>Business Transfers: If we are involved in a merger, acquisition, or asset sale, your personal information may be transferred. We will notify you before your personal information is transferred and becomes subject to a different Privacy Policy.</li>
        </ul>
      </Paragraph>

      <Title level={4}>4. Security of Your Information</Title>
      <Paragraph>The security of your data is important to us. We strive to use commercially acceptable means to protect your personal information; however, no method of transmission over the internet or method of electronic storage is 100% secure.</Paragraph>

      <Title level={4}>5. Your Rights and Choices</Title>
      <Paragraph>Depending on your location, you may have the following rights:</Paragraph>
      <Paragraph>
        <ul>
          <li>Access: You have the right to access the personal information we hold about you.</li>
          <li>Correction: You can request that we correct or update inaccurate or incomplete personal information.</li>
          <li>Deletion: You may request that we delete your personal information, subject to certain legal exceptions.</li>
          <li>Opt-Out: You may opt out of receiving promotional communications by following the unsubscribe instructions in those communications.</li>
        </ul>
      </Paragraph>
      <Paragraph>To exercise these rights, please contact us at [Contact Information].</Paragraph>


      <Title level={4}>6. Children’s Privacy</Title>
      <Paragraph>Our Service does not address anyone under the age of 13. We do not knowingly collect personal information from children under 13. If we become aware that we have collected personal information from a child under 13 without verification of parental consent, we take steps to remove that information from our servers.</Paragraph>

      <Title level={4}>7. Links to Other Websites</Title>
      <Paragraph>Our Service may contain links to other websites that are not operated by us. We have no control over and assume no responsibility for the content, privacy policies, or practices of any third-party sites or services.</Paragraph>

      <Title level={4}>8. Changes to This Privacy Policy</Title>
      <Paragraph>We may update our Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page. Changes are effective when posted on this page.</Paragraph>

      <Title level={4}>9. Contact Us</Title>
      <Paragraph>If you have any questions about this Privacy Policy, please contact us at [Contact Information].</Paragraph>

      </Col>
      </Row>
    </Content>
  );
};

export default PrivacyPolicy;
