import React, { useState } from 'react';
import { Form, Input, Flex, Typography, Button, Image, Modal } from 'antd';
import { PlusOutlined, CloseCircleOutlined } from '@ant-design/icons';
import DeleteIcon from '../../images/delete-icon-bg.svg';

const { Title, Text } = Typography;
const { TextArea } = Input;

const RequestList = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [newRequestName, setNewRequestName] = useState('');
  const [description, setDescription] = useState('');
  const [removeIndex, setRemoveIndex] = useState(null);
  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);

  // Default fields setup (these will be non-deletable and disabled)
  const defaultRequests = [
    { title: 'Birthday' },
    { title: 'Pep talk' },
    { title: 'Roast' },
    { title: 'Advice' },
    { title: 'Ask a Question' },
    { title: 'Other' },
  ];

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleSave = (add) => {
    if (!newRequestName.trim()) {
      return;
    }

    add({
      title: newRequestName,
      description,
      isCustom: true,
    }, 0);  // Add at index 0 to place it at the top

    setNewRequestName('');
    setDescription('');
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    setNewRequestName('');
    setDescription('');
  };

  // Open the custom deletion modal
  const showDeleteModal = (index) => {
    setRemoveIndex(index);
    setIsDeleteModalVisible(true);
  };

   // Confirm and delete the custom request
  const handleDeleteConfirm = (remove) => {
    if (removeIndex !== null) {
      remove(removeIndex);
      setRemoveIndex(null);
    }
    setIsDeleteModalVisible(false);
  };

  // Cancel the deletion
  const handleDeleteCancel = () => {
    setIsDeleteModalVisible(false);
    setRemoveIndex(null);
  };

  return (
    <Form
      name="dynamic_form_nest_item"
      autoComplete="off"
      initialValues={{
        requests: defaultRequests,
      }}
    >
      <Form.List name="requests">
        {(fields, { add, remove }) => (
          <>
            <Form.Item style={{ marginBottom: 15 }}>
              <Button
                type="dashed"
                onClick={showModal}
                icon={<PlusOutlined />}
                style={{ width: '100%', fontWeight: 600, border: '1px solid #D0D5DD', minHeight: '40px' }}
              >
                Add another
              </Button>
            </Form.Item>

            {fields.map((field, index) => {
              const isCustom = index < fields.length - defaultRequests.length;
              return (
                <div
                  key={field.key}
                  style={{ position: 'relative', marginBottom: 10, width: '100%' }}
                >
                  <Form.Item
                    {...field}
                    name={[field.name, 'title']}
                    fieldKey={[field.fieldKey, 'title']}
                    rules={[{ required: true, message: 'Please input a title!' }]}
                    style={{ marginBottom: 0, width: '100%', opacity: !isCustom ? 0.45 : "" }}
                    className="custom-form-item"
                  >
                    <Input 
                      placeholder={isCustom ? "Custom Request Title" : ""} 
                      disabled={!isCustom}
                    />
                  </Form.Item>

                  {isCustom && (
                    <CloseCircleOutlined
                      style={{
                        color: '#D92D20',
                        position: 'absolute',
                        right: 10,
                        top: 12,
                        fontSize: 20,
                      }}
                      onClick={() => showDeleteModal(field.name)}
                    />
                  )}
                </div>
              );
            })}

            <Modal
              open={isModalVisible}
              onCancel={handleCancel}
              footer={null}
              width={400}
              className="create-request-modal"
            >
              <Flex vertical>
                <Title level={4} style={{ fontSize: '18px', marginTop: 0, marginBottom: 25 }}>Create New Request</Title>

                <Form.Item
                  label="Request Name"
                  labelCol={{ span: 24 }}
                  wrapperCol={{ span: 24 }}
                  rules={[{ required: true, message: 'Please input request name!' }]}
                  className="custom-form-item"
                >
                  <Input
                    placeholder="Write name here..."
                    value={newRequestName}
                    onChange={(e) => setNewRequestName(e.target.value)}
                  />
                </Form.Item>
                <Form.Item
                  label="Description"
                  labelCol={{ span: 24 }}
                  wrapperCol={{ span: 24 }}
                  className="custom-form-item "
                  style={{ marginBottom: 10 }}
                 >
                  <TextArea
                    placeholder="Message..."
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                    style={{ border: '1px solid #d9d9d9' }}
                  />
                  <Text>250 characters left</Text>
                </Form.Item>
              </Flex>

              <Flex gap={5} className="ant-modal-footer">
                <Button size="large" onClick={handleCancel} style={{ fontWeight: 600 }}>Cancel</Button>
                <Button size="large" type="primary" onClick={() => handleSave(add)} style={{ fontWeight: 600 }}>Save</Button>
              </Flex>
            
            </Modal>


            {/* Custom Delete Confirmation Modal */}
            <Modal
              open={isDeleteModalVisible}
              onCancel={handleDeleteCancel}
              footer={null}
              width={400}
              className="delete-request-modal"
            >
              <Flex vertical style={{ textAlign: 'center', alignItems: 'center', paddingTop: 10 }}>
                <Image 
                  src={DeleteIcon}
                  preview={false}
                  width={48}
                  style={{ marginBottom: 15 }}
                />
                <Title level={4} style={{ fontSize: '18px', marginTop: 0, marginBottom: 5 }}>Delete Request Type?</Title>
                <Text style={{ marginBottom: 15 }}>Are you sure you want to delete this request type?</Text>
              </Flex>
              <Flex gap={5} className="ant-modal-footer">
                <Button size="large" onClick={handleDeleteCancel} style={{ fontWeight: 600 }}>Cancel</Button>
                <Button size="large" type="primary" danger onClick={() => handleDeleteConfirm(remove)} style={{ fontWeight: 600 }}>Delete</Button>
              </Flex>
            </Modal>

          </>
        )}
      </Form.List>
    </Form>
  );
};

export default RequestList;