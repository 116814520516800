import React,{ useState } from 'react';
import { Layout, Menu, Flex } from 'antd';
import { Link } from 'react-router-dom';

import './FooterComponent.css';

const { Footer } = Layout;

const FooterComponent = () => {
  const items = [
    { label: ( <Link to="/terms-service">Terms of Service</Link> ), key: 'terms-service' },
    { label: ( <Link to="/privacy-policy">Privacy Policy</Link> ), key: 'privacy-policy' },
    { label: ( <Link to="/content-guidelines">Content Guidelines</Link> ), key: 'content-guidelines' },
    { label: ( <Link to="/creator-terms-use">Creator Terms of Use</Link> ), key: 'creator-terms-use' },
    { label: ( <Link to="/fan-terms-use">Fan Terms of Use</Link> ), key: 'fan-terms-use' },
    { label: ( <Link to="/agency-terms-use">Agency Terms of Use</Link> ), key: 'agency-terms-use' },
    { label: ( <Link to="/payment-terms-conditions">Payment Terms and Conditions</Link> ), key: 'payment-terms-conditions' },
    { label: ( <Link to="/general-terms-service">General Terms of Service</Link> ), key: 'general-terms-service' },
    { label: ( <Link to="/contact">Contact</Link> ), key: 'contact' },
  ];

  const [current, setCurrent] = useState('mail');
  const onClick = (e) => {
    console.log('click ', e);
    setCurrent(e.key);
  };

  return (
    <Footer className="footer" style={{ textAlign: 'center', background: '#ffffff', padding: '0 20px' }}>
      <Flex vertical style={{ maxWidth: '576px', margin: '0 auto' }}>
          <Menu className="footer-menu" onClick={onClick} selectedKeys={[current]} mode="vertical" items={items} />
      </Flex>
    </Footer>
  );
}

export default FooterComponent;
