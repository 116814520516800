import React, { useState, useEffect,useContext } from 'react';
import { Button, Modal, Upload, Layout,Typography, Space, Card, Tabs, Image, Flex, Progress, message,Spin,Pagination } from 'antd';
import { UploadOutlined, CloudUploadOutlined, DeleteOutlined, InboxOutlined, FileImageOutlined, VideoCameraOutlined, PlusOutlined} from '@ant-design/icons';
import Phototab from '../../components/ContentVault/Phototab';
import Videotab from '../../components/ContentVault/Videotab';
import { UserContext } from '../../contexts/UserContext';
import DeleteIcon from "../../images/delete-icon-bg.svg";
import UploadIcon from '../../images/upload-icon.svg';
import { openNotification } from '../../utils/utils';
import ImageUploadIcon from '../../images/img-file-icon.svg';
import VideoUploadIcon from '../../images/video-file-icon.svg';

import './contentvault.css';
import { del, get, post } from '../../utils/api';
import useScreenSize from '../../utils/useScreenSize';

const { Title, Text } = Typography;
const { Dragger } = Upload;
const { TabPane } = Tabs;

const ContentVault = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isDeleteModalVisible, setDeleteModalVisible] = useState(false);
  const [fileList, setFileList] = useState([]);
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [fileType, setFileType] = useState('Photos');
  const [selectedFileIndices, setSelectedFileIndices] = useState([]);
  const [filesList, setFilesList] = useState([]);

  const [selectionSelect, setSelectionSelect] = useState(false);

  const [activeTab, setActiveTab] = useState('Photos');
  const [pagination, setPagination] = useState({ current: 1, pageSize: 12, total: 0 });
  const {user}  = useContext(UserContext);
  const [filesToRender, setFilesToRender] = useState([]);
  const { isMobile } = useScreenSize();

  const [progressMap, setProgressMap] = useState({});

  const filesLoader = async (current, pageSize) => {
    const formData = {
      user_id: user?.id,
      fileType: fileType,
      current: current,
      pageSize: pageSize,
    };
    setIsLoading(true);
    try {
      const response = await get('creator/content-vault', formData);
      if (response.status) {
        setFilesList(response.images);
        setPagination(prevPagination => ({
          ...prevPagination,
          total: response.pagination.total,
        }));
      }
    } catch (error) {
      console.log("error", error);
    } finally {
      setIsLoading(false);
    }
  }

  const onConfrimDelete = async () => {
    try {
      setIsLoading(true);
      const deletePromises = selectedFileIndices.map(async (fileIndex) => {
        return await del(`creator/content-vault/${fileIndex}`);
      });
      const responses = await Promise.all(deletePromises);
      const successMessages = new Set();
      const errorMessages = new Set();
      responses.forEach(response => {
        if (response.status) {
          successMessages.add(response.message);
        } else {
          errorMessages.add(response.message);
        }
      });
      if (successMessages.size > 0) {
        await filesLoader();
        setSelectedFileIndices([]);
        setDeleteModalVisible(false);
        openNotification([...successMessages].join(", "));
      }
      if (errorMessages.size > 0) {
        message.error([...errorMessages].join(", "));
      }
    } catch (error) {
      console.log("error", error);
      message.error("An error occurred while deleting files.");
    } finally {
      setIsLoading(false);
    }
  };

  const showUploadModal = () => {
    setIsModalVisible(true);
  };

  const handleCheckboxChange = (index) => {
    setSelectedFileIndices((prevIndices) => {
      if (prevIndices.includes(index)) {
        return prevIndices.filter((i) => i !== index);
      } else {
        return [...prevIndices, index];
      }
    });
  };

  const handleOk = async () => {

  }

  const uploadHandel = async () => {
    try {
      setIsLoading(true);
      const uniqueFiles = Array.from(new Set(fileList.map(file => file.originFileObj)));
      const base64Files = await Promise.all(uniqueFiles.map(file => getBase64(file)));
      const newUploadedFiles = [...uploadedFiles, ...base64Files];
      setUploadedFiles(newUploadedFiles);
      
      const formData = new FormData();
      uniqueFiles.forEach((file, index) => {
        formData.append(`multiImage[${index}]`, file);
      });
      formData.append('user_id', user?.id);
      
      const response = await post('creator/content-vault', formData);
      if (response) {
        setIsModalVisible(false);
        setFilesToRender([]);
        setFileList([]);
        filesLoader(pagination.current, pagination.pageSize);
        openNotification('Your file has been uploaded successfully.');
      }
    } catch (error) {
      if (error.response && error.response.data) {
        message.error(error.response.data.message);
      } else {
        message.error(error.message);
      }
    } finally {
      setIsLoading(false);
    }
  };

  const getBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = error => reject(error);
    });
  };

  const handleCancel = () => {
    setFileList([]);
    setIsModalVisible(false);
  };

  const getFileIcon = (file) => {
    if (file.type?.startsWith('image/')) {
      return <Image src={ImageUploadIcon} preview={false} />;
    } else if (file.type?.startsWith('video/')) {
      return  <Image src={VideoUploadIcon} preview={false} />;
    }
    return <Image src={ImageUploadIcon} preview={false} />;
  };

  const formatFileSize = (bytes) => {
    if (bytes < 1024 * 1024) {
      return `${(bytes / 1024).toFixed(1)} KB`;
    }
    return `${(bytes / (1024 * 1024)).toFixed(1)} MB`;
  };

  const simulateProgress = (uid) => {
    let percent = 0;
    const interval = setInterval(() => {
      if (percent >= 100) {
        clearInterval(interval);
        setProgressMap(prev => ({
          ...prev,
          [uid]: 100
        }));
      } else {
        percent += 5;
        setProgressMap(prev => ({
          ...prev,
          [uid]: percent
        }));
      }
    }, 100);
  };

  const uploadProps = {
    fileList,
    onChange: async({ fileList: newFileList }) => {
      // Start progress simulation for new files
      newFileList.forEach(file => {
        if (!progressMap[file.uid]) {
          simulateProgress(file.uid);
        }
      });
      
      setFileList(newFileList);
      const renderedFiles = await renderFiles(newFileList);
      setFilesToRender(renderedFiles);
    },
    multiple: true,
    beforeUpload: (file) => {
      // Initialize progress for the new file
      setProgressMap(prev => ({
        ...prev,
        [file.uid]: 0
      }));
      return false; // Prevent actual upload
    },
    onDrop(e) {
      console.log('Dropped files', e.dataTransfer.files);
    },
    showUploadList: false,
  };

  const updateFileProgress = (uid, percent, status) => {
    setFileList(prevList =>
      prevList.map(file =>
        file.uid === uid
          ? { ...file, progress: percent, status }
          : file
      )
    );
  };

  const handleDelete = (uid) => {
    setFileList(prev => prev.filter(file => file.uid !== uid));
    setProgressMap(prev => {
      const newMap = { ...prev };
      delete newMap[uid];
      return newMap;
    });
  };

  const processFile = (file) => {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve(file);
      }, 1000);
    });
  };

  const renderFiles = async (fileList) => {
    const renderedFiles = await Promise.all(
      fileList.map(async (file) => {
        const processedFile = await processFile(file);
        return { ...file, processedFile };
      })
    );
    return renderedFiles;
  };

  const handleClose = () => {
    setDeleteModalVisible(false);
  };

  const tabsHandller=async (key)=>{
    setFilesList([]);
    setPagination({ current: 1, pageSize: 12, total: 0 });
    setFileType(key);
  }
  
  useEffect(() => {
    const init = async () => {
      await filesLoader(pagination.current, pagination.pageSize);
    };
  
    init();
  }, [fileType, pagination.current, pagination.pageSize]);

  const changePagination = (page, pageSize) => {
    setPagination(prev => ({
      ...prev,
      current: page,
      pageSize: pageSize,
    }));
  }

  const handleDeleteSelected = () => {
    setDeleteModalVisible(true);
  };

  const handleShare = () => {
    openNotification('Selected image shared successfully');
  };
  const handleUnshare = () => {
    openNotification('Selected image unshared successfully');
  };
  const cancelSelected=()=>{
    setSelectedFileIndices([]);
    setSelectionSelect(false);
  }
  const handleTabChange = async (key) => {
    setActiveTab(key);
    setSelectionSelect(false);
    setSelectedFileIndices([]);
    await tabsHandller(key);  // Calls tabsHandller to reset files, pagination, and file type
  };

  const handleSelectionSelect = () =>{
    setSelectionSelect(true);
  }

  return (
    <Layout>

      <Flex className="content-head-area" vertical style={{ borderBottom: '1px solid #EAECF0', padding: '25px 25px 15px 25px' }}>
        <Flex horizontal justify="space-between" align="center">
          <Flex vertical>
            <Title level={2}>Gallery</Title>
            {filesList.length > 0 &&
              <Text style={{ fontSize: '16px' }}>Add, remove, or share content</Text>
            }
          </Flex>
          <Flex gap={15} horizontal>

            <Tabs
              className="content-vault-tabs"
              activeKey={activeTab}
              onChange={handleTabChange}
            >
              <Tabs.TabPane tab="Photos" key="Photos" />
              <Tabs.TabPane tab="Videos" key="Videos" />
            </Tabs>

            <Space>
              <Button type="primary" size="large" icon={<UploadOutlined />} onClick={showUploadModal} style={{ fontWeight: 600 }}>Upload</Button>
            </Space>
          </Flex>
        </Flex>

        {selectionSelect == true ? (
          <Flex horizontal="true" justify="end" style={{ marginTop: 15, marginBottom: 0 }}>
            <Space>
              <Button type="danger" style={{ padding: '0 10px' }} onClick={handleDeleteSelected}><DeleteOutlined style={{ color: '#D92D20', fontSize: '18px' }} /></Button>
              <Text type="primary" style={{ fontWeight: 600 }}>{selectedFileIndices.length} selected</Text>
              <Text style={{ fontWeight: 600, color: '#7f56d9',cursor: 'pointer' }} onClick={cancelSelected}>Cancel</Text>
            </Space>
          </Flex>
        ) : (
          <Flex horizontal="true" justify="end" style={{ marginTop: 15, marginBottom: 0 }}>
            <Space>
              <Button onClick={handleSelectionSelect} type="text" style={{ fontWeight: 600, color: '#7f56d9', padding: 0, height: 'auto', backgroundColor: 'transparent' }}>Select</Button>
            </Space>
          </Flex>
        )}

      </Flex>

      <div className="content-vault-main" style={{ padding: '0px 20px', overflow: 'hidden' }}>
        <Spin spinning={isLoading}>

          {selectedFileIndices.length > 0  && (
            <Flex horizontal="true" justify="end">
              <Space>
                
                {/* <Button type="default" size="large" onClick={handleShare}>Share</Button>
                <Button type="default" size="large" onClick={handleUnshare}>Unshare</Button>
                <Button type="primary" size="large" icon={<PlusOutlined />} onClick={showUploadModal} style={{ fontWeight: 600 }}>New</Button> */}
              </Space>
            </Flex>
          )}
          {/*<Tabs className="content-vault-tabs" defaultActiveKey="Photos" onChange={tabsHandller}>
            <TabPane tab="Photos" key="Photos">
                <Phototab selectedFileIndices={selectedFileIndices} showUploadModal={showUploadModal} uploadedFiles={filesList} handleCheckboxChange={handleCheckboxChange}/>
                <div style={{ textAlign: "center", margin: "25px 0 10px 0" }}>
                {pagination.total > 0 &&
                    (<Pagination
                      onChange={changePagination}
                      current={pagination.current}
                      pageSize={pagination.pageSize}
                      total={pagination.total}
                      showSizeChanger
                      onShowSizeChange={changePagination}
                    />)}
                </div>
            </TabPane>
            <TabPane tab="Videos" key="Videos">
              <Videotab selectedFileIndices={selectedFileIndices} showUploadModal={showUploadModal} uploadedFiles={filesList} handleCheckboxChange={handleCheckboxChange}/>
              <div style={{ textAlign: "center", margin: "25px 0 10px 0" }}>
              {pagination.total > 0 &&
                    (<Pagination
                      onChange={changePagination}
                      current={pagination.current}
                      pageSize={pagination.pageSize}
                      total={pagination.total}
                      showSizeChanger
                      onShowSizeChange={changePagination}
                    />)}
              </div>
            </TabPane>
          </Tabs>*/}

          {activeTab === 'Photos' && (
            <div className="tab-content">
              <Phototab
                selectionSelect={selectionSelect}
                selectedFileIndices={selectedFileIndices}
                showUploadModal={showUploadModal}
                uploadedFiles={filesList}
                handleCheckboxChange={handleCheckboxChange}
              />
              <div style={{ textAlign: "center", margin: "25px 0 10px 0" }}>
                {pagination.total > 0 && (
                  <Pagination
                    onChange={changePagination}
                    current={pagination.current}
                    pageSize={pagination.pageSize}
                    total={pagination.total}
                    showSizeChanger
                    onShowSizeChange={changePagination}
                  />
                )}
              </div>
            </div>
          )}

          {activeTab === 'Videos' && (
            <div className="tab-content">
              <Videotab
                selectionSelect={selectionSelect}
                selectedFileIndices={selectedFileIndices}
                showUploadModal={showUploadModal}
                uploadedFiles={filesList}
                handleCheckboxChange={handleCheckboxChange}
              />
              <div style={{ textAlign: "center", margin: "25px 0 10px 0" }}>
                {pagination.total > 0 && (
                  <Pagination
                    onChange={changePagination}
                    current={pagination.current}
                    pageSize={pagination.pageSize}
                    total={pagination.total}
                    showSizeChanger
                    onShowSizeChange={changePagination}
                  />
                )}
              </div>
            </div>
          )}

        </Spin>
      </div>

      <Modal keyboard={false} maskClosable={false} className="media-upload" visible={isModalVisible} onOk={uploadHandel} onCancel={handleCancel} width={480} footer={[
        <Button key="cancel" size="large" onClick={handleCancel} style={{ fontWeight: 600 }}>Cancel</Button>,
        <Button key="add" size="large" 
        type="primary" 
        onClick={uploadHandel} 
        disabled={!filesToRender.length || fileList.length===0} 
        style={{ fontWeight: 600 }}
        >Add files </Button>,
      ]}>
        <Spin spinning={isLoading}>
          <Title level={4}>Vault Media Upload</Title>
          <Text style={{ marginBottom: '20px', display: 'block' }}>Vault stores all of your photos, videos, and content in a secure place.</Text>

          <Dragger {...uploadProps} style={{ backgroundColor: '#ffffff', border: '1px solid #EAECF0' }}>
            <p className="ant-upload-drag-icon" style={{ marginBottom: 10 }}>
              <Image src={UploadIcon} preview={false} size={40} />
            </p>
            <p className="ant-upload-text"><span style={{ fontWeight: 600, color: '#6941C6', fontSize: '14px' }}>Click to upload</span> or drag and drop</p>
            <p className="ant-upload-hint" style={{ fontSize: '12px' }}>SVG, PNG, JPG or GIF (max. 800×400px)</p>
          </Dragger>

          <Flex gap={10} vertical style={{ marginTop: 10 }}>
            {fileList.map(file => (
              <Card className="image-upload-list" style={{ width: '100%' }} key={file.uid}>
                <Flex gap={14} style={{ width: '100%' }}>
                  {getFileIcon(file)}
                  <Flex vertical style={{ flex: 1 }}>
                    <Flex>
                      <Flex vertical style={{ flex: 1 }}>
                        <span className="font-medium text-sm">{file.name}</span>
                        <span className="text-sm text-gray-500">
                          {formatFileSize(file.size)}
                        </span>
                      </Flex>
                      <Flex vertical>
                        <Button
                          type="text"
                          icon={<DeleteOutlined className="text-gray-500" />}
                          onClick={() => handleDelete(file.uid)}
                          className="flex items-center justify-center hover:bg-gray-100"
                        />
                      </Flex>
                    </Flex>
                    <Flex gap={10}>
                     <Progress 
                        percent={progressMap[file.uid] || 0} 
                        size="small" 
                        showInfo={false}
                        strokeColor="#8B5CF6"
                        className="mb-0"
                      />
                      <Text style={{ fontWeight: 500, width: '45px' }}>{progressMap[file.uid] || 0}%</Text>
                    </Flex>
                  </Flex>
                </Flex>
              </Card>
            ))}
          </Flex>

        </Spin>
      </Modal>

      <Modal className="media-upload" visible={isDeleteModalVisible} onOk={handleOk} onCancel={handleClose} width={400} footer={[
        <Button key="cancel" size="large" onClick={handleClose} style={{ fontWeight: 600 }}>Cancel</Button>,
        <Button key="delete" size="large" type="primary" danger onClick={onConfrimDelete} style={{ fontWeight: 600 }}>Delete</Button>,
      ]}>
        <Image
          width={48}
          src={DeleteIcon}
          preview={false}
          style={{ marginTop: '10px', marginBottom: '10px' }}
        />
        <Title level={4}>Remove selected content?</Title>
        <Text style={{ marginBottom: '20px', display: 'block' }}>Are you sure you want to remove selected item(s) from the vault? This action cannot be undone.</Text>
      </Modal>
    </Layout>
  );
}
export default ContentVault;