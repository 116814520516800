import React, { useState,useContext,useEffect } from 'react';
import { Row, Image, Col, Switch, Button, Flex, Form, Input, Divider, Typography,Spin,message  } from 'antd';
import { CloseCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { UserContext } from '../../contexts/UserContext';
import EqualIcon from '../../images/equal-icon.svg';
import { convertStringsToBooleans,openNotification } from '../../utils/utils';
import { put } from '../../utils/api';
import RequestList from '../GlobalComponents/RequestList.js';
import useScreenSize from '../../utils/useScreenSize';

const { Title, Text } = Typography;

const PrivateRequests = ({settinginfo}) => {

    const { isMobile } = useScreenSize();
    const [form] = Form.useForm();
    settinginfo=convertStringsToBooleans(settinginfo);
    
    const [loading, setLoading]=useState(false);
    const {user}  = useContext(UserContext);
    const [privateRequests, SetPrivateRequests] = useState(false);

    const [showRequests, setShowRequests] = useState(false);
    const [showDelivery, setShowDelivery] = useState(false);
    const [maxNumbers, setMaxNumbers] = useState(5);
    const [price, setPrice] = useState(45);
    const [expressDelivery,SetExpressDelivery]=useState(56.25);

    const onSubmit =async (values) => {
      setLoading(true);
      try {
        const response = await put(`creator/save-settings/${user?.id}`,values);
        if (response && response.status === 200) {
          openNotification(response.message);
        } else {
          message.error(response.message);
        }

      }catch (error) {
        if (error.response && error.response.data) {
          message.error(error.response.data.message);
        } else {
          message.error(error.message);
        }
      }finally {
        setLoading(false);
      }
    };

    const onChange = async (checked) => {
      await onSubmit({is_private_requests:checked});
      SetPrivateRequests(checked);
    };

    const onChangeOnOFF = (checked) => {
      setShowRequests(checked);
    }

    const onChangeOnOFFCustom = (checked) => {
      setShowDelivery(checked)
    }

    useEffect(()=>{
      if(settinginfo?.is_private_requests){
        SetPrivateRequests(true);
      }
    },[settinginfo]);

    const onValuesChange = (changedValues, allValues) => {
      if (changedValues.max_numbers) {
        setMaxNumbers(changedValues.max_numbers);
      }
      if (changedValues.price) {
        setPrice(changedValues.price);
      }
    };

    const onadditional_feeChange=(changedValues)=>{
      const crtValue= parseFloat(changedValues?.target?.value || 0);
      const additionalFeeAmount = parseFloat(price) * crtValue/100
      const totalValue = price + additionalFeeAmount;
      SetExpressDelivery(totalValue);
    }
  
    return(
        <div>
          <Spin spinning={loading}>
          <Form form={form} onFinish={onSubmit} initialValues={settinginfo} onValuesChange={onValuesChange} className="setting-form">
          <Flex className="head-inner" vertical justify="space-between">
            <Flex vertical style={{ marginBottom: 15 }}>
                <Title level={4} style={{ marginBottom: '8px', fontSize: '18px' }}>Private Requests</Title>
                <Text>Allows fans to request custom messages to you. You can approve or decline them and set prices in “Private request”</Text> 
            </Flex>

            <Flex gap={18} align="center" style={{ marginBottom: 10 }}>
              <Form.Item
                name="is_private_requests"
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
                style={{ marginBottom: 0 }}
                rules={[{ required: false }]}
              >
                <Switch onChange={onChange} style={{ marginTop: 2 }} />
              </Form.Item>
              <Flex vertical>
                <Text style={{ fontSize: '16px', fontWeight: 500, color: '#344054' }}>Available for request</Text>
              </Flex>
            </Flex>
          </Flex>

          <Divider style={{ marginTop: 10 }} />

            <Row>
              <Col xs={{ span: 24 }} md={{ span: 6 }} lg={{ span: 6 }}>
                  <Flex vertical>
                    <Text level={4} style={{ fontWeight: 600 }}>Request Pricing</Text>
                    <Text>Set a price for fans to request personalized video or content.</Text> 
                  </Flex>
              </Col>
              <Col xs={{ span: 24 }} md={{ span: 1 }} lg={{ span: 1 }}></Col>
              <Col xs={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }} style={{ opacity: privateRequests != true && '0.3', pointerEvents: privateRequests != true && 'none' }}>
                  <Row gutter={20}>
                    <Col xs={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }}>
                      <Form.Item
                        name="price"
                        label="Price"
                        labelCol={{ span: 24 }}
                        wrapperCol={{ span: 24 }}
                        style={{ marginBottom: 0 }}
                        className="custom-form-item"
                      >
                        <Input addonBefore={<Text>$</Text>} defaultValue="45" placeholder="45" />
                      </Form.Item> 
                    </Col>
                  </Row>

              </Col>
            </Row>

            <Divider />

            <Row>
              <Col xs={{ span: 24 }} md={{ span: 6 }} lg={{ span: 6 }}>
                  <Flex vertical>
                    <Text level={4} style={{ fontWeight: 600 }}>Limit requests</Text>
                    <Text>This is the maximum number of requests you’ll see a time. When you reach your limit you’ll go unavailable. When you’re ready, you can turn requests back on.</Text> 
                  </Flex>
              </Col>
              <Col xs={{ span: 24 }} md={{ span: 1 }} lg={{ span: 1 }}></Col>
              <Col xs={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }} style={{ opacity: privateRequests != true && '0.3', pointerEvents: privateRequests != true && 'none' }}>

                  <Flex gap={18} align="center" style={{ marginBottom: 10, marginTop: isMobile ? 20 : '' }}>
                    <Form.Item
                        name="limit_requests"
                        labelCol={{ span: 24 }}
                        wrapperCol={{ span: 24 }}
                        rules={[{ required: false }]}
                        style={{ marginBottom: 0 }}
                      >
                        <Switch onChange={onChangeOnOFF} style={{ marginTop: 2 }} />
                    </Form.Item>
                    <Flex vertical>
                      <Text style={{ fontSize: '16px', fontWeight: 500, color: '#344054' }}>Limit requests</Text>
                    </Flex>
                  </Flex>

                  {showRequests == true &&
                    <Row gutter={20}>
                      <Col xs={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }}>
                        <Form.Item
                          name="max_numbers"
                          label="Max numbers of requests"
                          labelCol={{ span: 24 }}
                          wrapperCol={{ span: 24 }}
                          style={{ marginBottom: 0 }}
                          className="custom-form-item"
                        >
                          <Input defaultValue="5" placeholder="5" />
                        </Form.Item> 
                      </Col>
                    </Row>
                  }

              </Col>
            </Row>

            <Divider />

            <Row>
              <Col xs={{ span: 24 }} md={{ span: 6 }} lg={{ span: 6 }}>
                  <Flex vertical>
                    <Text level={4} style={{ fontWeight: 600 }}>Express Delivery</Text>
                    <Text>Boost earnings with 24 hour video delivery options:</Text> 
                  </Flex>
              </Col>
              <Col xs={{ span: 24 }} md={{ span: 1 }} lg={{ span: 1 }}></Col>
              <Col xs={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }} style={{ opacity: privateRequests != true && '0.3', pointerEvents: privateRequests != true && 'none' }}>
                  <Flex gap={18} align="center" style={{ marginBottom: 10, marginTop: isMobile ? 20 : '' }}>
                    <Form.Item
                        name="is_custom_request"
                        labelCol={{ span: 24 }}
                        wrapperCol={{ span: 24 }}
                        rules={[{ required: false }]}
                        style={{ marginBottom: 0 }}
                      >
                        <Switch onChange={onChangeOnOFFCustom} style={{ marginTop: 2 }} />
                    </Form.Item>
                    <Flex vertical>
                      <Text style={{ fontSize: '16px', fontWeight: 500, color: '#344054' }}>Allow express delivery</Text>
                    </Flex>
                  </Flex>

                  {showDelivery == true &&
                    <Row gutter={20}>
                      <Col xs={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }}>
                        <Form.Item
                          name="additional_fee"
                          label="Additional fee to charge"
                          labelCol={{ span: 24 }}
                          wrapperCol={{ span: 24 }}
                          className="custom-form-item"
                          onChange={onadditional_feeChange}
                        >
                          <Input addonBefore={<Text>%</Text>} defaultValue="25" placeholder="25" />
                        </Form.Item> 
                        <Text>Price with 24hr request: {`$${expressDelivery}`}</Text>
                      </Col>
                    </Row>
                  }

              </Col>
            </Row>

            <Divider />

            <Row>
              <Col xs={{ span: 24 }} md={{ span: 6 }} lg={{ span: 6 }}>
                  <Flex vertical style={{ marginBottom: isMobile ? 20 : '' }}>
                    <Text level={4} style={{ fontWeight: 600 }}>Request List</Text>
                    {/* <Text>Set a list of custom requests that your audience can make from your profile. The maximum amount is &#123;${price}}.</Text>  */}
                    <Text>Set a list of custom requests that your audience can make from your profile. The maximum amount is $3.</Text> 
                  </Flex>
              </Col>
              <Col xs={{ span: 24 }} md={{ span: 1 }} lg={{ span: 1 }}></Col>
              <Col xs={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }} style={{ opacity: privateRequests != true && '0.3', pointerEvents: privateRequests != true && 'none' }}>

                {/*<Form.List name="private_request_list">
                    {(fields, { add, remove }) => (
                      <>
                        {fields.map(({ key, name, fieldKey, ...restField }) => (
                          <Flex
                            key={key}
                            vertical
                            className="request-list-row"
                            style={{ marginBottom: 10, width: '100%', position: 'relative' }}
                            align="baseline"
                          >
                            <Flex vertical style={{ width: '100%' }}>
                                <Form.Item
                                  {...restField}
                                  name={[name, 'title']}
                                  fieldKey={[fieldKey, 'title']}
                                  wrapperCol={{ span: 24 }}
                                  rules={[{ required: true, message: 'Please input a title!' }]}
                                  className="custom-form-item"
                                  style={{ marginBottom: 0, width: '100%' }}
                                >
                                  <Input placeholder="Example: Sing me a song" className="custom-form-item" />
                                </Form.Item>
                                {fields.length > 0 && (
                                  <CloseCircleOutlined
                                    style={{ color: '#D92D20', position: 'absolute', right: 10, top: 12, fontSize: 20 }}
                                    onClick={() => remove(name)}
                                  />
                                )}
                            </Flex>
                          </Flex>
                        ))}
                        <Form.Item style={{ marginLeft: 35 }}>
                          <Button type="link" onClick={() => add()} icon={<PlusOutlined />} style={{ fontWeight: 600, padding: 0 }}>
                            Add another
                          </Button>
                        </Form.Item>
                      </>
                    )}
                  </Form.List>*/}

                  <RequestList />

                </Col>
              </Row>

              <Divider />

              <Flex
                  horizotanl
                  justify="end"
                  gap="middle"
                  style={{
                    width: '100%',
                    marginTop: '20px'
                  }}
                >
                <Button style={{ fontWeight: 600 }} size="large">
                  Cancel
                </Button>
                  <Button htmlType='submit' type="primary" size="large" style={{ fontWeight: 600 }}>
                    Save
                  </Button>
              </Flex>
          
          </Form>
          </Spin>
        
        </div>
    );
};

export default PrivateRequests;
