import {Avatar,Image,notification} from "antd";
import {  UserOutlined} from '@ant-design/icons';
import CheckCircle from '../images/check-with-circles.svg';
import { get } from "./api";
const getStatusText = (status) => {
    const statusMap = {
      0: 'Pending',
      1: 'Active',
    };
    return statusMap[status] || 'Unknown';
  };
  function removeExtraSpaces(str) {
    if (typeof str !== 'string') {
      return str;
    }
    return str.trim().replace(/\s+/g, ' ');
  }

const getInitials = (name) => {
  name=removeExtraSpaces(name);
    if (!name) return '';
    const words = name.split(' ');
    if (words.length === 1) {
      return name.substring(0, 2).toUpperCase();
    }
    return (words[0][0] + words[1][0]).toUpperCase();
  };
  const extractImageIds = (fileList) => {
    const ids = fileList.map((file) => file.response?.image?.id);
    return ids.filter((id) => id !== undefined); // Remove undefined values
  };

  const getImageUrl=(path)=>{
    return `${process.env.REACT_APP_API_BASE_URL}/${path}`;
  }
  const imageUrl=async(id)=>{
    if(!id){return false}
    const response = await get(`auth/image/${id}`);
    if(response){
      return getImageUrl(response);
    }
    return false
  }

  const convertStringsToBooleans =(obj) => {
    for (const key in obj) {
        if (obj[key] === "1") {
            obj[key] = true;
        } else if (obj[key] === "0") {
            obj[key] = false;
        }
       
    }
    return obj;
};

const calculateAge=(dateOfBirth)=>{
  const dob = new Date(dateOfBirth);
  const today = new Date();
  let age = today.getFullYear() - dob.getFullYear();
  const monthDiff = today.getMonth() - dob.getMonth();

  if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < dob.getDate())) {
      age--;
  }

  return age;
}

const getAvtar=async(imageId)=>{
  if(imageId){
    const rsp=await imageUrl(imageId);
    if(rsp){
      <Image key={imageId} width={40} src={rsp} preview={false} />
    }
  }
  return (<Avatar size={48} style={{ backgroundColor: '#98A2B3' }}><UserOutlined /></Avatar>);
}

const cleanPhoneNumber=(phoneNumber)=> {
  if(!phoneNumber){
    return null;
  }
  // Remove all non-numeric characters except for the plus sign
  let cleaned = phoneNumber.replace(/[^\d+]/g, '');

  // Remove the leading +1 if it exists
  if (cleaned.startsWith('+1')) {
      cleaned = cleaned.substring(2);
  }

  return cleaned;
}


function formatNumber(num) {
  if (num >= 1000) {
    const suffixes = ['', 'k', 'M', 'B', 'T'];
    const suffixNum = Math.floor(Math.log10(num) / 3);
    let fnum = num / Math.pow(10, suffixNum * 3);

    // Add comma if the number is between 10 and 100
    if (fnum >= 10 && fnum < 100) {
      fnum = fnum.toFixed(1);
    } else {
      fnum = fnum.toFixed(0);
    }

    return `${fnum}${suffixes[suffixNum]}`;
  } else {
    return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ','); // Add commas for numbers less than 1000
  }
}

function formatPrice(amount) {
  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  }).format(amount);
}


const processHtmlContent = (htmlContent) => {
  try{
    const tempDiv = document.createElement('div');
    tempDiv.innerHTML = htmlContent;
   const finaldata= tempDiv.textContent || tempDiv.innerText || '';
    return (finaldata)?finaldata.substring(0, 20):'Attachment';
  }catch(error){
    return '';
  }
};

function removeTags(str) {
  if ((str === null) || (str === '')){
    return false;
  }

  if(str=='<p class="custom-image-class"><br></p>' || str=='<p><br></p>'){
    return false;
  }else{
    return true;
  }
      
}

const openNotification = (message) => {
  notification.success({
    message: message,
    placement: 'topRight',
    duration: 3,
    icon: <Image src={CheckCircle} preview={false} /> 

  });
};


  export {getStatusText,getInitials,extractImageIds,getImageUrl,imageUrl,convertStringsToBooleans,calculateAge,getAvtar,cleanPhoneNumber,formatNumber,formatPrice,processHtmlContent,removeTags,openNotification };