import React, { useState, useRef, useEffect } from 'react';
import { Flex, Input, Button, Image, Upload, Dropdown, Typography, Form, Tabs } from 'antd';
import { DeleteOutlined, ArrowUpOutlined, CloseCircleOutlined, PlusOutlined, SmileOutlined, PauseOutlined,CaretRightOutlined } from '@ant-design/icons';
import ContentVaultUpload from './ContentVaultUpload';
import ReactPlayer from 'react-player';
import data from '@emoji-mart/data'
import Picker from '@emoji-mart/react';
import Gifs from './Gifs'; // Import Gifs component
import AudioRecorder from './AudioRecorder';
import CameraIcon from '../../images/camera-icon.svg';
import PictureIcon from '../../images/picture-icon.svg';
import LockIcon from '../../images/lock-icon2.svg';
import UploadIcon from '../../images/share-icon.svg';
import CurrencyIcon from '../../images/currency-icon.svg';

const { TextArea } = Input;
const { Text } = Typography;


const ChatInputNewMessage = ({ onInputChange, setSendButton, setLoading, onImagesChange, isShow = true,setChatImages,sendMessageHandler, basePrice,setSelectedPrice,setPriceButton }) => {
  const [message, setMessage] = useState('');
  const [fileList, setFileList] = useState([]);
  const [isContentVaultVisible, setContentVaultVisible] = useState(false);
  const [selectedVaultImages, setSelectedVaultImages] = useState([]);
  const [isEmojiPickerVisible, setEmojiPickerVisible] = useState(false);
  const [gifsVisible, setGifsVisible] = useState(false); // GIF state
  const [searchTerm, setSearchTerm] = useState('');
  const [audioURL, setAudioURL] = useState('');
  const [visualData, setVisualData] = useState([]);
  const [isRecording, setIsRecording] = useState(false);
  const audioRef = useRef(null);
  const analyserRef = useRef(null);
  const audioContextRef = useRef(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const animationFrameRef = useRef(null);
  const VISUALIZATION_MULTIPLIER = 2.5;
  const [isPriceModalVisible, setIsPriceModalVisible] = useState(false);
  const [priceInput, setPriceInput] = useState('0.99');

  useEffect(()=>{
    setSelectedPrice(priceInput);
  },[priceInput]);
  useEffect(()=>{
    setPriceButton(isPriceModalVisible);
  },[isPriceModalVisible]);

  const textAreaRef = useRef(null);
  const emojiPickerRef = useRef(null);
  const dropdownRef = useRef(null);

  const stopPropagation = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  useEffect(()=>{
    setChatImages(fileList);
  },[fileList]);
  
  const handleEmojiSelect = (emojiData) => {
    console.log(emojiData, 'emojiData')
    const cursorPosition = textAreaRef.current?.resizableTextArea.textArea.selectionStart || 0;
    const newMessage = 
      message.slice(0, cursorPosition) + 
      emojiData.native + 
      message.slice(cursorPosition);
    setMessage(newMessage);
    if (onInputChange) {
      onInputChange(newMessage);
    }
    setEmojiPickerVisible(false);
    setTimeout(() => {
      textAreaRef.current?.focus();
    }, 0);
  };

  const insertPlaceholder = (placeholder) => {
    const cursorPosition = textAreaRef.current?.resizableTextArea?.textArea.selectionStart || 0;
    const newMessage = 
      message.slice(0, cursorPosition) + 
      `{${placeholder}}` + 
      message.slice(cursorPosition);
      
    setMessage(newMessage);
    if (onInputChange) {
      onInputChange(newMessage);
    }
    setTimeout(() => {
      const newCursorPosition = cursorPosition + placeholder.length + 2;
      textAreaRef.current?.resizableTextArea?.textArea.setSelectionRange(newCursorPosition, newCursorPosition);
      textAreaRef.current?.focus();
    }, 0);
  };

  const handleTabChange = (key) => {
    if (key === 'emoji') {
      setGifsVisible(false);
    } else if (key === 'gif') {
      setGifsVisible(true);
    }
  };

  const handleGifSelect = (gif) => {
    const newGifFile = {
      uid: `gif-${Date.now()}-${Math.random()}`,
      id: Date.now(),
      file: gif || null,
      type: 'gif',
      preview: gif || null,
      name: `gif_${Date.now()}.gif`
    };
    setFileList([newGifFile]);
    setEmojiPickerVisible(false);
    
    if (onImagesChange) {
      const updatedFiles = [newGifFile].map(file => ({
        id: file.uid,
        url: file.file || file.url,
        file: file.file,
        source: file.source || 'gif',
        type: file.type
      }));
      onImagesChange(updatedFiles);
    }
  };

  const handleImageUpdate = (newImage, source = 'upload') => {
    const processImage = (image) => ({
      uid: image.uid || `${source}-${Date.now()}-${Math.random()}`,
      name: image.name || `${source}-image`,
      status: 'done',
      url: image.url || (image.originFileObj ? URL.createObjectURL(image.originFileObj) : image.thumbUrl),
      thumbUrl: image.thumbUrl || image.url,
      file: image.originFileObj,
      source: source,
      type: 'image'
    });
    
    const processedImage = processImage(newImage);
    setFileList([processedImage]);
    
    if (onImagesChange) {
      const updatedFiles = [processedImage].map(file => ({
        id: file.uid,
        url: file.url,
        file: file.file,
        source: file.source,
        type: file.type
      }));
      onImagesChange(updatedFiles);
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault(); // Prevent new line
      if (message.trim()) {
        sendMessageHandler();
      }
    }
  };

  const handleMessageChange = (e) => {
    const newMessage = e.target.value;
      setMessage(newMessage);
      if (onInputChange) {
        onInputChange(newMessage);
      }
  };

  const handleUploadChange = ({ fileList: newFileList }) => {
    const latestFile = newFileList[newFileList.length - 1];
    if (latestFile) {
      handleImageUpdate(latestFile, 'upload');
    }
  };

  const handleContentVaultData = (selectedImages) => {
    setSelectedVaultImages(selectedImages);
  };

  const handleAddContentVault = () => {
    if (selectedVaultImages.length > 0) {
      console.log("formattedImage",selectedVaultImages);
      const image = selectedVaultImages[0];
      const formattedImage = {
        uid: `vault-${Date.now()}-${Math.random()}`,
        name: image || 'vault-image',
        url: `${process.env.REACT_APP_API_BASE_URL}/${image}`,
        thumbUrl: image
      };

      
      handleImageUpdate(formattedImage, 'vault');
    }    
    setContentVaultVisible(false);
  };

   const handleRemoveImage = (uid) => {
    const updatedFileList = fileList.filter(file => file.uid !== uid);
    setFileList(updatedFileList);
    
    if (onImagesChange) {
      const processedImages = updatedFileList.map(file => ({
        id: file.uid,
        url: file.type === 'gif' ? file.file : file.url,
        file: file.file,
        source: file.source || (file.type === 'gif' ? 'gif' : 'upload'),
        type: file.type
      }));
      onImagesChange(processedImages);
    }
  };

  const handlePriceClick = () => {
    setIsPriceModalVisible(true);
    if(basePrice){
      setPriceInput(basePrice);
    }else{
      setPriceInput('0.99');
    }
  }
  const handlePriceInputChange = (e) => {
    setPriceInput(e.target.value);
  };
  const handleClearPrice = () => {
    setPriceInput(null);
    setIsPriceModalVisible(false);
  };

  // UI Components
  const uploadButton = (
    <Button 
      type="text"
      icon={<Image src={UploadIcon} preview={false} style={{ opacity: '0.7' }} />} 
      style={{ padding: 0, background: 'transparent', height: 'auto' }}
    >
      Upload
    </Button>
  );

  const uploadPhoto=( <Button 
    type="text" 
    icon={<Image src={CameraIcon} preview={false} />} 
    style={{ padding: 0, background: 'transparent', height: 'auto' }}
  >
    Camera
  </Button>);

  const EmojiPickerWithClickPrevention = () => (
    <div 
      ref={emojiPickerRef}
      onClick={stopPropagation}
      onMouseDown={stopPropagation}
      onKeyDown={stopPropagation}
      style={{ padding: '10px' }}
    >
      <Picker
        data={data}
        onEmojiSelect={handleEmojiSelect}
        previewPosition="none"
        skinTonePosition="none"
        searchPosition="sticky"
        maxFrequentRows={2}
        perLine={8}
        theme="light"
        autoFocus={true}
      />
    </div>
  );

  const tabItems = [
    {
      key: 'emoji',
      label: 'Emoji',
      children: <EmojiPickerWithClickPrevention />
    },
    {
      key: 'gif',
      label: 'GIF',
      children: (
        <Gifs
          gifsVisible={gifsVisible}
          setGifsVisible={setGifsVisible}
          onSelectGifs={handleGifSelect}
          searchTerm={searchTerm}
        />
      )
    }
  ];

  const dropdownItems = [
    {
      label:(
        <Upload
          accept="image/*,video/*"
          multiple={false}
          showUploadList={false}
          beforeUpload={() => false}
          onChange={handleUploadChange}
          capture="camera"
        >
          {uploadPhoto}
        </Upload>
      ),
      key: '0',
    },
    {
      label: (
        <Upload
          accept="image/*,video/*"
          multiple={false}
          showUploadList={false}
          beforeUpload={() => false}
          onChange={handleUploadChange}
        >
          {uploadButton}
        </Upload>
      ),
      key: '1',
    },
    {
      label: isShow && (
        <Button 
          type="text" 
          onClick={() => setContentVaultVisible(true)} 
          icon={<Image src={PictureIcon} preview={false} />} 
          style={{ padding: 0, background: 'transparent', height: 'auto' }}
        >
          Gallery
        </Button>
      ),
      key: '2',
    },
  ];

  const placeholderItems = [
    {
      key: 'firstName',
      label: 'First Name',
      onClick: () => insertPlaceholder('First Name'),
    },
    {
      key: 'lastName',
      label: 'Last Name',
      onClick: () => insertPlaceholder('Last Name'),
    }
  ]

  const dropdownOverlay = (
    <div
      ref={dropdownRef}
      onMouseDown={(e) => {
        e.preventDefault();
        e.stopPropagation();
      }}
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
      }}
    >
      <Tabs 
        defaultActiveKey="emoji" 
        items={tabItems} 
        onChange={handleTabChange}
        style={{ background: 'white', borderRadius: '8px', boxShadow: '0 2px 8px rgba(0,0,0,0.15)' }}
      />
    </div>
  );

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setEmojiPickerVisible(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);



  const playAudio = async () => {
    if (!audioURL) return;

    if (!audioRef.current) {
      audioRef.current = new Audio(audioURL);
      
      // Set up audio context for playback visualization
      audioContextRef.current = new AudioContext();
      analyserRef.current = audioContextRef.current.createAnalyser();
      analyserRef.current.fftSize = 128;
      analyserRef.current.smoothingTimeConstant = 0.8;
      
      const source = audioContextRef.current.createMediaElementSource(audioRef.current);
      source.connect(analyserRef.current);
      analyserRef.current.connect(audioContextRef.current.destination);
    }

    audioRef.current.play();
    setIsPlaying(true);
    visualize();

    audioRef.current.onended = () => {
      setIsPlaying(false);
      cancelAnimationFrame(animationFrameRef.current);
      setVisualData(new Array(128).fill(0));
    };
  };

  const pauseAudio = () => {
    if (audioRef.current) {
      audioRef.current.pause();
      setIsPlaying(false);
      cancelAnimationFrame(animationFrameRef.current);
    }
  };

  const visualize = () => {
    if (!analyserRef.current) return;
    
    const dataArray = new Uint8Array(analyserRef.current.frequencyBinCount);
    
    const updateVisualizer = () => {
      analyserRef.current.getByteFrequencyData(dataArray);
      const normalizedData = Array.from(dataArray).map(value => 
        Math.min(255, value * VISUALIZATION_MULTIPLIER)
      );
      setVisualData(normalizedData);
      animationFrameRef.current = requestAnimationFrame(updateVisualizer);
    };
    
    updateVisualizer();
  };

  const IsstopRecording=async(audioURL)=>{
    const response = await fetch(audioURL); // Fetch the blob
    const blob = await response.blob();
    const file = new File([blob], "audio.wav", { type: 'audio/wav' });
    const source="upload";
    const processImage = (image) => ({
      uid: image.uid || `${source}-${Date.now()}-${Math.random()}`,
      name: image.name || `${source}-audio`,
      status: 'done',
      url: image.url || (image.originFileObj ? URL.createObjectURL(image.originFileObj) : image.thumbUrl),
      thumbUrl: image.thumbUrl || image.url,
      file: image,
      source: source
    });
    const updatedFileList = [processImage(file)];
   
    setFileList(updatedFileList);
  
  }

  const VideoURLChecker = (videoURL) => {
    if (!videoURL) return false;
    if (typeof videoURL !== 'string') return false;
    const SUPPORTED_VIDEO_EXTENSIONS = [
      '.mp4', 
      '.avi', 
      '.mov', 
      '.wmv', 
      '.flv', 
      '.webm', 
      '.mkv', 
      '.m4v', 
      '.mpeg', 
      '.mpg'
    ];
    try {
      const normalizedURL = String(videoURL || '');
      return SUPPORTED_VIDEO_EXTENSIONS.some(ext => 
        normalizedURL.toLowerCase().endsWith(ext)
      );
    } catch (error) {
      console.error('Error in VideoURLChecker:', error);
      return false;
    }
  };

  const handleAudioRemove = () => {
    setAudioURL('');
    setVisualData([]);
    if (audioRef.current) {
      audioRef.current.pause();
      audioRef.current.currentTime = 0;
    }
    if (audioContextRef.current) {
      audioContextRef.current.close();
      audioContextRef.current = null;
    }
    audioRef.current = null;
    analyserRef.current = null;
    if (animationFrameRef.current) {
      cancelAnimationFrame(animationFrameRef.current);
    }
    setIsPlaying(false);
  };

  return (
    <Flex vertical className="message-inner-box select-message-box">

      <Flex className="message-action-row" gap={15} align="center" justify="start" style={{ marginBottom: 0, padding: '10px 15px 10px 0px' }}>
        <Dropdown
          open={isEmojiPickerVisible}
          onOpenChange={(visible) => {
            if (!visible) {
              if (!isEmojiPickerVisible) {
                setEmojiPickerVisible(visible);
              }
            } else {
              setEmojiPickerVisible(visible);
            }
          }}
          // onOpenChange={(visible) => {
          //   setEmojiPickerVisible(visible);
          // }}
          overlay={dropdownOverlay}
          trigger={['click']}
          placement="topLeft"
          overlayClassName="emoji-gif-dropdown"
          destroyPopupOnHide={false}
        >
          <span 
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              setEmojiPickerVisible(!isEmojiPickerVisible);
            }}
            style={{ cursor: 'pointer' }}
          >
            <SmileOutlined style={{ fontSize: 18, color: '#475467' }} />
          </span>
        </Dropdown>
        <Dropdown
          menu={{ items: dropdownItems }}
          placement="topLeft"
          trigger={['click']}
        >
          <span style={{ cursor: 'pointer' }} onClick={(e) => { 
            e.preventDefault();
            setEmojiPickerVisible(false);
          }}>
            <PlusOutlined style={{ fontSize: 18, color: '#475467' }} />
          </span>
        </Dropdown>
      </Flex>

      <Flex vertical style={{ width: 'calc(100% - 66px)' }}>
        <Flex vertical style={{ width: '100%', border: '1px solid #D0D5DD', borderRadius: '8px', position: 'relative' }}>
          {fileList.length > 0 && (
            <Flex gap={10} style={{ padding: '10px 10px 10px 10px', marginTop: 0 }}>
              {fileList.filter((file) => file.name !== "audio.wav").map((file) => (
                <Flex key={file.uid} className="images-grid" style={{ position: 'relative' }}>
                 {VideoURLChecker(file?.name)?
                  <ReactPlayer width="100%" height="100px" url={file?.url} controls />
                 : <Image 
                    src={file.type === 'gif' ? file.file : file.url}
                    alt={file.name}
                    preview={false}
                    width={200}
                    style={{ borderRadius: 5, objectFit: 'cover' }}
                  />}
                  
                  <Button
                    type="text"
                    className="img-remove"
                    onClick={() => handleRemoveImage(file.uid)}
                    style={{ 
                      position: 'absolute',
                      top: '-7px',
                      right: '-7px',
                      padding: 0,
                      width: '20px',
                      height: '20px',
                      borderRadius: 20,
                      backgroundColor: '#ffffff',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      fontSize: '16px',
                      lineHeight: 1
                    }}
                  >
                    ×
                  </Button>
                </Flex>
              ))}
            </Flex>
          )}

    {/* {audioURL &&
        <Flex >
            <Button 
              icon={<DeleteOutlined />} 
              onClick={() => setAudioURL('')}
              className="bg-red-500 text-white"
            />
             <audio src={audioURL} controls className="w-64" />
        </Flex>} */}

      {visualData && visualData.length>0 && 
       <Flex gap={5} style={{ padding: 5, alignItems: 'center' }}>
       {!isRecording && <Button 
          icon={<DeleteOutlined style={{ fontSize: 18 }} />}
          type="text"
          style={{ padding: 0, background: 'transparent', width: 24 }}
          onClick={handleAudioRemove}
        />}
        <div 
          style={{ 
            height: '42px',
            background: '#F9FAFB',
            borderRadius: '30px',
            padding: '6px',
            display: 'flex',
            alignItems: 'center',
            width: 'calc(100% - 30px)',
          }}
        >
          <div style={{ 
            display: 'flex',
            alignItems: 'center',
            height: '100%',
            width: '100%',
            gap: '2px'
          }}>
            {!isRecording && <Button
              icon={isPlaying ? <PauseOutlined style={{ fontSize: 18 }} /> : <CaretRightOutlined style={{ fontSize: 18 }} />}
              onClick={isPlaying ? pauseAudio : playAudio}
              type="text"
              style={{ 
                padding: 0, 
                background: 'transparent',
                height: 'auto'
              }}
            />}

            {visualData.map((value, index) => (
              <div
                key={index}
                style={{
                  width: '3px',
                  height: `${(value / 255) * 100}%`,
                  backgroundColor: '#D0D5DD',
                  transition: 'height 0.05s',
                }}
              />
            ))}
          </div>
        </div>
        </Flex>}

        <Flex horizontal>
            <TextArea
              ref={textAreaRef}
              placeholder="Send a message..." 
              onChange={handleMessageChange} 
              onKeyDown={handleKeyDown}
              value={message}
              autoSize
              onClick={() => setEmojiPickerVisible(false)}
            />
            <Flex gap={8} style={{ padding: '4px 4px 4px 4px' }}>
              <AudioRecorder 
                setAudioURL={setAudioURL} 
                setVisualData={setVisualData} 
                setIsRecording={setIsRecording}
                isRecording={isRecording}
                IsstopRecording={IsstopRecording}
              />
              {fileList[0]?.type !== 'gif' && fileList[0]?.file.type !== 'audio/wav' && fileList.length > 0 &&<>
                {isPriceModalVisible !== true &&
                  <Button loading={setLoading} onClick={handlePriceClick} type="default" size="large" style={{ fontWeight: 600, borderRadius: '100px', padding: '0', width: 36, height: 36 }}><Image src={CurrencyIcon} preview={false} /></Button>
                }
              </>}
              {setSendButton && (message || fileList.length > 0 || (audioURL && !isRecording)) && <>
                <Button loading={setLoading} type="primary" htmlType='submit' size="large" style={{ fontWeight: 600, borderRadius: '100px', padding: '0', width: 36, height: 36 }}><ArrowUpOutlined /></Button>
              </>}
            </Flex>
          </Flex>
        </Flex>

        {isPriceModalVisible &&
          <Flex gap={10} horizotanl align="center" style={{ padding: '6px 0px 0px 0px' }}>
            <Form.Item className="custom-form-input-price" style={{ marginBottom: 0 }}>
              <Input
                placeholder=""
                addonBefore={<Text>$</Text>}
                value={priceInput}
                onChange={handlePriceInputChange}
                style={{ marginBottom: '0px' }}
              />
            </Form.Item>
            <CloseCircleOutlined style={{ color: '#D92D20', fontSize: '20px' }} onClick={handleClearPrice} />
          </Flex>
        }
      </Flex>

      <ContentVaultUpload 
        isContentVaultVisible={isContentVaultVisible} 
        setContentVaultVisible={setContentVaultVisible} 
        contentVaultData={handleContentVaultData}
        handleAddContentVault={handleAddContentVault}
      />
    </Flex>
  );
};

export default ChatInputNewMessage;
