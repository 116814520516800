import React, { useState,useEffect,useContext } from 'react';
import { Card, Button, List, Avatar, Flex, Image, Modal, Typography } from 'antd';
import { RightOutlined, StarFilled, StarOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import { UserContext } from '../../../contexts/UserContext';
import SelectMessage from '../../Communities/SelectMessage';
import AvatarImage from '../../AvatarImage';
import { formatPrice } from '../../../utils/utils';
import useScreenSize from '../../../utils/useScreenSize';
import { get, post } from '../../../utils/api';
import AllFansIcon from "../../../images/user-icon-white.svg";
import StarIcon from "../../../images/star-icon.svg";
import MessageIcon from '../../../images/message-icon.svg';

import { openNotification} from '../../../utils/utils';


const { Title, Text } = Typography;

const Engagement = ({ data }) => {
   const [position, setPosition] = useState('end');
   const navigate = useNavigate();
   const {user}  = useContext(UserContext);
   const { isMobile } = useScreenSize();

   const [isLoading, setIsLoading] = useState(false);
   const [selectedEngagement, setSelectedEngagement] = useState(null);
   const [communitiesList, setCommunitiesList] = useState([]);
   const [searchCommunity, setSearchCommunity] = useState('');
   const [communities, setCommunities] = useState([
    { id: 1, name: 'All Audience', userCount: 0, icon: AllFansIcon, bgColor: '#7F56D9' },
    { id: 2, name: 'Favorite Audience', userCount: 0, icon: StarIcon, bgColor: '#FAC515' },
  ]);

  const [selectedMessage, setSelectedMessage] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [userInfo,setUserInfo]= useState(null);
  const [chatHistory, setChatHistory] = useState([]);
  const [isHistoryLoading, setIsHistoryLoading] = useState(false); 

   const navigateToReport = () => {
    const redirectLink=user?.role==="Team"?'/team/engagement-report':'/engagement-report';
      navigate(redirectLink);
   }; 

   const loadCommunities = async (current, pageSize) => {
   
    setCommunitiesList([]);
    setIsLoading(true);

    try {
      const response = await get('creator/communities/',{s:searchCommunity});
      if(response){
        setCommunitiesList(response);
      }
    } catch (error) {
      console.log("error", error);
    } finally {
      setIsLoading(false);
    }
  }

   const loadfavoriteAudiencefanCounts=async()=>{
    try {
      const response = await get('creator/fanCounts/');
      if(response && response.status){
        const { allUsers, favoriteAudienceCount } = response.data;
        setCommunities(prevCommunities =>
          prevCommunities.map(community => {
            if (community.name === 'All Audience') {
              return { ...community, userCount: allUsers };
            } else if (community.name === 'Favorite Audience') {
              return { ...community, userCount: favoriteAudienceCount };
            }
            return community;
          })
        );
      }
    } catch (error) {
      console.log("error", error);
    }
  }

  useEffect(() => {
    const init = async () => {
      await loadCommunities();
      await loadfavoriteAudiencefanCounts();
    };
  
    init(); // Call the initialization function immediately
  }, []);

   const addRemovefavorite=async(item)=>{
    const updatedUsers = data.revenueList.map((user) =>
      user.user_id === item.user_id ? { ...user, hasFavorites: !user.hasFavorites } : user
    );
    setSelectedEngagement((prev) => ({
      ...prev,
      Users: updatedUsers,
    }));

    setIsLoading(true);
    try {
    const response=await post(`creator/addRemovefavorite`, {
        favrateId: item?.user_id
      });
      if(response.status){
        await loadfavoriteAudiencefanCounts();
       
        openNotification(response.message);
      }
    } catch (error) {
      console.error('Error fetching user info:', error);
    } finally {
      setIsLoading(false);
    }
  }


  const handleSelectMessage = async(item) => {
    setSelectedMessage(item);
    setIsModalVisible(true);
    
    try{
      const getUserInfo = await post(`creator/getUserInfo`, {
        phone: item?.phone_number
      });
      if (getUserInfo && getUserInfo.status) {
        setUserInfo(getUserInfo.data);
      } else {
        console.error('Information not available!');
      }

        const response = await post(`creator/chat`,{
          phn:item?.phone_number
        });
        if(response && response.status){
          setChatHistory(response?.data.reverse());
        }
    }catch(error){
      console.log("loading......");
    } finally {
      setIsHistoryLoading(false);
    }
  }

  const handleModalClose = () => {
    setIsModalVisible(false);
    setSelectedMessage(null);
    setIsHistoryLoading(false);
  };

  console.log(data?.revenueList, 'data.revenueListdata.revenueList')

  //  const data = [
  //   {
  //     title: 'John Doe',
  //     spent: '$1000',
  //     interactions: 30,
  //   },
  //   {
  //     title: 'Melissa Korne',
  //     spent: '$900',
  //     interactions: 26,
  //   },
  //   {
  //     title: 'Lisa Johnson',
  //     spent: '$800',
  //     interactions: 21,
  //   },
  //   {
  //     title: 'Ann Hawthorne',
  //     spent: '$450',
  //     interactions: 14,
  //   },
  //   {
  //     title: 'Timmy Turner',
  //     spent: '$300',
  //     interactions: 10,
  //   },
  // ];

   return (
    <Card className="insight-card">
      <Flex className="top-content" vertical style={ isMobile ? { padding: 20 } : { padding: 30 }}>
        <Title level={4} style={{ marginTop: 0, fontSize: '18px' }}>Engagement</Title>

        <List
          itemLayout="horizontal"
          dataSource={data?.revenueList || []}
          renderItem={(item) =>{
            const imageId = item && item?.revenueUser?.uploadedIds && item?.revenueUser?.uploadedIds.length > 0 ? item?.revenueUser?.uploadedIds[0] : null;
            return (<List.Item>
              <List.Item.Meta
                avatar={<AvatarImage size={40} imageId={imageId} title={`${item?.revenueUser?.first_name} ${item?.revenueUser?.last_name}`} />}
                title={`${item?.revenueUser?.first_name} ${item?.revenueUser?.last_name}`}
                description={`${formatPrice(item?.totalAmount || 0)} | ${item?.itemCount} interactions`}
              />
              <Flex gap={5} align="center">
               <Button
                  type="link"
                  icon={item?.hasFavorites?<StarFilled style={{ fontSize: 20,color: '#FAC515' }} />:<StarOutlined style={{ fontSize: 20 }} />}
                  style={{ color: '#475467', padding: 0 }}
                  onClick={()=>{
                    addRemovefavorite(item);
                  }}
                >
                </Button>
                <Button
                  type="link"
                  icon={<Image src={MessageIcon} preview={false} />}
                  style={{ color: '#475467', width: 40, height: 40, padding: 8, border: '1px solid #D0D5DD' }}
                  onClick={() => handleSelectMessage(item)}
                >
                </Button>
              </Flex>
            </List.Item>)
          }}
        />

      </Flex>
      <Flex vertical align="end" style={ isMobile ? { padding: '10px 20px', borderTop: '1px solid #EAECF0' } : { padding: '15px 30px', borderTop: '1px solid #EAECF0' }}>
        <Button type="text" icon={<RightOutlined />} iconPosition={position} style={{ color: '#6941C6', fontWeight: 600, padding: 0, background: 'transparent' }} onClick={navigateToReport}>View report</Button>
      </Flex>


      {selectedMessage &&
        <Modal
          open={isModalVisible}
          onCancel={handleModalClose}
          footer={null}
          width={800}
          className="select-message-modal"
        >
          <SelectMessage selectedMessage={selectedMessage} isHistoryLoading={isHistoryLoading} chatHistory={chatHistory} setChatHistory={setChatHistory} />
        </Modal>
      }

    </Card>
  );
}

export default Engagement;
