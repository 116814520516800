import React, { useState,useContext,useEffect } from 'react';
import { Row, Image, Col, Switch, Button, Flex, Form, Input, Divider, Typography,Spin,message  } from 'antd';
import { CloseCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { UserContext } from '../../contexts/UserContext';
import EqualIcon from '../../images/equal-icon.svg';
import { convertStringsToBooleans } from '../../utils/utils';
import { put } from '../../utils/api';
import useScreenSize from '../../utils/useScreenSize';
import { openNotification } from '../../utils/utils';

const { Title, Text } = Typography;

const DefaultPrice = ({settinginfo}) => {

    const [form] = Form.useForm();
    settinginfo=convertStringsToBooleans(settinginfo);
    const [loading, setLoading]=useState(false);
    const {user}  = useContext(UserContext);
    const { isMobile } = useScreenSize();

    const onSubmit =async (values) => {
     
      setLoading(true);
      try {
        const response = await put(`creator/save-settings/${user?.id}`,values);
        if (response && response.status === 200) {
          openNotification(response.message);
        } else {
          message.error(response.message);
        }

      }catch (error) {
        if (error.response && error.response.data) {
          message.error(error.response.data.message);
        } else {
          message.error(error.message);
        }
      }finally {
        setLoading(false);
      }
    };


    return(
        <div>
          <Spin spinning={loading}>
          <Form form={form} onFinish={onSubmit} initialValues={settinginfo} className="setting-form" style={{ marginTop: 10 }}>
            <Flex className="head-inner" vertical justify="space-between">
              <Flex vertical style={{ marginBottom: 15 }}>
                  <Title level={4} style={{ marginBottom: '8px', fontSize: '18px' }}>Content Pricing</Title>
                  <Text>Write out automation to your fans to make your work easier</Text> 
              </Flex>
            </Flex>

            <Divider style={{ marginTop: 10 }} />

         
              <Row>
                <Col xs={{ span: 24 }} md={{ span: 6 }} lg={{ span: 6 }}>
                    <Flex vertical style={isMobile ? { marginBottom: 20 } : ""}>
                      <Text level={4} style={{ fontWeight: 600 }}>Default Price</Text>
                    </Flex>
                </Col>
                <Col xs={{ span: 24 }} md={{ span: 1 }} lg={{ span: 1 }}></Col>
                <Col xs={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }}>

                  <Row gutter={20}>
                  <Col xs={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }}>
                      <Form.Item
                        name="basePrice"
                        label="Price"
                        labelCol={{ span: 24 }}
                        wrapperCol={{ span: 24 }}
                        className="custom-form-item"
                        rules={[
                          { 
                            validator: async (_, value) => {
                              if (!value && value !== 0) {
                                throw new Error('Price is required.');
                              }
                              if (value < 0.99) {
                                throw new Error('Price must be at least $0.99');
                              }
                              return Promise.resolve();
                            }
                          }
                        ]}
                      >
                        <Input 
                          type="number"
                          addonBefore={<Text>$</Text>} 
                          defaultValue={0.99} 
                          placeholder="00.99" 
                          step="0.01" 
                          min="0.99"
                        />
                      </Form.Item>
                      <Text>$0.99 is standard minimum starting number.</Text>
                    </Col>
                  </Row>
                </Col>
              </Row>

              <Divider />

              <Flex
                  horizotanl
                  justify="end"
                  gap="middle"
                  style={{
                    width: '100%',
                    marginTop: '20px'
                  }}
                >
                <Button style={{ fontWeight: 600 }} size="large">
                  Cancel
                </Button>
                  <Button htmlType='submit' type="primary" size="large" style={{ fontWeight: 600 }}>
                    Save
                  </Button>
              </Flex>
          
          </Form>
          </Spin>
        
        </div>
    );
};

export default DefaultPrice;
