import React from 'react';
import { Layout, Col, Row, Typography } from 'antd';

const { Title, Paragraph } = Typography;
const { Content } = Layout;

const GeneralTermsService = () => {

  return (
    <Content style={{ padding: '0 30px' }}>
      <Row style={{ justifyContent: 'center' }}>
        <Col 
          xs={{
            span: 24,
          }}
          lg={{
            span: 16,
          }}
        >
          <Title level={2} style={{ marginBottom: 20, marginTop: 0 }}>General Terms of Service</Title>
          <Paragraph>
            Welcome to [Platform Name]! These General Terms of Service ("Terms") govern your access to and use of our platform, 
            including all features, content, and services. By accessing or using [Platform Name], you agree to comply with these 
            Terms and our [Privacy Policy](link to Privacy Policy), [Content Guidelines](link to Content Guidelines), and any 
            additional policies referenced herein. Please review these Terms carefully.
          </Paragraph>

          <Title level={4}>1. Eligibility</Title>
          <Paragraph>
            To use [Platform Name], you must be at least 18 years old (or meet the minimum age requirement in your jurisdiction). 
            By accessing our platform, you represent and warrant that you meet these age requirements and have the legal capacity to enter into these Terms.
          </Paragraph>

          <Title level={4}>2. Account Registration</Title>
          <Paragraph>
            To access certain features, you may need to create an account. You agree to provide accurate, current information during registration and to keep it updated. 
            You are responsible for maintaining the confidentiality of your account credentials and for all activities under your account.
          </Paragraph>

          <Title level={4}>3. User Conduct</Title>
          <Paragraph>
            You agree to use [Platform Name] in a lawful, respectful manner. Prohibited actions include, but are not limited to:
          </Paragraph>
          <Paragraph>
            <ul>
              <li>Harassing, threatening, or abusing other users.</li>
              <li>Uploading or sharing content that infringes intellectual property rights, violates privacy rights, or is unlawful.</li>
              <li>Using bots, scrapers, or automated means to access or collect data from the platform.</li>
            </ul>
          </Paragraph>

          <Title level={4}>4. Content Ownership and Licensing</Title>
          <Paragraph>
            You retain ownership of any content you upload to [Platform Name]. By posting content, you grant us a worldwide, non-exclusive, 
            royalty-free license to use, display, distribute, and modify your content to provide our services. This license is revocable upon content removal, 
            but copies may remain in backups for a limited period.
          </Paragraph>

          <Title level={4}>5. Payments and Subscriptions</Title>
          <Paragraph>
            Certain features on [Platform Name] may require payments or subscriptions. You agree to pay all associated fees and comply with our 
            [Payment Terms and Conditions](link to Payment Terms). Fees are non-refundable except as provided in our refund policies.
          </Paragraph>

          <Title level={4}>6. Termination and Suspension</Title>
          <Paragraph>
            [Platform Name] reserves the right to suspend or terminate your account for violations of these Terms or other platform policies. 
            We may also terminate access due to inactivity, security issues, or compliance with legal requirements.
          </Paragraph>

          <Title level={4}>7. Disclaimers and Limitation of Liability</Title>
          <Paragraph>
            [Platform Name] is provided "as-is" without warranties of any kind. We do not guarantee the accuracy, reliability, or availability 
            of our platform. To the fullest extent permitted by law, [Platform Name] and its affiliates will not be liable for any damages arising 
            from your use of the platform.
          </Paragraph>

          <Title level={4}>8. Indemnification</Title>
          <Paragraph>
            You agree to indemnify and hold [Platform Name], its affiliates, and employees harmless from any claims, damages, losses, or expenses 
            arising from your use of the platform, violation of these Terms, or infringement of third-party rights.
          </Paragraph>

          <Title level={4}>9. Changes to the Terms</Title>
          <Paragraph>
            We may update these Terms from time to time to reflect changes in our services or legal requirements. We will notify you of any 
            significant changes, and continued use of the platform constitutes acceptance of the updated Terms.
          </Paragraph>

          <Title level={4}>10. Governing Law</Title>
          <Paragraph>
            These Terms are governed by the laws of [Applicable Jurisdiction]. Any disputes arising from your use of [Platform Name] 
            shall be resolved in the courts of [Applicable Jurisdiction].
          </Paragraph>

          <Title level={4}>11. Contact Us</Title>
          <Paragraph>
            For questions or concerns regarding these Terms, please contact us at [Contact Information].
          </Paragraph>
        </Col>
      </Row>
    </Content>
  );
};

export default GeneralTermsService;
