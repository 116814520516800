import React,{useState} from 'react';
import { Layout, Col, Row, Flex } from 'antd';
import Payment from '../../../components/Audience/Payment';
import { useParams } from 'react-router-dom';

const { Content } = Layout;

const AudiencePayment = () => {
    const { phone } = useParams();
    const [unLockHandler,SetUnLockHandler]=useState(false);
    return (
        <Layout style={{ minHeight: '100vh', backgroundColor: '#F2F2F7', justifyContent: 'center' }}>
            <Content style={{ padding: '16px 20px', flex: '0' }}>
                <Flex vertical style={{ maxWidth: '416px', margin: '0 auto', padding: '25px 20px', backgroundColor: '#FFFFFF', borderRadius: 10 }}>
                    <Payment phone={phone} SetUnLockHandler={SetUnLockHandler} />
                </Flex>
            </Content>
        </Layout>
    );
}

export default AudiencePayment;