import React from 'react';
import { Layout, Col, Row, Typography } from 'antd';

const { Title, Paragraph } = Typography;
const { Content } = Layout;

const CreatorTermsUse = () => {

  return (
    <Content style={{ padding: '0 30px' }}>
      <Row style={{ justifyContent: 'center' }}>
      <Col 
        xs={{
          span: 24,
        }}
        lg={{
          span: 16,
        }}
      >
      <Title level={2} style={{ marginBottom: 20, marginTop: 0 }}>Creator Terms of Use</Title>
      <Paragraph>Welcome to [Platform Name]! These Creator Terms of Use ("Creator Terms") govern your access to and use of the content creation features on our platform. By becoming a creator on [Platform Name], you agree to comply with these Creator Terms, along with our [Terms of Service](link to Terms of Service), [Privacy Policy](link to Privacy Policy), and [Content Guidelines](link to Content Guidelines). Please read these terms carefully.
      </Paragraph>

      <Title level={4}>1. Eligibility and Registration</Title>
      <Paragraph>To become a creator on [Platform Name], you must:</Paragraph>
      <Paragraph>
        <ul>
          <li>Be at least [Age Requirement] years of age (or the age of majority in your jurisdiction).</li>
          <li>Register and maintain an active account.</li>
          <li>Provide accurate, complete, and current information as requested.</li>
        </ul>
      </Paragraph>
      <Paragraph>By registering, you confirm that all information provided is accurate and agree to update it as necessary.</Paragraph>

      <Title level={4}>2. Ownership and License of Created Content</Title>
      <Paragraph>As a creator, you retain ownership of the content you create and upload to [Platform Name]. However, by posting content on our platform, you grant us a non-exclusive, royalty-free, worldwide license to:</Paragraph>
      <Paragraph>
        <ul>
          <li>Use, display, modify, adapt, distribute, and promote your content on and off our platform.</li>
          <li>Feature your content for marketing and promotional purposes without compensation, though we will always credit you as the creator when feasible.</li>
        </ul>
      </Paragraph>
      <Paragraph>This license is revocable by deleting your content from the platform, though some residual copies may remain on backups for a limited period.</Paragraph>

      <Title level={4}>3. Creator Responsibilities</Title>
      <Paragraph>AYou are solely responsible for the content you create and share on our platform. By posting content, you agree to:</Paragraph>

      <Paragraph>
        <ul>
          <li>Follow our [Content Guidelines](link to Content Guidelines).</li>
          <li>Ensure your content does not infringe on any third-party rights, including copyrights, trademarks, or privacy rights.</li>
          <li>Avoid posting content that is illegal, harmful, abusive, or otherwise violates these Creator Terms or our [Terms of Service](link to Terms of Service).</li>
        </ul>
      </Paragraph>

      <Title level={4}>4. Prohibited Content and Activities</Title>
      <Paragraph>Creators are not allowed to post content that includes, but is not limited to:</Paragraph>

      <Paragraph>
        <ul>
          <li>Harassment, hate speech, or discriminatory content.</li>
          <li>Pornographic, sexually explicit, or adult material.</li>
          <li>Misinformation or misleading content.</li>
          <li>Any illegal activities, including content that promotes or facilitates illegal acts.</li>
        </ul>
      </Paragraph>

      <Title level={4}>5. Monetization and Payments</Title>
      <Paragraph>If applicable, creators may have the opportunity to monetize their content through [Platform Name] (e.g., through subscriptions, donations, ad revenue, etc.). Any earnings are subject to:</Paragraph>
      <Paragraph>
        <ul>
          <li>Compliance with our platform’s guidelines and policies.</li>
          <li>CDeduction of applicable platform fees and any third-party processing fees.</li>
          <li>CTaxes, where applicable. It is your responsibility to report and pay taxes on any earnings received.</li>
        </ul>
      </Paragraph>

      <Title level={4}>6. Intellectual Property and Content Rights</Title>
      <Paragraph>By uploading content, you warrant that:</Paragraph>
      <Paragraph>
        <ul>
          <li>You own the rights to your content or have secured the necessary permissions or licenses.</li>
          <li>Your content does not infringe any copyright, trademark, or intellectual property rights of others.</li>
          <li>You will indemnify and hold [Platform Name] harmless for any claims arising from content you upload that violates intellectual property or any other rights.</li>
        </ul>
      </Paragraph>

      <Title level={4}>7. Content Removal and Account Termination</Title>
      <Paragraph>We reserve the right to remove any content or terminate creator accounts that violate these Creator Terms, the [Content Guidelines](link to Content Guidelines), or the [Terms of Service](link to Terms of Service). Reasons for removal or suspension may include:</Paragraph>

      <Paragraph>
        <ul>
          <li>Repeatedly posting prohibited content.</li>
          <li>Violating platform guidelines or engaging in harmful or illegal activities.</li>
          <li>Failing to maintain an accurate creator profile or engaging in deceptive practices.</li>
        </ul>
      </Paragraph>

      <Title level={4}>8. Content Reporting and Disputes</Title>
      <Paragraph>If you believe your content has been unfairly removed or restricted, or if you see content that violates our terms, please report it to us. We review all reports and may reinstate content or address violations at our discretion.</Paragraph>


      <Title level={4}>9. Changes to These Terms</Title>
      <Paragraph>We may update these Creator Terms from time to time to reflect changes in our platform, services, or legal requirements. We will notify you of any significant changes, and your continued use of the platform constitutes acceptance of the revised terms.</Paragraph>

      <Title level={4}>10. Contact Us</Title>
      <Paragraph>If you have any questions about these Creator Terms of Use, please contact us at [Contact Information].</Paragraph>

      </Col>
      </Row>
    </Content>
  );
};

export default CreatorTermsUse;
