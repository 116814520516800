import React, { useState } from 'react';
import { Row, Col, Image, Checkbox, Typography, Radio, Button } from 'antd';
import useScreenSize from '../../utils/useScreenSize';
import { UploadOutlined } from '@ant-design/icons';
import FileIcon from '../../images/image-icon.svg';

import { RowsPhotoAlbum } from "react-photo-album";
import "react-photo-album/rows.css";

import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";

import Slideshow from "yet-another-react-lightbox/plugins/slideshow";
import "yet-another-react-lightbox/plugins/thumbnails.css";

const { Title } = Typography;

const Phototab = (props) => {
  const {
    showUploadModal,
    handleCheckboxChange,
    handleRadioChange,
    uploadedFiles,
    selectedFileIndices,
    singleSelection,
    selectionSelect,
  } = props;

  const { isMobile, isTablet } = useScreenSize();

  const [isLightboxOpen, setIsLightboxOpen] = useState(false);

  const [index, setIndex] = useState(-1);
  const handleImageClick = (file, index) => {
    if (!selectionSelect) {
      setIsLightboxOpen(true);
    } else {
      handleImageSelect(file.id, file.Image.filepath);
    }
  };

  const images = uploadedFiles.map((file) => ({
    src: `${process.env.REACT_APP_API_BASE_URL}/${file.Image.filepath}`,
    thumbnail: `${process.env.REACT_APP_API_BASE_URL}/${file.Image.filepath}`,
    width: 1080,
    height: 780,
    srcSet: [
      { src: `${process.env.REACT_APP_API_BASE_URL}/${file.Image.filepath}`, width: 400, height: 300 },
      { src: `${process.env.REACT_APP_API_BASE_URL}/${file.Image.filepath}`, width: 200, height: 150 },
    ],

  }));

  const handleImageSelect = (id, filepath) => {
    if (singleSelection) {
      handleRadioChange(id, filepath);
    } else {
      handleCheckboxChange(id, filepath);
    }
  };

  return (
    <div>
      {uploadedFiles.length === 0 ? (
        <div style={{ textAlign: 'center', marginTop: 50 }}>
          <Image width={48} src={FileIcon} preview={false} />
          <Title level={4} style={{ marginTop: 10, marginBottom: '30px' }}>No content found</Title>
          <Button type="primary" size="large" icon={<UploadOutlined />} onClick={showUploadModal} style={{ fontWeight: 600 }}>
            Upload
          </Button>
        </div>
      ) : (
        <div style={{ marginTop: 20, marginBottom: 20 }}>
        {selectionSelect == true ? (<>
          <Row gutter={isMobile ? [0] : isTablet ? [8, 8] : [12, 12]}>
            {uploadedFiles.map((file, index) => (
              <Col key={index} span={6}>
                <div
                  onClick={() => handleImageClick(file, index)}
                  style={{
                    padding: 0,
                    border:
                      selectionSelect && selectedFileIndices.includes(file?.id)
                        ? '2px solid #7F56D9'
                        : '2px solid transparent',
                    textAlign: 'center',
                    cursor: selectionSelect ? 'pointer' : 'pointer',
                    position: 'relative',
                  }}
                  className={
                    selectionSelect && selectedFileIndices.includes(file?.id)
                      ? 'image-container selected'
                      : 'image-container'
                  }
                >
                  {selectedFileIndices.includes(file?.id) && (
                    singleSelection ? (
                      <Radio
                        checked={selectedFileIndices.includes(file?.id)}
                        style={{ position: 'absolute', bottom: 15, right: 15, zIndex: 1 }}
                      />
                    ) : (
                      <Checkbox
                        checked={selectedFileIndices.includes(file?.id)}
                        style={{ position: 'absolute', bottom: 15, right: 15, zIndex: 1 }}
                      />
                    )
                  )}
              
                  {file?.Image?.filepath && (
                    <img
                      className="uploaded-img"
                      src={`${process.env.REACT_APP_API_BASE_URL}/${file?.Image?.filepath}`}
                      alt={file?.Image?.filepath}
                      style={{ width: '100%', display: 'block' }}
                    />
                  )}                  
                </div>
              </Col>
            ))}
          </Row>
        </>) : (<>
          {selectionSelect == false &&
            <RowsPhotoAlbum 
              photos={images} 
              targetRowHeight={200} 
              onClick={({ index }) => setIndex(index)} />
          }
        </>)}
        </div>
      )}
      
      <Lightbox
        slides={images}
        open={index >= 0}
        index={index}
        close={() => setIndex(-1)}
        // enable optional lightbox plugins
        plugins={[ Slideshow]}
      />
    
    </div>
  );
};

export default Phototab;