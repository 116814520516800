import React, { useState } from 'react';
import { Layout } from 'antd';
import { ProjectOutlined, UserOutlined } from '@ant-design/icons';
import Sidebar from '../components/Sidebar';
import { useLocation } from 'react-router-dom';

const { Content } = Layout;

const AdminLayout = ({ children }) => {

  const [collapsed, setCollapsed] = useState(false);
  const [isAdmin , setAdmin] = useState(true);

  const toggleCollapsed = () => {
    setCollapsed(!collapsed);
  };
  
  const menuItems = [
    {
      key: '2',
      icon: <ProjectOutlined />,
      label: 'Dashboard',
      path: '/admin/dashboard',
    },
    {
      key: '3',
      icon: <UserOutlined />,
      label: 'Users',
      path: '/admin/users',
    },
  ];

  const location = useLocation();
  const currentPath = location.pathname;

  return (
    <Layout style={{ minHeight: '100vh' }}>
      <Sidebar menuItems={menuItems} currentPath={currentPath}  collapsed={collapsed} setCollapsed={setCollapsed} toggleCollapsed={toggleCollapsed} isAdmin={isAdmin} />
      <Layout className="site-layout" style={{ paddingLeft: collapsed ? '80px' : '312px' }}>
        <Content>
          <div className="site-layout-background" style={{ padding: 0, minHeight: 360 }}>
            {children}
          </div>
        </Content>
      </Layout>
    </Layout>
  );
};

export default AdminLayout;
