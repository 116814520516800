import React, { useState, useRef, useEffect } from 'react';
import { CaretRightOutlined, PauseOutlined } from '@ant-design/icons';

// Global audio instance tracker
const globalAudioManager = {
  activeInstance: null,
  cleanup: () => {
    if (globalAudioManager.activeInstance) {
      globalAudioManager.activeInstance();
      globalAudioManager.activeInstance = null;
    }
  }
};

const AudioPlayer = ({ audioURL }) => {
    const [isPlaying, setIsPlaying] = useState(false);
    const [visualData, setVisualData] = useState(Array(50).fill(30));
    const VISUALIZATION_MULTIPLIER = 2.5;
    const audioRef = useRef(null);
    const audioContextRef = useRef(null);
    const analyserRef = useRef(null);
    const animationFrameRef = useRef(null);
    const sourceRef = useRef(null);

    const generateBars = () => {
        return Array.from({ length: 50 }, () => {
            const baseHeight = Math.random() * 80 + 20;
            return baseHeight;
        });
    };
    
    const [bars] = useState(generateBars());

    const cleanup = () => {
        if (audioRef.current) {
            audioRef.current.pause();
            audioRef.current.src = '';
            audioRef.current = null;
        }
        if (sourceRef.current) {
            sourceRef.current.disconnect();
            sourceRef.current = null;
        }
        if (analyserRef.current) {
            analyserRef.current.disconnect();
            analyserRef.current = null;
        }
        if (audioContextRef.current) {
            audioContextRef.current.close();
            audioContextRef.current = null;
        }
        if (animationFrameRef.current) {
            cancelAnimationFrame(animationFrameRef.current);
            animationFrameRef.current = null;
        }
        setIsPlaying(false);
        setVisualData(Array(50).fill(30));
    };

    useEffect(() => {
        // Register this instance's cleanup function
        globalAudioManager.activeInstance = cleanup;
        return () => {
            if (globalAudioManager.activeInstance === cleanup) {
                globalAudioManager.cleanup();
            }
        };
    }, []);

    useEffect(() => {
        return () => cleanup();
    }, [audioURL]);

    const initializeAudio = async () => {
        try {
            // Clean up any existing audio globally before initializing new
            globalAudioManager.cleanup();
            
            audioContextRef.current = new AudioContext();
            analyserRef.current = audioContextRef.current.createAnalyser();
            analyserRef.current.fftSize = 128;
            analyserRef.current.smoothingTimeConstant = 0.7;

            audioRef.current = new Audio();
            audioRef.current.crossOrigin = "anonymous";
            audioRef.current.src = audioURL;

            audioRef.current.addEventListener('ended', () => {
                cleanup();
                globalAudioManager.activeInstance = null;
            });

            await audioRef.current.load();

            sourceRef.current = audioContextRef.current.createMediaElementSource(audioRef.current);
            sourceRef.current.connect(analyserRef.current);
            analyserRef.current.connect(audioContextRef.current.destination);

            // Register this instance as the active one
            globalAudioManager.activeInstance = cleanup;

            return true;
        } catch (error) {
            console.error('Audio initialization error:', error);
            cleanup();
            return false;
        }
    };

    const visualize = () => {
        if (!analyserRef.current) return;
    
        const dataArray = new Uint8Array(analyserRef.current.frequencyBinCount);
        
        const updateVisualizer = () => {
          analyserRef.current.getByteFrequencyData(dataArray);
          const normalizedData = Array.from(dataArray).map(value => 
            Math.min(255, value * VISUALIZATION_MULTIPLIER)
          );
          setVisualData(normalizedData);
          animationFrameRef.current = requestAnimationFrame(updateVisualizer);
        };
        
        updateVisualizer();
    };

    const togglePlayPause = async () => {
        try {
            if (!isPlaying) {
                const initialized = await initializeAudio();
                if (!initialized) return;

                await audioRef.current.play();
                setIsPlaying(true);
                visualize();
            } else {
                cleanup();
                globalAudioManager.activeInstance = null;
            }
        } catch (error) {
            console.error('Playback error:', error);
            cleanup();
        }
    };

    return (
        <div className="audio-player" style={{ 
            display: 'flex',
            alignItems: 'center',
            gap: '6px',
            padding: '6px 8px',
            background: '#FFFFFF',
            borderRadius: '30px',
        }}>
            <button
                onClick={togglePlayPause}
                style={{
                    background: 'none',
                    border: 'none',
                    cursor: 'pointer',
                    padding: '0',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    width: '30px',
                    height: '30px'
                }}
            >
                {isPlaying ? (
                    <PauseOutlined style={{ fontSize: '28px', color: '#666' }} />
                ) : (
                    <CaretRightOutlined style={{ fontSize: '32px', color: '#666' }} />
                )}
            </button>

            <div style={{ 
                flex: 1,
                display: 'flex',
                alignItems: 'center',
                gap: '2px',
                height: '40px',
                width: '100%',
            }}>
                {isPlaying ? 
                    visualData.map((value, index) => (
                        <div
                            key={index}
                            style={{
                                width: '3px',
                                height: `${(value / 255) * 100}%`,
                                backgroundColor: '#D0D5DD',
                                transition: 'height 0.05s',
                            }}
                        />
                    ))
                    :
                    bars.map((height, index) => (
                        <div
                            key={index}
                            style={{
                                width: '3px',
                                height: `${height}%`,
                                backgroundColor: '#D0D5DD',
                                transition: 'height 0.05s',
                            }}
                        />
                    ))
                }
            </div>
        </div>
    );
};

export default AudioPlayer;