import React, { useState, useRef, useEffect } from 'react';
import { Button, message,Image } from 'antd';
import { DeleteOutlined, SendOutlined } from '@ant-design/icons';
import MicrophoneIcon from '../../images/microphone-icon.svg';
const AudioRecorder = ({
  setAudioURL,
  setVisualData,
  setIsRecording,
  isRecording,
  IsstopRecording
}) => {
 
  const [duration, setDuration] = useState(0);
  
  const VISUALIZATION_MULTIPLIER = 2.5;
  const mediaRecorderRef = useRef(null);
  const audioChunksRef = useRef([]);
  const timerRef = useRef(null);
  const analyserRef = useRef(null);
  const animationFrameRef = useRef(null);
  
  useEffect(() => {
    return () => {
      if (timerRef.current) clearInterval(timerRef.current);
      if (animationFrameRef.current) cancelAnimationFrame(animationFrameRef.current);
    };
  }, []);

  const startRecording = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      const mediaRecorder = new MediaRecorder(stream);
      mediaRecorderRef.current = mediaRecorder;
      audioChunksRef.current = [];

      // Set up audio analyzer
      const audioContext = new AudioContext();
      const source = audioContext.createMediaStreamSource(stream);
      const analyser = audioContext.createAnalyser();
      analyser.fftSize = 256;
      source.connect(analyser);
      analyserRef.current = analyser;

      mediaRecorder.ondataavailable = (event) => {
        audioChunksRef.current.push(event.data);
      };

      mediaRecorder.onstop =async () => {
        const audioBlob = new Blob(audioChunksRef.current, { type: 'audio/wav' });
        const url = URL.createObjectURL(audioBlob);
        await IsstopRecording(url);
        setAudioURL(url);
      };

      // Start recording
      mediaRecorder.start();
      setIsRecording(true);
      startTimer();
      visualize();
    } catch (err) {
      message.error('Error accessing microphone');
    }
  };

  const stopRecording = () => {
    if (mediaRecorderRef.current && isRecording) {
      mediaRecorderRef.current.stop();
      mediaRecorderRef.current.stream.getTracks().forEach(track => track.stop());
      clearInterval(timerRef.current);
      cancelAnimationFrame(animationFrameRef.current);
      setIsRecording(false);
      
    }
  };

  const startTimer = () => {
    setDuration(0);
    timerRef.current = setInterval(() => {
      setDuration(prev => prev + 1);
    }, 1000);
  };

  const visualize = () => {
    if (!analyserRef.current) return;
    
    const dataArray = new Uint8Array(analyserRef.current.frequencyBinCount);
    
    const updateVisualizer = () => {
      analyserRef.current.getByteFrequencyData(dataArray);
      const normalizedData = Array.from(dataArray).map(value => 
        Math.min(255, value * VISUALIZATION_MULTIPLIER)
      );
      setVisualData(normalizedData);
      animationFrameRef.current = requestAnimationFrame(updateVisualizer);
    };
    
    updateVisualizer();
  };

  const formatTime = (seconds) => {
    const mins = Math.floor(seconds / 60);
    const secs = seconds % 60;
    return `${String(mins).padStart(2, '0')}:${String(secs).padStart(2, '0')}`;
  };

  // const handleSave = async () => {
  //   if (!audioURL) return;
    
  //   // Create FormData for API upload
  //   const formData = new FormData();
  //   const audioBlob = await fetch(audioURL).then(r => r.blob());
  //   formData.append('audio', audioBlob, 'recording.wav');
    
  //   try {
  //     // Replace with your API endpoint
  //     const response = await fetch('/api/upload-audio', {
  //       method: 'POST',
  //       body: formData
  //     });
      
  //     if (response.ok) {
  //       message.success('Audio saved successfully');
  //       setAudioURL('');
  //     } else {
  //       message.error('Failed to save audio');
  //     }
  //   } catch (err) {
  //     message.error('Error saving audio');
  //   }
  // };

  return (
    <div className="flex audio-player items-center justify-between p-4 bg-white rounded-lg shadow">
        {isRecording && <span className="text-gray-600 font-mono">
            {formatTime(duration)}
          </span>}
          <Button
            size="large" 
            style={{ fontWeight: 600, borderRadius: '100px', padding: '0', width: 36, height: 36 }}
            icon={<Image src={MicrophoneIcon} preview={false} />}
            type={isRecording ? "primary" : "default"}
            onClick={isRecording ? stopRecording : startRecording}
            className="w-12 h-12 rounded-full"
          />
    </div>
  );
};

export default AudioRecorder;