import React,{useState,useEffect,useContext} from 'react';
import { Layout, Row, Col, Button, Upload, Image, Form, Input, Flex, Avatar, Divider, Typography, message, Spin } from 'antd';
import { MailOutlined, CloudUploadOutlined } from '@ant-design/icons';
import CopyIcon from "../../images/copy-icon.svg";
import UploadIcon from '../../images/upload-icon.svg';
import { UserContext } from '../../contexts/UserContext';
import './profile.css';
import { get,put } from '../../utils/api';
import { convertStringsToBooleans } from '../../utils/utils';
import { extractImageIds,getImageUrl,imageUrl,getInitials,openNotification } from '../../utils/utils';
import useScreenSize from '../../utils/useScreenSize';

const { Title, Text, Paragraph } = Typography;
const { TextArea } = Input;

const { Dragger } = Upload;

const MyProfile = () => {

  const [form] = Form.useForm();
  const { isMobile } = useScreenSize();
  const [loading,setLoading]=useState(false);
  const [profileInfo,setProfileInfo]=useState(null);
  const {user}  = useContext(UserContext);
  const [uploadedIds, setUploadedIds] = useState([]);
  const [profileImage,setProfileImage]=useState(null);
  const [textboxMessage, setTextboxMessage] = useState("");
  const [settinginfo, setSettingInfo] = useState(null);
  const maxLength = 500;

  const onSubmit = async (values) => {
    setLoading(true);
    try{
        const completeFormData = { ...values,uploadedIds};
        const response = await put(`admin/users/update/${user?.id}`, completeFormData);
        if (response && response.status === 200) {
            if(response?.token){
              localStorage.setItem('token', response?.token);
            }
            openNotification('Update successful');
            window.location.reload();
          }else{
            message.error(response.message);
          }
    }catch (error) {
        if (error.response && error.response.data) {
          message.error(error.response.data.message || 'Error submitting form');
        } else {
          message.error(error.message || 'Error submitting form');
        }
      } finally {
        setLoading(false);
      }
  };

  useEffect(()=>{
    const init=async()=>{
      try {
        setLoading(true);
        const response = await get(`/creator/get-profile/${user?.id}`);
        if(response && response.data){
           setProfileInfo(response.data); 
           setUploadedIds(response.data?.uploadedIds);
           setSettingInfo(response.data?.bio);
           if(response.data && response.data.images && response.data.images){
            const {filepath}=response.data.images[0];
            setProfileImage(getImageUrl(filepath));
           }
        }
      }catch(error){
        console.log('error',error);
      }finally {
        setLoading(false);
      }
    }
    init();
   
  },[user?.id]);

  const handleCopy = () => {
    const inputValue = form.getFieldValue('assign_number');
    console.log(inputValue, 'inputValue');
    navigator.clipboard.writeText(inputValue).then(() => {
      openNotification('Copied to clipboard!');
    }).catch(err => {
      message.error('Failed to copy!');
    });
  };

  useEffect(() => {
    if (settinginfo) {
      setTextboxMessage(settinginfo);
    }
  }, [settinginfo]);

  const handleChange = (e) => {
    if (e.target.value.length <= maxLength) {
      setTextboxMessage(e.target.value);
    }
  };

  if(!profileInfo){
    return (  <Layout className="profile-page" style={{ padding: 30 }}><Spin spinning={true}></Spin></Layout>);
  }

  return(
    <Layout className="profile-page" style={ isMobile ? { padding: 15 } : { padding: 30 }}>
      <Spin spinning={loading}>
      {isMobile ? 
        <Flex className="avatar-grid" style={{ paddingTop: 30, paddingBottom: 30 }} vertical align="start" gap={10}>
      
          {profileImage? <Avatar size={96} src={profileImage} />: <Avatar size={96} className="rounded-avatar" style={{ fontSize: 26, fontWeight: 600 }}>
                    {getInitials(`${profileInfo?.first_name} ${profileInfo?.last_name}`)}
                  </Avatar>}
          <Flex vertical>
            <Title level={2} style={{ marginBottom: '5px', marginTop: 0 }}>{profileInfo?.first_name} {profileInfo?.last_name}</Title>
            <Text style={{ fontSize: '16px' }}>@{profileInfo?.user_name}</Text>
          </Flex>
        </Flex>
        : 
        <Flex className="avatar-grid" style={{ paddingTop: 30, paddingBottom: 30 }} horizontal align="center" gap={25}>
      
          {profileImage? <Avatar size={165} src={profileImage} />: <Avatar size={96} className="rounded-avatar" style={{ fontSize: 26, fontWeight: 600 }}>
                    {getInitials(`${profileInfo?.first_name} ${profileInfo?.last_name}`)}
                  </Avatar>}
          <Flex vertical>
            <Title level={2} style={{ marginBottom: '5px' }}>{profileInfo?.first_name} {profileInfo?.last_name}</Title>
            <Text style={{ fontSize: '16px' }}>@{profileInfo?.user_name}</Text>
          </Flex>
        </Flex>

      }

        <Divider />
       
        <Form form={form} initialValues={profileInfo} onFinish={onSubmit} className="setting-form">
          <Row>
            <Col xs={{ span: 24 }} md={{ span: 6 }} lg={{ span: 6 }}>
                <Flex vertical>
                  <Text level={4} style={{ fontWeight: 600 }}>Profile</Text>
                  <Text>Manage your team members here.</Text> 
                </Flex>
            </Col>
            <Col xs={{ span: 24 }} md={{ span: 1 }} lg={{ span: 1 }}></Col>
            <Col xs={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }}>
              <Form.Item
                name="assign_number"
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
                rules={[{ required: false, message: 'Please input your tiktok username!' }]}
                className="custom-form-item"
              >
                <Input 
                  addonAfter={
                  <Button type="text" onClick={handleCopy}>
                      <Image 
                        width={20} 
                        src={CopyIcon} 
                        preview={false}
                      />
                      <span className="share-text" style={{ fontWeight: 600, marginLeft: 10 }}>Copy</span>
                  </Button>} 
                  placeholder="Blonde handle" 
                  defaultValue="+1 999 999 9999" 
                  className="copy-paste-input"
                  style={{ padding: '7px 0px' }} 
                  readOnly={true}
                />
              </Form.Item>
              <Form.Item
                name="email"
                label="Email address"
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
                rules={[{ required: false, message: 'Please input your first name!' }]}
                className="custom-form-item email-input"
              >
                <Input readOnly={true}  prefix={<MailOutlined />} placeholder="Enter first name" style={{ padding: '4px 10px' }} />
              </Form.Item>
            </Col>
          </Row>

          <Divider />

          <Row>
            <Col xs={{ span: 24 }} md={{ span: 6 }} lg={{ span: 6 }}>
                <Flex vertical style={isMobile ? { marginBottom: 30 } : ""}>
                  <Text level={4} style={{ fontWeight: 600 }}>Personal info</Text>
                  <Text>Update your photo and personal details,  this will be public.</Text> 
                </Flex>
            </Col>
            <Col xs={{ span: 24 }} md={{ span: 1 }} lg={{ span: 1 }}></Col>
            <Col xs={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }}>

                <Row gutter={30}>
                  <Col xs={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }}>
                    <Form.Item
                      name="first_name"
                      label="First name"
                      labelCol={{ span: 24 }}
                      wrapperCol={{ span: 24 }}
                      rules={[{ required: false, message: 'Please input your first name!' }]}
                      className="custom-form-item"
                    >
                      <Input placeholder="Enter first name" style={{ padding: '7px 10px' }} />
                    </Form.Item>
                  </Col>
                  <Col xs={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }}>
                    <Form.Item
                      name="last_name"
                      label="Last name"
                      labelCol={{ span: 24 }}
                      wrapperCol={{ span: 24 }}
                      rules={[{ required: false, message: 'Please input your first name!' }]}
                      className="custom-form-item"
                    >
                      <Input placeholder="Enter first name" style={{ padding: '7px 10px' }} />
                    </Form.Item>
                  </Col>
                </Row>

                <Flex className="avatar-with-upload" gap={20} style={ isMobile ? { flexDirection: "column", alignItems: "center", marginTop: 10 } : { flexDirection: "row", marginTop: 10 }}>
                  <Flex>
                    {profileImage? <Avatar size={ isMobile ? 64 : 165} src={profileImage} />: <Avatar size={96} className="rounded-avatar" style={{ fontSize: 26, fontWeight: 600 }}>
                      {getInitials(`${profileInfo?.first_name} ${profileInfo?.last_name}`)}
                    </Avatar>}
                  </Flex>
                  <Flex className="avatar-upload-area" style={ isMobile ? { width: "100%" } : "" }>
                    <Form.Item name="uploadedIds"  noStyle>
                        <Input type="hidden" />
                    </Form.Item>
                    <Form.Item
                      name="photo"
                      labelCol={{ span: 24 }}
                      wrapperCol={{ span: 24 }}
                      rules={[{ required: false }]}
                    >
                      <Dragger 
                        multiple
                        name="singleImage"
                        action={`${process.env.REACT_APP_API_BASE_URL}/auth/single-upload`}
                        onChange={async (info)=>{
                          console.log("info",info);
                          const { status } = info.file;
                          if (status !== 'uploading') {
                            console.log(info.file, info.fileList);
                          }
                          if (status === 'done') {
                            openNotification(`${info.file.name} file uploaded successfully.`);
                          } else if (status === 'error') {
                            message.error(`${info.file.name} file upload failed.`);
                          }
                          const {fileList}=info;
                          const getIds=extractImageIds(fileList);
                          if(getIds){
                            const url=await imageUrl(getIds[0]);
                            setProfileImage(url);
                            setUploadedIds(getIds);
                          }
                        }} style={{ backgroundColor: '#ffffff', border: '1px solid #EAECF0' }}>
                          <p className="ant-upload-drag-icon" style={{ marginBottom: 10 }}>
                            <Image src={UploadIcon} preview={false} size={40} />
                          </p>
                          <p className="ant-upload-text"><span style={{ fontWeight: 600, color: '#6941C6', fontSize: '14px' }}>Click to upload</span> or drag and drop</p>
                          <p className="ant-upload-hint" style={{ fontSize: '12px' }}>SVG, PNG, JPG or GIF (max. 800x400px)</p>
                      </Dragger>
                    </Form.Item>
                  </Flex>
                </Flex>

            </Col>
          </Row>

          <Divider />

          <Row>
            <Col xs={{ span: 24 }} md={{ span: 6 }} lg={{ span: 6 }}>
                <Flex vertical style={isMobile ? { marginBottom: 30 } : ""}>
                  <Text level={4} style={{ fontWeight: 600 }}>Social platforms</Text>
                  <Text>Manage your social links here, this will be public.</Text> 
                </Flex>
            </Col>
            <Col xs={{ span: 24 }} md={{ span: 1 }} lg={{ span: 1 }}></Col>
            <Col xs={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }}>

              <Form.Item
                name="instagram_uri"
                label="Instagram"
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
                rules={[{ required: false, message: 'Please input your instagram profile link!' }]}
                className="custom-form-item"
              >
                <Input addonBefore={<Text>@</Text>} defaultValue="Instagram" placeholder="Instagram" />
              </Form.Item> 
              <Form.Item
                name="youtube_uri"
                label="YouTube"
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
                rules={[{ required: false, message: 'Please input your youTube profile link!' }]}
                className="custom-form-item"
              >
                <Input addonBefore={<Text>@</Text>} defaultValue="YouTube" placeholder="YouTube" />
              </Form.Item> 

              <Form.Item
                name="facebook_uri"
                label="Facebook"
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
                rules={[{ required: false, message: 'Please input your facebook profile link!' }]}
                className="custom-form-item"
              >
                <Input addonBefore={<Text>@</Text>} defaultValue="Facebook" placeholder="Facebook" />
              </Form.Item> 
              <Form.Item
                name="snapchat_uri"
                label="Snapchat"
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
                rules={[{ required: false, message: 'Please input your Snapchat profile link!' }]}
                className="custom-form-item"
              >
                <Input addonBefore={<Text>@</Text>} placeholder="Snapchat" />
              </Form.Item> 
              <Form.Item
                name="ticktok_uri"
                label="TikTok"
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
                rules={[{ required: false, message: 'Please input your TikTok profile link!' }]}
                className="custom-form-item"
              >
                <Input addonBefore={<Text>@</Text>} defaultValue="TikTok" placeholder="TikTok" />
              </Form.Item> 
            </Col>
          </Row>

          <Divider />

          <Row>
            <Col xs={{ span: 24 }} md={{ span: 6 }} lg={{ span: 6 }}>
                <Flex vertical style={isMobile ? { marginBottom: 30 } : ""}>
                  <Text level={4} style={{ fontWeight: 600 }}>Your bio</Text>
                  <Text>Write a short introduction, this will be public.</Text> 
                </Flex>
            </Col>
            <Col xs={{ span: 24 }} md={{ span: 1 }} lg={{ span: 1 }}></Col>
            <Col xs={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }}>
              <Form.Item
                label="Message"
                name="bio"
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
                rules={[{ required: false, message: 'Please enter your message' }]}
                className="message-textarea"
              >
                <TextArea label="Message" onChange={handleChange} maxLength={maxLength} rows={6} />
              </Form.Item>
              <Paragraph style={{color:(textboxMessage?.length>499)?"red":""}}>
                {textboxMessage ? (<>
                  {maxLength - textboxMessage.length} characters left
                </>) : (<>
                  {maxLength} characters left
                </>)
                }
              </Paragraph>
            </Col>
          </Row>

          <Divider />

          <Flex
              horizotanl
              justify="end"
              gap="middle"
              style={{
                width: '100%',
                marginTop: '20px'
              }}
            >
            <Button style={{ fontWeight: 600 }} size="large">
              Cancel
            </Button>

            <Button type="primary" htmlType='submit' size="large" style={{ fontWeight: 600 }}>
              Save
            </Button>
          
          </Flex>

        </Form>
        </Spin>
    </Layout>
  );
};

export default MyProfile;
